import React from "react";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";
import ButtonLink from "../../common/buttonLink";

const RoutesTable = ({ data, sortColumn, onSort, onDelete,onNumOfStopsClick,pagination,t}) => {
  const columns = [
    { path: "routeNum", label: t("Route #") },
    { path: "routeName", label: t("Route Name") },
    { path: "busRegNum", label: t("Bus Reg #") },
    { path: "numOfStops", label: t("# of Stops"),numeric:true,
      key:"action",
      content:(item)=>{
        return<>
        <ButtonLink icon="visibility" variant="text" title={item.numOfStops} onButtonClick={()=>onNumOfStopsClick(item,"dialogOpen")}/>
        </>
      }
  
    },
    { path: "driverName", label: t("Driver Name")},
    { path: "attenderName", label: t("Attender Name")},
    {
      key: "action",
      content: (item) => {
        return <><Edit link={`routes/${item.id}`} /> &nbsp; <Delete onDelete={onDelete} item={item} /></>;
      },
    }
  ];

  return (
    <MatTable
      data={data}
      heading={t("List of Routes")}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
      // paginate={true}
      // pagination={pagination}
    />
  );
};

export default RoutesTable;
