import React from "react";
import { useTranslation } from "react-i18next";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";
import MatChip from "../../common/matChip";
import { Typography } from "@mui/material";

const HostelFeeCollectionTable = ({ data, sortColumn, onSort, onDelete, pagination }) => {
  const { t } = useTranslation(["hostel", "common"]);
  const columns = [
    { path: "academicYear", label: t("Academic Year") },
    { path: "studentName", label: t("Student Name") },
    { path: "hostelName", label: t("Hostel Name") },
    { path: "sharing", label: t("Sharing"), numeric: true },
    { path: "blockNum", label: t("Block #"), numeric: true },
    { path: "roomNum", label: t("Room #"), numeric: true },
    {
      path: "totalFeeAmount", label: t("Fee Amount"), className:"text-blue", numeric: true
    },
    {
      path: "totalPaidAmount", label: t("Paid Amount"),className:"text-green", numeric: true
    },
    {
      path: "discountAmount", label: t("Discount"), numeric: true,
      content: (item) => {
        return item.discountAmount&&item.discountAmount!=0?item.discountAmount:"-"
      }
    },
    {
      path: "remainingAmount", label: t("Balance"),className:"text-red", numeric: true,
      content: (item) => {
       return item.remainingAmount==0? "-": item.remainingAmount
      }
    },
    {
      key: "action",
      content: (item) => {
        switch (item.paymentStatus) {
          case "PAYMENT_DONE":
            return <>
            <Edit icon="visibility" link={`hostel-fee-collections/${item.id}?pay=v`} title={t("View")} />
          </>;
        
          default:
            return <>
              <Edit icon="currency_rupee" link={item.remainingAmount>0?`hostel-fee-collections/${item.id}?pay=r`: `hostel-fee-collections/${item.id}`} title={t("Pay")} />
            </>;
        }
          
      }
    }
  ];

  return (
    <MatTable
      data={data}
      heading={t("Hostel Fee Collections")}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
      paginate={true}
      pagination={pagination}
    />
  );
};

export default HostelFeeCollectionTable;
