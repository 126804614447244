
import React, { Component } from 'react';
import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
import MatChips from '../../common/matChips';
import { getStatsData } from '../../common/util';
import enquiryService from '../../../services/enquiryService';
import BackButton from '../../common/backButton';
import MatCircularProgressbar from '../../common/matCircularProgressbar';
import { withTranslation } from 'react-i18next';
import MatTable from '../../common/matTable';

class AdmissionAnalysisByProgram extends Component {
  state = {}


  async componentDidMount() {
    const { data } = await enquiryService.getEnquiryStatsByProgram("Admission");
    // console.log("data========:", data);
    this.setState({ data });
    const stats = getStatsData();
    this.setState({ stats })
  }
  handleClick = (item) => {
    this.props.history.push("/admissions?programId=" + item.id);
  }
  getChipItem(label, count) {
    return <><span>{label}</span> <br></br> <span className="count-label">{count ? count : 0}</span></>
  }
  renderProgramTargetTable(data) {
    console.log("renderProgramTargetTable:", data);
    const columns = [
      {
        path: 'code',
        label: "Program"
      }, {
        path: 'targetValue',
        label: 'Target',
        numeric: true

      }, {
        path: 'enquiryCount',
        label: "Enrolled",
        numeric: true

      }
    ]

    return <>
      <MatTable  columns={columns} data={data} />
    </>
  }
  render() {
    const { data, stats } = this.state;
    const { t } = this.props;
    return (<>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography component={"h"} variant='h6' >
            {"Admission Analysis by " + t("common:Program")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} alignItems='flex-end'>
          <Grid container direction='row-reverse'>
            <BackButton {...this.props} />
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
      <Grid container>
        <MatChips
          color={"primary"}
          items={[
            { label: this.getChipItem("Today", stats?.todayAdmissionCount), value: "ALL" },
            { label: this.getChipItem("Total", stats?.totalAdmissionCount), value: "TOTAL" },
            { label: this.getChipItem("Percentage", stats?.admissionPercentage + "%"), value: "A" },
            // { label: this.getChipItem("Collectable", stats?.bcc.Collectable), value: "B" },
            // { label: this.getChipItem("Type C", stats?.bcc.C), value: "C" },
            // { label: this.getChipItem("Followup Remaining", stats?.enquiryFollowupType.remaining), value: "REMAINING" },
          ]}
        // selected={enquiryType}
        // onChipClick={this.handleEnquiryTypeClick}
        />
      </Grid>
      <br />
      <Divider />
      <br />
      <Grid container spacing={2}>
        <Grid container item sm={12} md={8} spacing={2} >

        
        {
          data && data.programs.map(item => {
            return (
              <Grid xs={12} sm={12} md={3} item>
                <Card onClick={() => this.handleClick(item)}>
                  <CardHeader title={item.code}> </CardHeader>
                  <CardContent>
                    <MatCircularProgressbar value={item.percentage}
                      content={
                        <div style={{ marginTop: -5, textAlign: "center" }}>
                          <h4 style={{ margin: 0 }}>Admissions</h4>
                          <strong>
                            {item.enquiryCount}/{item.intakeValue}
                            <br />
                            {`(${item.percentage}%)`}
                          </strong>
                        </div>
                      }
                    />
                  </CardContent>
                </Card>
              </Grid>
            )
          })
        }
        </Grid>
        <Grid item sm={12} md={4}>
          {this.renderProgramTargetTable(data?.programs)}
        </Grid>

      </Grid>
    </>);
  }
}

export default withTranslation(["feeDetails", "common"])(AdmissionAnalysisByProgram);