import http from "./httpService";

// const apiEndpoint = "/hedge-brown-server/api/enquiry-document-configs";
const apiEndpoint = "/api/admission-fee-details";


function create(data) {
  return http.post(apiEndpoint, data);
}

function update(data) {
  return http.put(apiEndpoint, data);
}

function get(id) {
  return http.get(`${apiEndpoint}/${id}`);

}
function getByProgram(programId) {
  return http.get(`${apiEndpoint}/program/${programId}`);
}
function getByProgramAndTerm(programId,termId) {
  return http.get(`${apiEndpoint}/program/${programId}/term/${termId}`);
}

function getByProgramAndTermAndCalendar(programId,termId,calendar) {
  return http.get(`${apiEndpoint}/program/${programId}/term/${termId}/calendar/${calendar}`);
}

function getAll() {
  return http.get(apiEndpoint);
}

function getAllByQuery(query) {
  return http.get(apiEndpoint,{params:query});
}

function getAllList() {
  return http.get(apiEndpoint + "/list");
}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getByProgram,
  getByProgramAndTerm,
  getByProgramAndTermAndCalendar,
  getAll,
  getAllByQuery,
  getAllList,
  deleteById,
};
