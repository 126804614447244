import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import React, { Component } from "react";

class MatTableHeader extends Component {
  prepareSortColumn(path) {
    const sortColumn = { ...this.props.sortColumn };
    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }
    return sortColumn;
  }

  renderSortLabel = (column) => {
    const { sortColumn, onSort } = this.props;
    return (
      <TableSortLabel
        active={sortColumn.path === column.path}
        direction={sortColumn.path === column.path ? sortColumn.order : "asc"}
        onClick={() => onSort(this.prepareSortColumn(column.path))}
      >
        {column.label}
      </TableSortLabel>
    );
  };

  renderLabel = (column) => {
    if (column.sort) {
      return this.renderSortLabel(column);
    } else {
      return column.label;
    }
  };

  render() {
    const { columns,classes } = this.props;
    // console.log("theme{}:",classes)
    return (
      <TableHead >
        <TableRow >
        <TableCell
              color="info"
              align={"left"}
              key={"SLno"}
              className={classes.tableHead}
            >
              SL #
            </TableCell>
          {columns.map((column) => (
            <TableCell
              color="info"
              title={column.helperText}
              align={column.numeric ? "right" : "left"}
              key={column.path || column.key}
              className={classes.tableHead}
            >
              {this.renderLabel(column)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

export default MatTableHeader;
