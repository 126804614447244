import { Hidden } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ButtonLink from "./buttonLink";
import ButtonLinkFab from "./buttonLinkFab";
import ButtonLinkIcon from "./buttonLinkIcon";
const Add = ({ link, item, onAdd, title, type = "normal | fab | iconOnly" }) => {
  const { t } = useTranslation(["common"]);

  // function renderButton() {
  //   switch (type.toLowerCase()) {
  //     case "icononly":
  //       return (<ButtonLinkIcon
  //         title={title}
  //         link={link}
  //         item={item}
  //         icon={"add_circle"}
  //         onButtonClick={onAdd}
  //         style={{ padding: "0px 8px" }}
  //       />)
  //     case "fab":
  //       return (<ButtonLinkFab
  //         title={title}
  //         link={link}
  //         item={item}
  //         icon={"add"}
  //         onButtonClick={onAdd}
  //       />)
  //     default:
  //       return (<ButtonLink
  //         title={title}
  //         link={link}
  //         item={item}
  //         icon={"add_circle"}
  //         onButtonClick={onAdd}
  //       />)
  //   }
  // }
  return (<>
    <Hidden only={["md", "lg", "xl"]}>
      <ButtonLinkFab
        title={title}
        link={link}
        item={item}
        icon={"add"}
        onButtonClick={onAdd}
      />
    </Hidden>
    <Hidden only={["xs", "sm"]}>
      <ButtonLink
        title={title}
        link={link}
        item={item}
        icon={"add_circle"}
        onButtonClick={onAdd}
      />
    </Hidden>
  </>)
};

export default Add;
