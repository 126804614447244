import http from "./httpService";

const apiEndpoint = "/api/admission-fee-payments";

function create(data) {
  return http.post(apiEndpoint, data);
}

function update(data) {
  return http.put(apiEndpoint, data);
}

function optInFeeItem(id,data) {
  return http.put(`/api/admission-fee-payment-items/optin/${id}`,data);
}

function optOutFeeItem(id,data) {
  return http.put(`/api/admission-fee-payment-items/optout/${id}`,data);
}

function get(id) {
  return http.get(`${apiEndpoint}/${id}`);
}
function getByStudent(studentId) {
  return http.get(`${apiEndpoint}/user/${studentId}`);
}
function getTransactionsByStudent(studentId) {
  return http.get(`${apiEndpoint}/transactions/${studentId}`);
}

function getTransactions(query) {
  return http.get(`${apiEndpoint}/transactions`,{params:query});
}
function getCollections(query) {
  return http.get(`${apiEndpoint}/collections`,{params:query});
}

function getCollectables(query) {
  return http.get(`${apiEndpoint}/collectables`,{params:query});
}

function getTransactionsByAdmissionFeePayment(id) {
  return http.get(`${apiEndpoint}/transactions/${id}`);
}
function getAll() {
  return http.get(apiEndpoint);
}

function offlineAdmissionFeePayment(id,data){
  return http.post(`${apiEndpoint}/${id}/offline-payment`, data);
}

function studentAdmissionFeeDetails(query){
  return http.get(`${apiEndpoint}/student-admission-fee-details`,{ params: { ...query } });

}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  optInFeeItem,
  optOutFeeItem,
  get,
  getByStudent,
  getAll,
  getTransactionsByStudent,
  getTransactionsByAdmissionFeePayment,
  getCollections,
  getCollectables,
  getTransactions,
  offlineAdmissionFeePayment,
  studentAdmissionFeeDetails,
  deleteById,
};
