import authService from "./authService";
import http from "./httpService";

// const apiEndpoint = "/hedge-brown-server/api/groups";
const apiEndpoint = "/api/groups";


function createGroup(group) {
  return http.post(apiEndpoint, group);
}

function updateGroup(group) {
  return http.put(apiEndpoint, group);
}

function getGroup(groupId) {
  return http.get(`${apiEndpoint}/${groupId}`);
}
function getGroups() {
  return http.get(apiEndpoint);
}

function getGroupsInLogin() {
  return http.get(apiEndpoint);
}

function deleteGroup(groupId) {
  return http.delete(`${apiEndpoint}/${groupId}`);
}

export default {
  createGroup,
  updateGroup,
  getGroup,
  getGroups,
  getGroupsInLogin,
  deleteGroup,
};
