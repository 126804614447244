import { Divider, Grid } from "@mui/material";
import React from "react";
import queryString from "query-string";
import { withTranslation } from "react-i18next";
import enquiryService from "../../../services/enquiryService";
import logger from "../../../services/logger";
import MatChips from "../../common/matChips";
import MatInputBase from "../../common/matInputBase";
import MatTablePagination from "../../common/matTablePagination";
import PagingAndSorting from "../../common/pagingAndSorting";
import AdmissionApplicantTable from "./admissionApplicantTable";
import { getStatsData } from "../../common/util";
import { debounce } from "lodash";
import admissionFeePaymentService from "../../../services/admissionFeePaymentService";
// import UploadEnquiriesForm from "./uploadEnquiriesForm";

class AdmissionApplicants extends PagingAndSorting {
  state = {
    data: [],
    status: "APPROVED",
    allocatedTo: "ME",
    sortColumn: { path: "modifiedDate", order: "desc" },
    page: 0,
    rowsPerPage: 25,
    totalElements: 0,
    confirmDialogOpen: false,
    formDialogOpen: false,
    pagination:{page: 0,rowsPerPage: 25,count:0,onPageChange:this.handlePageChange,onRowsPerPageChange:this.handleRowsPerPageChange}

  };

  componentDidMount() {
    const status = queryString.parse(window.location.search).status;
    //console.log("status:", status);
    // const { status } = this.state;
    if (status) {
      this.setState({ status });
    }
    const stats = getStatsData();
    if (stats)
      this.setState({ stats });
    this.getPagedData({
      ...this.query(),
      status: status?status:this.state.status
    });
  }

  query(search = "") {
    const { page, rowsPerPage: size, sortColumn, status } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    return { search, page, size, sort, status };
  }




  handleStatusClick = (type) => {
    //console.log("type :", type);
    this.setState({ status: type },()=>this.getPagedData({
      ...this.query(),
      status: type
    }));

    
  };

  // handleInputBaseClick = async (item) => {
  //   //console.log("inputbase :", item);
  //   this.setState({ status: "APPROVED" });

  //   this.getPagedData({
  //     ...this.query(item),
  //     status: "APPROVED"

  //   });
  // };

  handleInputBaseClick = async (item) => {
    this.setState({search:''})
    this.setState({ status: "APPROVED" });
    this.getPagedData({
      ...this.query(),
      status: "APPROVED"
    });
  };

   debouncedSearch = debounce((item) => {
    this.getPagedData({
      ...this.query(item),
      status: "APPROVED"
    });
  }, 300); 

  handleInputBaseChange = async (item) => {
    console.log("input base change:",item);
    this.setState({search:item})
    this.setState({ status: "APPROVED" });
    this.debouncedSearch(item);

  };


  

  getPagedData = async (query) => {
    // query={...this.query("")}
    console.log("query :",query);
    query.status = query.status ? query.status : this.state.status;

    try {
      const { data, headers } = await admissionFeePaymentService.studentAdmissionFeeDetails(query);
      const totalElements = headers["x-total-elements"];
      const pagination={...this.state.pagination};
      pagination.count=totalElements;
      this.setState({ data, pagination });
      
    } catch (ex) {
      logger.error(ex);
    }
  };

  getChipItem(label, count) {
    return <><span>{label}</span> <br></br> <span className="count-label">{count ? count : 0}</span></>
  }


  render() {
    const {
      sortColumn,
      data,
      rowsPerPage,
      page,
      totalElements,
      status,
      allocatedTo,
      confirmDialogOpen,
      formDialogOpen,
      stats,
      search,
      pagination
    } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Divider/>
        <br />
          <Grid container>
            {/* <Grid item xs={12} md={3}>
              <MatInputBase
                txtLabel="Search by Application #"
                btnLabel="serach"
                icon="search"
                onInputBaseClick={this.handleInputBaseClick}
              />
            </Grid> */}
            <Grid item xs={12} md={3}>
              <MatInputBase
                input={search}
                txtLabel="Search by Name/Mobile"
                btnLabel="serach"
                icon="search"
                onInputBaseClick={this.handleInputBaseClick}
                onChange={this.handleInputBaseChange}
              />
            </Grid>
            {/* {authService.getCurrentRole() === "COUNSELLOR" || authService.getCurrentRole() === "ADMISSION_MANAGER" && (
              <Grid container item xs={12} md={6} direction="row-reverse">
                <MatChip
                  items={[
                    { label: "ALL", value: "ALL" },
                    { label: "Allocated to me", value: "ME" },
                  ]}
                  selected={allocatedTo}
                  onChipClick={this.handleAllocatedToClick}
                />
              </Grid>
            )} */}
          </Grid>
     
          <br />
          <Divider/>
          <br />
          {/* {authService.getCurrentRole() === "COUNSELLOR" || authService.getCurrentRole() === "ADMISSION_MANAGER" && ( */}
          <Grid container>
            <Grid container item xs={12} sm={12} md={8}>
              <MatChips
                items={[
                  { label: this.getChipItem("Approved", stats?.totalApprovedApplicationCount), value: "APPROVED" },
                  { label: this.getChipItem("Enrolled", stats?.totalAdmissionCount), value: "ENROLLED" },
                  { label: this.getChipItem("Not Enrolled", stats?.totalApprovedApplicationCount - stats?.totalAdmissionCount), value: "YET_TO_ENROLL" },
                  { label: this.getChipItem("Cancelled", stats?.totalAdmissionCanceledCount), value: "CANCELLED" },
                ]}
                selected={status}
                onChipClick={this.handleStatusClick}
              />
            </Grid>

          </Grid>
          {/* )} */}
          <br />
          <Divider/>
          <br />
          <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AdmissionApplicantTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              pagination={pagination}

            />
           
          </Grid>
        </Grid>

        {confirmDialogOpen &&
          this.renderDeleteConfirmDialog(
            t("Delete Confirmation"),
            t("Are you sure you want to delete?")
          )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["enquiries"])(AdmissionApplicants);
