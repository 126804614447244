import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";
import {prepareChartData, prepareMapDataToChartData} from "../../common/chartUtil"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = makeStyles((theme) => ({
  dashboardCard:{
    height:"100%",
    [theme.breakpoints.up('md')]: {
     height:"325px"
    }
  }
}))

function getText(key,label, data,onClick) {
  return (
    <ListItem button >
      <ListItemText 
        onClick={()=>onClick("/admission-enquiries?q="+key)}
        primary={
          <div style={{ margin: 0 }}>
            <span> {label}</span>
            <div style={{ fontSize: 16, float: "right" }}>
              <strong>{data}</strong>
            </div>
          </div>
        }
      />
    </ListItem>
  );
}

const EnquiryFollowUpStats = ({ data,stats,onClick }) => {
  const classes=useStyles();
  // const chartData=data.filter(cd=>cd.label!=="total")
const total=stats.total;
delete stats.total;
const remaining=stats.NA;
delete stats.NA;
//  const dataSet= prepareChartData(chartData,['green','yellow','red']);
 const dataSet= prepareMapDataToChartData(stats,data?.enquiryFollowupType?.total,[]);
 //console.log("data enquiryCount ;",data.enquiryCount);
  return (
    <React.Fragment>
      <Card className={classes.dashboardCard}>
        <CardHeader title="Enquiry Followups" className="dashboard-card-heading"></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <List component="nav" aria-label="mailbox folders">
               {getText("TOTAL","Total ", data?.enquiryFollowupType?.total,onClick)} 
                <Divider />
               {getText("A","Type A", stats.A,onClick)} 
                <Divider />
               {getText("B","Type B", stats.B,onClick)} 
                <Divider />
               {getText("C","Type C", stats.C,onClick)} 
               <Divider/>
               {getText("REMAINING","Remaining ", data?.enquiryFollowupType?.remaining,onClick)} 

                <Divider />
              </List>
            </Grid>
            <Grid container xs={12} alignItems={"baseline"} md={7}>
              <Grid item xs={12} md={12}>
                <br />
               {dataSet && <Bar
                  options={dataSet.options}
                  data={dataSet.data}
                  height={260}
                />}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* <Bar options={options} data={dataSet} /> */}
    </React.Fragment>
  );
};

export default EnquiryFollowUpStats;
