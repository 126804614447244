import { Divider, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import queryString from "query-string";
import authService from "../../../services/authService";
import enquiryService from "../../../services/enquiryService";
import logger from "../../../services/logger";
import MatChips from "../../common/matChips";
import MatInputBase from "../../common/matInputBase";
import MatTablePagination from "../../common/matTablePagination";
import PagingAndSorting from "../../common/pagingAndSorting";
import programService from "../../../services/programService";
import MatSelect from "../../common/matSelect";
import AdmissionTable from "./admissionTable";
import termDataService from "../../../services/termDataService";
import { debounce } from "lodash";
// import UploadEnquiriesForm from "./uploadEnquiriesForm";

class Admissions extends PagingAndSorting {
  state = {
    data: [],
    programs: [],
    programId: -1,
    terms:[],
    termId:-1,
    enquiryType: "ALL",
    allocatedTo: "ME",
    sortColumn: { path: "date", order: "desc" },
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    confirmDialogOpen: false,
    formDialogOpen: false,
  };

  async componentDidMount() {
    const queryParams = queryString.parse(window.location.search);
    const {  programId,termId } = queryParams;

    if (programId){
      this.setState({ programId});
      this.programChange(programId);
    }

    const role = authService.getCurrentRole();
    let allocatedTo = "ALL";
    switch (role) {
      case "COUNSELLOR":
        allocatedTo = "ME";
        this.setState({ allocatedTo });
        break;
      default:
        allocatedTo = "ALL";
        this.setState({ allocatedTo });
        break;
    }

  await this.getPrograms();
    this.getPagedData({
      ...this.query(),
      programId: this.state.programId,
      termId:this.state.termId,
      allocatedTo: role === "COUNSELLOR" ? this.state.allocatedTo : "ALL",
    });
  }

  getPrograms = async () => {
    try {
      const { data: programs } = await programService.getAll();
      this.setState({ programs });
    } catch (ex) {
      logger.error(ex);
    }
  };

  handleProgramChange = async (event) => {
    const programId=event.target.value;
   await this.programChange(programId);
  }

  async programChange(programId){
    this.setState({ programId })
    console.log("event.target.value:", programId);
    if(programId!=-1){
      console.log("-1 event.target.value:", programId);

      const {data:terms}=await termDataService.getAllByProgram(programId);
      this.setState({terms});
      if(terms.length>=1){
        this.setState({termId:terms[0].id})
        this.getPagedData({ ...this.query(), programId: programId,termId:terms[0].id });
      }
    }else{
      console.log("not -1 programId:", programId);

      this.getPagedData({ ...this.query(), programId: -1,termId:-1 });
    }
  }

  handleTermChange = async (event) => {
    this.setState({ termId: event.target.value });
    this.getPagedData({ ...this.query(),programId:this.state.programId,termId:event.target.value  });

  }

  
  query(search = "") {
    const { page, rowsPerPage: size, sortColumn } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    return { search, page, size, sort };
  }

  handleUpload = (item) => {
    this.setState({ formDialogOpen: true });
  };

  handleAllocatedToClick = async (allocatedTo) => {
    //console.log("allocated to :", allocatedTo);
    this.setState({ allocatedTo });

    this.getPagedData({
      ...this.query(),
      enquiryType: this.state.enquiryType,
      programId: this.state.programId,
      allocatedTo,
    });
  };

  handleEnquiryTypeClick = (type) => {
    //console.log("type :", type);
    this.setState({ enquiryType: type });

    this.getPagedData({
      ...this.query(),
      enquiryType: type,
      programId: this.state.programId,
      allocatedTo: this.state.allocatedTo,
    });
  };

  handleInputBaseClick = async (item) => {
    this.setState({search:''})
    this.setState({ enquiryType: "ALL" });
    this.getPagedData({
      ...this.query(),
      enquiryType: "ALL",
      programId: this.state.programId,
      allocatedTo: this.state.allocatedTo,
    });
  };

   debouncedSearch = debounce((item) => {
    this.getPagedData({
      ...this.query(item),
      enquiryType: "ALL",
      programId: this.state.programId,
      allocatedTo: this.state.allocatedTo,
    });
  }, 300); 

  handleInputBaseChange = async (item) => {
    console.log("input base change:",item);
    this.setState({search:item})
    this.setState({ enquiryType: "ALL" });
    this.debouncedSearch(item);

  };

  getPagedData = async (query) => {
    try {
      const {programId,termId}=query;
      query.programId=programId?programId:this.state.programId;
      query.termId=termId?termId:this.state.termId;
      query.enquiryType = query.enquiryType ? query.enquiryType : this.state.enquiryType;
      query.allocatedTo = query.allocatedTo ? query.allocatedTo : this.state.allocatedTo;

      const { data, headers } = await enquiryService.getAdmissionsByQuery(query);
      const totalElements = headers["x-total-elements"];

      this.setState({ data, totalElements });
    } catch (ex) {
      logger.error(ex);
    }
  };

  getChipItem(label, count) {
    return <div><span>{label}</span> <br></br> <span className="count-label" >{count ? count : 0}</span></div>
  }

  onConfirmOk = async (id) => {
    try {
      await enquiryService.deleteById(id);
      await this.getPagedData(this.query());
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const {
      sortColumn,
      data,
      programs,
      programId,
      terms,
      termId,
      rowsPerPage,
      page,
      totalElements,
      enquiryType,
      allocatedTo,
      confirmDialogOpen,
      search,
      applicantTypes,
    } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
          <Divider/>
        <br />
        <Grid container>
         
          <Grid item xs={12} md={3}>
            <MatInputBase
              input={search}
              txtLabel="Search by Name/Mobile"
              btnLabel="clear"
              icon="search"
              onChange={this.handleInputBaseChange}
              onInputBaseClick={this.handleInputBaseClick}
            />
          </Grid>
        
        </Grid>
        <br />
        <Divider />
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <MatSelect label={t("common:Filter By Program")} isOptionAllRequired={true} onChange={this.handleProgramChange} options={programs} value={programId} />
          </Grid>
         {terms && terms.length>1 && <Grid item xs={12} md={2}>
            <MatSelect label={t("common:Filter By Term")} isOptionAllRequired={true} onChange={this.handleTermChange} options={terms} value={termId} />
          </Grid>}
        
        </Grid>
        <br />
        <Divider />
        <br />
        <Grid item container xs={12}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AdmissionTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            />
            <MatTablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>

        {confirmDialogOpen &&
          this.renderDeleteConfirmDialog(
            t("Delete Confirmation"),
            t("Are you sure you want to delete?")
          )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["enquiries","common"])(Admissions);
