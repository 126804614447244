import React from 'react';
import Form from '../../common/form';
import { Button, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
class HostelRoomAllocationView extends Form {
    state = {}
    render() {
        const { data, selectedRoom, onRoomSelect } = this.props;
        // console.log("room allocations:", data);
        return <>
            <Card>
                <CardHeader title={
                    <><Typography component={"span"} variant='h6'>
                        Room Allocation
                    </Typography>
                        <Divider />
                    </>
                }
                    subheader={
                        data && <>
                            <Typography>
                                <strong>Student Name:</strong> {data.studentName} &nbsp;
                            </Typography>
                            <Divider />
                            <Typography>
                                <strong>Hostel Fee:</strong> {data.hostelFeeDetail?.feeAmount} &nbsp;
                                {data.messFeeDetail && <><strong>Mess Fee:</strong> {data.messFeeDetail?.feeAmount} </>}
                                <strong>Total:</strong>  {data.messFeeDetail ? <> {data.hostelFeeDetail?.feeAmount + data.messFeeDetail?.feeAmount}</> : data.hostelFeeDetail?.feeAmount}
                            </Typography>
                            <Divider />
                        </>
                    }
                />
                <CardContent style={{ marginTop: "10px" }} >
                    <Grid container>
                        {data && data?.roomAllocations && data?.roomAllocations.map(r =>
                            <Grid item md={1} marginRight={1} marginBottom={1}>
                                {!r.roomIsFull ?
                                    <Button style={{ marginRight: "5px" }} title={"Allocated:" + r.allocatedBeds + "  Un-Allocated:" + r.unallocatedBeds} onClick={() => onRoomSelect(r)} variant={selectedRoom === r.roomId ? 'contained' : 'outlined'}> {r.roomNum}</Button>
                                    : <Button title='Room is full' disabled style={{ marginRight: "5px" }} variant={selectedRoom === r.roomId ? 'contained' : 'outlined'}> {r.roomNum}</Button>
                                }
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </>;
    }
}

export default HostelRoomAllocationView;