import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import Add from "../../common/add";
import PagingAndSorting from "../../common/pagingAndSorting";
import CustomersTable from "./customersTable.page";
import FormDialog from "../../common/formDialog";
import committeeMeetingService from "./customer.service";
import committeeService from "../../../services/committeeService";
import MatChips from "../../common/matChips";
import CustomerFormPage from "./customerForm.page";
import customerService from "./customer.service";

class Customers extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "modifiedDate", order: "asc" },
    confirmDialogOpen: false,
    numOfStopsDilog:false,
    status:"All",
    pagination: { page: 0, rowsPerPage: 25, count: 0, onPageChange: this.handlePageChange, onRowsPerPageChange: this.handleRowsPerPageChange }

  };

  async componentDidMount() {
    await this.getPagedData({...this.query()});
  }


  onConfirmOk = async (id) => {
    try {
      await customerService.deleteById(id);
      await this.getPagedData({...this.query()});
    } catch (ex) {
      logger.error(ex);
    }
  };

  closeDialog=()=>{
    this.setState({dialogOpen:false});
    this.getPagedData(this.query());  
  }




  getPagedData = async (query) => {
    try {
      query= {...query,search:this.state.status}
      const { data, headers } = await customerService.getAllByQuery({...query});
      const totalElements = headers["x-total-elements"];
      const {pagination}={...this.state}
      pagination.count=totalElements
      this.setState({ data, pagination });
    } catch (ex) {
      logger.error(ex);
    }
  };


  handleAttendance=(item)=>{
    console.log("att:",item);
  }
  handleResolution=(item)=>{
    console.log("res:",item);
  }
  handleAgend=(item)=>{
    console.log("ag:",item);
  }
  handleUploadMoM=(item)=>{
    console.log("mom:",item);
  }

  filterByStatus= (search)=>{
     this.setState({status:search},()=>this.getPagedData(this.query()));
    // this.getPagedData({...this.query(),search})
  }
  getChipItem(label) {
    return <><span>{label}</span></>
  }
 
  render() {
    const { sortColumn, data, confirmDialogOpen,dialogOpen,id,pagination } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item xs={12} sm={12} md={12} lg={12}>
         
            
            <Grid container item sm={12} md={12} direction="row-reverse">
              <Add item={{id:"new"}} onAdd={()=>this.setState({dialogOpen:true})} title={t("Add Customer")} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomersTable
              paginate={true}
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
              t={t}
              pagination={pagination}
              
            />
          </Grid>
        </Grid>
        {
          dialogOpen &&
          <FormDialog
            title={t("Customer Details")}
            content={<CustomerFormPage  id={id?id:"new"} onSave={this.closeDialog}/>}
            open={dialogOpen}
            onClose={()=>this.setState({dialogOpen:false})}
          ></FormDialog>
        }
        {confirmDialogOpen && this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["customers", "common"])(Customers);
