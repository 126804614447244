
import React, { Component } from 'react';
import billingService from '../../../services/billingService';
import BillingStatsCard from './billingStatsCard';
import { Divider, Grid, Typography } from '@mui/material';
import MatChips from '../../common/matChips';
import { formatInLakhsCrores, getStatsData } from '../../common/util';
import BackButton from '../../common/backButton';
import MatChip from '../../common/matChip';
import { withTranslation } from 'react-i18next';

class BillingAnalysisByProgram extends Component {
  state = {}


  async componentDidMount() {
    const { data } = await billingService.getAnalysisByPrograms();
    console.log("data========:", data);
    this.setState({ data });
   const stats= getStatsData();
   this.setState({stats})
  }

  getChipItem(label, count,className="default") {
    return <><span>{label}</span> <br></br> <span className="count-label">{count ? count : 0}</span></>
  }
  render() {
    const { data,stats } = this.state;
    const {t}=this.props;
    return (<>
     <Grid container>
        <Grid item xs={12} md={6}>
          <Typography component={"h"} variant='h6' >
            {"Billing Analysis by "+t("common:Program")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} alignItems='flex-end'>
          <Grid container direction='row-reverse'>
            <BackButton  {...this.props} />
          </Grid>
        </Grid>
      </Grid>
    <br />
    <Divider/>
    <br />
    <Grid container>
      <MatChip variant='outlined' color={"info"} label={this.getChipItem("Billing",formatInLakhsCrores( stats?.bcc.Billing))}/> &nbsp;
      <MatChip variant='outlined' color={"success"} label={this.getChipItem("Collection",formatInLakhsCrores(stats?.bcc.Collection))}/>&nbsp;
      <MatChip variant='outlined' label={this.getChipItem("Discount",formatInLakhsCrores(stats?.bcc.Discount))}/>&nbsp;
      <MatChip variant='outlined' color={"error"} label={this.getChipItem("Collectable",formatInLakhsCrores(stats?.bcc.Collectable))}/>&nbsp;

    {/* <MatChips
                  items={[
                    { label: this.getChipItem("Billing", stats?.bcc.Billing), value: "ALL" },
                    { label: this.getChipItem("Collection", stats?.bcc.Collection), value: "TOTAL" },
                    { label: this.getChipItem("Discount", stats?.bcc.Discount), value: "A" },
                    { label: this.getChipItem("Collectable", stats?.bcc.Collectable), value: "B" },
                    // { label: this.getChipItem("Type C", stats?.bcc.C), value: "C" },
                    // { label: this.getChipItem("Followup Remaining", stats?.enquiryFollowupType.remaining), value: "REMAINING" },
                  ]}
                  // selected={enquiryType}
                  // onChipClick={this.handleEnquiryTypeClick}
                /> */}
    </Grid>
    <br />
    <Divider/>
    <br />
      <Grid container spacing={2}>
        {
          data && data.map(item => {
            return (
              <Grid xs={12} sm={12} md={4} item>
                <BillingStatsCard data={item.map} title={item.code} />
              </Grid>
            )
          })
        }
      </Grid>
    </>);
  }
}

export default withTranslation("billing","common")(BillingAnalysisByProgram);