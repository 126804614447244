import jwtDecode from "jwt-decode";
import http from "./httpService";

const apiEndpoint = "/api/authenticate";
const tokenKey = "token";

http.setJwt(getJwt());
http.setTenant(getTenant());
http.setInstituteTenant(getInstituteTenant());

async function login(username, password) {
  const { data } = await http.post(apiEndpoint, {
    login: username,
    password: password,
  });
  localStorage.setItem(tokenKey, data.token);
}

function logout() {

  localStorage.removeItem(tokenKey);
  localStorage.clear();
  
  // window.history.deleteAll()
  // browser.history.deleteAll();
}

function forgotPassword(email) {
  return http.post(apiEndpoint + "/reset-password/init", { login: email });
}

function resetPassword(password, key) {
  return http.post(apiEndpoint + "/reset-password/finished", {
    newPassword: password,
    key: key,
  });
}

function activatePassword(password, key) {
  return http.post(apiEndpoint + "/activate", {
    newPassword: password,
    key: key,
  });
}

function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

function getCurrentRole() {
  try {
    const jwt = getCurrentUser();
    if (jwt) return jwt.roles[0];
  } catch (ex) {
    return null;
  }
}

function setCurrentRole() {
  try {
    localStorage.setItem("role", getCurrentRole());
  } catch (ex) {
    console.log(ex);
  }
}

function getJwt() {
  return localStorage.getItem(tokenKey);
}

async function getJwtWithBearer() {
  const token = await localStorage.getItem(tokenKey);
  return token ? "Bearer " + token : null;
}

function getTenant() {
  try {
    const jwt = getCurrentUser();
    if (jwt) return jwt.tenantId;
    else {
      return process.env.REACT_APP_ENV === "dev"
        ? "qa"
        : window.origin.split("/")[2].split('.')[0];
    }
  } catch (ex) {
    return null;
  }
}

function getInstituteTenant() {
  try {
    const jwt = getCurrentUser();
    if (jwt) return jwt.instituteTenant;
  } catch (ex) {
    return null;
  }
}

function getInstituteType() {
  try {
    const jwt = getCurrentUser();
    if (jwt) return jwt.instituteType;
  } catch (ex) {
    return null;
  }
}



export default {
  login,
  logout,
  forgotPassword,
  resetPassword,
  activatePassword,
  getCurrentUser,
  setCurrentRole,
  getCurrentRole,
  getJwt,
  getJwtWithBearer,
  getTenant,
  getInstituteTenant,
  getInstituteType
};
