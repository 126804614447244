import { Card, CardContent, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import uploadFilesService from "../../../services/uploadFilesService";
import { getDynamicFormJson } from "../../common/dynamicFormService";
import Form from "../../common/form";
import { getJoiSchema, getStateObject } from "../../common/formUtils";
import programService from "../../../services/programService";
import downloadFileService from "../../../services/downloadFileService";
import MatSelect from "../../common/matSelect";
import termDataService from "../../../services/termDataService";
class UploadEnquiriesForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("uploadEnquiries");
    this.state = {
      programs:[],
      terms:[],
      data: getStateObject(this.formJson.groups),
      selectedFiles: [],
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }
  async componentDidMount() {
    // await this.populateForm();
    this.getPrograms();
  }

  onSelectOf = (selcted, value) => {
    switch (selcted) {
      case "programId":
        break;
      default:
        break;
    }
  }

  getPrograms = async () => {
    try {
      const { data: programs } = await programService.getAll();
      // const dropDowns  =this.state;
      // dropDowns.programs=programs
      this.setState({ programs });
    } catch (ex) {
      logger.error(ex);
    }
  };

  handleProgramChange = async (event) => {
    this.setState({ programId: event.target.value })
    console.log("event.target.value:", event.target.value);
    const { data: terms } = await termDataService.getAllByProgram(event.target.value);
    this.setState({ terms });
    if (terms.length ===1) {
      this.setState({termId: terms[0].id});
    }    
  }

  handleTermChange = async (event) => {
    this.setState({ termId: event.target.value });
  }

  handleClick = (label) => {
    downloadFileService.downloadFile("admission-enquiries/download-template", "enquiries.xlsx")
  }


  doSubmit = async (id) => {
    const { programId,termId } = this.state;
    try {
      if (id === "new") {
        await uploadFilesService
          .upload(
            this.state.selectedFiles[0],
            `/admission-enquiries/upload`,
            (event) => {
              this.setState({
                progress: Math.round((100 * event.loaded) / event.total),
              });
            }
          )
          .then((response) => {
            this.setState({
              message: response.data.message,
              isError: false,
            });
          })
          .catch(() => {
            this.setState({
              progress: 0,
              message: "Could not upload the file!",
              currentFile: undefined,
              isError: true,
            });
          });
      } else {
        // await service.update(this.state.data);
      }
      // this.props.history.push("/admission-enquiries");
      this.props.onSave();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns, progress, programs, terms, programId, termId } = this.state;

    return (
      <>
        {/* <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <MatSelect label={t("common:Filter By Program")} onChange={this.handleProgramChange} options={programs} value={programId} />
          </Grid>
          {terms && terms.length > 1 &&
            <Grid item xs={12} md={12}>
              <MatSelect label={"Filter By Year"} onChange={this.handleTermChange} options={terms} value={termId} />
            </Grid>}
        </Grid> */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Card>
              <form onSubmit={(event) => this.handleSubmit(event, "new")}>
                {/* <CardHeader title={t(titleText)} /> */}
                {this.renderForm(this.formJson, dropDowns, false)}
                <CardContent>
                  <Grid container item sm={12} md={12} direction="row-reverse">
                    {this.renderMatButton(t("Upload"), "submit", "info", false, "upload")}
                    {/* <ButtonLink size="small" icon={"download"} title={"Download Template"} onButtonClick={this.handleDownload} /> */}
                    {/* <Button size="small" title="Download Template" color="primary" variant="contained" onClick={this.handleDownload}>Download Template</Button> */}
                    {this.renderMatButton(t("common:Download Template"), "button", "info", false, "download")}
                  </Grid>
                </CardContent>
              </form>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withTranslation(["uploadEnquiriesForm", "common"])(
  UploadEnquiriesForm
);
