import { Button, Fab, Hidden, Icon, IconButton } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const ButtonLinkIcon = ({ link, item, title, icon,type="normal",color="info", variant = "contained",size="small", onButtonClick,...rest }) => {
  const { t } = useTranslation(["common"]);

    return (
      <React.Fragment>
          {link ? (
            <Link to={link}>
              <IconButton title={title} color={color} aria-label={title} {...rest} size="large">
                <Icon>{icon}</Icon>
              </IconButton>
            </Link>
          ) : (
            // <Link to={link}>
              <IconButton
                title={title}
                color={color}
                aria-label={title}
                onClick={(event) => onButtonClick(item,event)}
                // style={{padding:"0px"}}
                {...rest}
                size="large">
                <Icon>{icon}</Icon>
              </IconButton>
            // </Link>
          )}
      </React.Fragment>
    );
};

export default ButtonLinkIcon;
