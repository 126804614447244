import http from "./httpService";

// const apiEndpoint = "/hedge-brown-server/api/fee-categories";
const apiEndpoint = "/api/admission-fee-structures";


function create(data) {
  return http.post(apiEndpoint, data);
}

function update(data) {
  return http.put(`${apiEndpoint}/${data.id}`, data);
}

function get(id) {
  return http.get(`${apiEndpoint}/${id}`);
}
function getAll() {
  return http.get(`${apiEndpoint}/all`);
}
function getAllByCalendar() {
  return http.get(`${apiEndpoint}/admission-calendar`);
}


function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getAll,
  getAllByCalendar,
  deleteById,
};
