import React from "react";
import { useTranslation } from "react-i18next";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";

const HostelAllocationsTable = ({ data, sortColumn, onSort, onDelete, pagination }) => {
  const { t } = useTranslation(["hostel", "common"]);
  const columns = [
    { path: "academicYear", label: t("Academic Year") },
    { path: "studentName", label: t("Student Name") },
    { path: "hostelName", label: t("Hostel Name") },
    { path: "sharing", label: t("Sharing"), numeric: true },
    { path: "blockNum", label: t("Block #"), numeric: true },
    { path: "roomNum", label: t("Room #"), numeric: true },
    // {
    //   path: "totalFeeAmount", label: t("Fee Amount"), className:"text-blue", numeric: true
    // },
    // {
    //   path: "totalPaidAmount", label: t("Paid Amount"),className:"text-green", numeric: true
    // },
    // {
    //   path: "discountAmount", label: t("Discount"), numeric: true,
    //   content: (item) => {
    //     return item.discountAmount&&item.discountAmount!=0?item.discountAmount:"-"
    //   }
    // },
    // {
    //   path: "remainingAmount", label: t("Balance"),className:"text-red", numeric: true,
    //   content: (item) => {
    //    return item.remainingAmount==0? "-": item.remainingAmount
    //   }
    // },
    {
      key: "action",
      content: (item) => {
        switch (item.paymentStatus) {
          case "PAYMENT_DONE":
            return <>
              <Edit icon="visibility" link={`hostel-allocations/${item.id}`} title={t("View")} />
            </>;

          default:
            return <>
              <Edit icon="currency_rupee" link={item.allocated ? `hostel-allocations/${item.id}?pay=r` : `hostel-allocations/${item.id}`} title={t("Allocate")} />
            </>;
        }

      }
    }
  ];

  return (
    <MatTable
      data={data}
      heading={t("Hostel Allocations")}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
      paginate={true}
      pagination={pagination}
    />
  );
};

export default HostelAllocationsTable;
