import React from "react";
import { useTranslation } from "react-i18next";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";
import { formatToDate, formatToDateAndTime } from "../../common/dateUtil";
import { formatInLakhsCrores } from "../../common/util";

const CollectionTable = ({ data, sortColumn,pagination, onSort, onDelete }) => {
  const { t } = useTranslation(["admissions", "common"]);
  const columns = [
    {
      path: "createdDate",
      label: t("Date"),
      sort: true,
      content: (item) => {
        return formatToDateAndTime(item.createdDate)
      }
    },
    
    { path: "userName", label: t("Student Name") },
    { path: "programName", label: t("common:Program") },
    { path: "termLabel", label: t("Term") },

    // { path: "gender", label: t("Gender") },
    { path: "transactionAmount", label: t("Tnx Amount"),numeric:true,
      content:(item)=>{
        return formatInLakhsCrores(item.transactionAmount)
      }
     },
    { path: "paymentMode", label: t("Tnx Mode") },
 
    
    
  ];

  return (
    <MatTable
      data={data}
      heading="List of Collections"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
      paginate={true}
      pagination={pagination}
    />
  );
};

export default CollectionTable;
