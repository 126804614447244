import React from "react";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";
import { formatToDate, formatToTime } from "../../common/dateUtil";

const CustomersTable = ({ data, sortColumn, onSort, onDelete,onEdit,t,paginate,pagination}) => {
  const columns = [
    { path: "customerName", label: t("Customer Name")},
    { path: "email", label: t("Email ID")},
    { path: "mobile", label: t("Mobile")},
    { path: "instanceCreated", label: t("Instance Created")},



    // { path: "date", label: t("Date"),content:(item)=>formatToDate(item.date) },
    // { path: "time", label: t("Time"),content:(item)=>formatToTime(item.time) },
    // { path: "venue", label: t("Venue")},
    // { path: "status", label: t("Status")},
   
    {
      key: "action",
      content: (item) => {
        return <><Edit item={item} onEdit={onEdit} iconOnly  /> <Delete onDelete={onDelete} item={item} iconOnly  /></>;
      },
    }
  ];

  return (
    <MatTable
      paginate={paginate}
      data={data}
      heading={t("List of Customers")}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
      pagination={pagination}
      

    />
  );
};

export default CustomersTable;
