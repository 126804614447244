
import React, { Component } from 'react';
import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
import MatChips from '../../common/matChips';
import { getStatsData } from '../../common/util';
import enquiryService from '../../../services/enquiryService';
import BackButton from '../../common/backButton';
import MatCircularProgressbar from '../../common/matCircularProgressbar';
import { withTranslation } from 'react-i18next';
import MatTable from '../../common/matTable';

class EnquiryAnalysisByProgram extends Component {
  state = {}


  async componentDidMount() {
    const { data } = await enquiryService.getEnquiryStatsByProgram("Enquiry");
    console.log("data========:", data);
    this.setState({ data });
    const stats = getStatsData();
    this.setState({ stats })
  }

  getChipItem(label, count) {
    return <><span>{label}</span> <br></br> <span className="count-label">{count ? count : 0}</span></>
  }

  handleClick = (item) => {
    this.props.history.push("/admission-enquiries?programId=" + item.id);

  }

  renderProgramTargetTable(data) {
    console.log("renderProgramTargetTable:", data);
    const columns = [
      {
        path: 'code',
        label: "Program"
      }, {
        path: 'targetValue',
        label: 'Target',
        numeric: true

      }, {
        path: 'enquiryCount',
        label: "Actual",
        numeric: true

      }
    ]

    return <>
      <MatTable  columns={columns} data={data} />
    </>
  }

  render() {
    const { data, stats } = this.state;
    const { t } = this.props;
    return (<>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography component={"h"} variant='h6' >
            {"Enquiry Analysis by " + t("common:Program")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} alignItems='flex-end'>
          <Grid container direction='row-reverse'>
            <BackButton {...this.props} />
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
      <Grid container>
        <MatChips color={"primary"} variant={"outlined"}

          items={[
            { label: this.getChipItem("Today", stats?.today), value: "ALL" },
            { label: this.getChipItem("Total", stats?.enquiryCount), value: "TOTAL" },
            { label: this.getChipItem("Percentage", stats?.percentage + "%"), value: "A" },

          ]}
        />
      </Grid>
      <br />
      <Divider />
      <br />
      <Grid container spacing={2}>
        <Grid item container xs={12} md={9} spacing={2}>
          {
            data && data.programs.map(item => {
              return (
                <><Grid xs={12} sm={12} md={2} item onClick={() => this.handleClick(item)}>
                  <Card>
                    {/* <CardHeader title={item.programName}> </CardHeader> */}
                    <CardHeader title={item.code}> </CardHeader>

                    <CardContent>
                      <MatCircularProgressbar value={item.percentage}
                        content={<div style={{ marginTop: -5, textAlign: "center" }}>
                          <h2 style={{ margin: 0 }}>Target</h2>
                          <strong>
                            {item.enquiryCount}/{item.targetValue}
                            <br />
                            {`(${item.percentage}%)`}
                          </strong>
                        </div>} />
                    </CardContent>
                  </Card>
                </Grid>

                </>
              )
            })
          }
        </Grid>
        <Grid item xs={12} md={3}>
          {this.renderProgramTargetTable(data?.programs)}
        </Grid>
      </Grid>
    </>);
  }
}

export default withTranslation(["analysis", "common"])(EnquiryAnalysisByProgram);