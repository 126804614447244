import React from 'react';
import MatTable from '../../common/matTable';
const ApplicationAnalysisByProgramTable = ({data}) => {
    const columns=[
        {
            label:"Program Name",
            path:"programName"
        },
        {
            label:"# of Applications",
            path:"enquiryCount",
            numeric:true
        }
    ]
    return ( <MatTable paginate={false} data={data} columns={columns} /> );
}
 
export default ApplicationAnalysisByProgramTable;