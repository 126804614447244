import { Card, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import Form from "../../common/form";
import {
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";
import hostelAllocationFormJson from "./hostelAllocation.json"
import hostelAllocationService from "./hostelAllocation.service";
import blockService from "../manage-blocks/block.service";
import messFeeDetailService from "../hostel-mess-fee-details/messFeeDetail.service";
import hostelFeeDetailService from "../hostel-fee-details/hostelFeeDetail.service";
import HostelRoomAllocationView from "./hostelRoomAllocationView";
import BackButton from "../../common/backButton";
import roomService from "../manage-blocks/manage-rooms/room.service";

class HostelAllocationForm extends Form {
  constructor() {
    super();
    this.formJson = hostelAllocationFormJson;
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
      sharing: undefined
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }


  async componentDidMount() {

    await this.getSharingInfo();
     await this.populateForm();
  }

  async populateForm() {
    const id = this.props.match.params.id;
    const pay = new URLSearchParams(this.props.location?.search).get("pay")

    if (id === "new") return;
    try {
      const { data } = await hostelAllocationService.get(id);
      // data={...data,...this.state.feeDetail}
      console.log("fee data:", data);
      if (pay === "r") {
        this.setState({sharing:data.sharing,hostelId:data.hostelId})
        this.getHostels(data.sharing)
        this.getHostelBlocks(data.hostelId)
        this.getHostelRoomAllocationDetails(data.hostelId,data.blockId)
        if(data.messRequired){
          this.getMeals(data.hostelId)
          this.getMessFee(data.hostelId,data.numOfMeals)
        }
        this.getHostelFee(data.hostelId,data.sharing)
        delete data.dueDate;
        this.mapToViewModel(data);
      } else {
        this.mapToViewModel({...data});
      }
      this.setState({ hostelFeeDetail: data })
    } catch (ex) {
      logger.error(ex);
    }
  }

  onSelectOf = (selected, value) => {
    const { data } = this.state;
    switch (selected) {
      case "sharing":
        this.setState({ sharing: value })
        this.getHostels(value);
        break;
      case "hostelId":
        // alert("num of sharing:"+value)
        this.setState({ hostelId: value });
        this.getHostelBlocks(value);
        this.getHostelFee(value,this.state.sharing);

        break;
      case "blockId":
        // alert("num of sharing:"+value)
        this.getHostelRooms(value)
        this.getHostelRoomAllocationDetails(this.state.hostelId,value)
        break;
      case "messRequired":
        // alert("num of sharing:"+value)
        if (value === "Yes") 
          this.getMeals(this.state.hostelId);
        
        break;
      case "numOfMeals":
        // alert("num of sharing:"+value)
        data.numOfMeals = value;
        this.setState({ data })
        this.getMessFee(this.state.hostelId,value);
        
        break;
      
    }
  }


  async getHostels(sharing) {
    try {
      const { data: hostels } = await roomService.getSummaryBySharing(sharing);
      const dropDowns = { ...this.state.dropDowns }
      dropDowns.hostels = hostels;
      this.setState({ dropDowns });
    } catch (error) {
      logger.error(error)
    }
  }

  async getHostelBlocks(hostelId) {
    try {
      const { data: blocks } = await blockService.getAllByQuery(hostelId, {});
      const dropDowns = { ...this.state.dropDowns }
      dropDowns.blocks = blocks;
      this.setState({ dropDowns });
    } catch (error) {
      logger.error(error)
    }
  }

  async getHostelRooms(blockId) {
    try {
      const { data: rooms } = await roomService.getAllByBlock(blockId);
      const dropDowns = { ...this.state.dropDowns }
      dropDowns.rooms = rooms;
      this.setState({ dropDowns });
    } catch (error) {
      logger.error(error)
    }
  }

  async getHostelRoomAllocationDetails(hostelId,blockId) {
    try {
      const { data: roomAllocations } = await roomService.getRoomAllocationDetails({ hostelId, blockId });
      // const dropDowns = { ...this.state.dropDowns }
      // dropDowns.rooms = rooms;
      this.setState({ roomAllocations });
    } catch (error) {
      logger.error(error)
    }
  }

  async getSharingInfo() {
    try {
      const { data: sharings } = await roomService.getSharings();
      // const { data: sharings } = await roomService.getSharingsByHostelType(this.data.hostelType);
      const dropDowns = { ...this.state.dropDowns }
      dropDowns.sharings = sharings;
      console.log("dropdowns:",dropDowns);
      this.setState({ dropDowns, sharings },()=>this.populateForm());
    } catch (error) {
      logger.error(error)
    }
  }

  async getMeals(hostelId) {
    try {
      const { data: meals } = await messFeeDetailService.getAllByQuery({ "hostelId": hostelId });
      const dropDowns = { ...this.state.dropDowns }
      dropDowns.meals = meals;
      this.setState({ dropDowns, meals });
    } catch (error) {
      logger.error(error)
    }
  }

  async getHostelFee(hostelId,sharing) {
    try {
      const { data: hostelFeeDetail } = await hostelFeeDetailService.getHostelFeeBySharing(hostelId, sharing);
      this.setState({ hostelFeeDetail});
    } catch (error) {
      logger.error(error)
    }
  }

  async getMessFee(hostelId,numOfMeals) {
    try {
      const { data: messFeeDetail } = await messFeeDetailService.getMessFeeByNumOfMeals(hostelId,numOfMeals);
      this.setState({messFeeDetail});
    } catch (error) {
      logger.error(error)
    }
  }

  handleRoomSelect = (item) => {
    console.log("selected room:", item);
    const data = { ...this.state.data }
    data.roomNum = item.roomNum;
    data.roomId = item.roomId;
    this.setState({ data })

  }


  doSubmit = async (id) => {
    try {
      const { data, sharing, feeDetail } = this.state;
      // console.log("total amount:",data," - fee details:",feeDetail);
      if (id === "new") {
        await hostelAllocationService.create({ ...data });
      } else {
        delete data.totalFeeAmount;
        this.setState({ data });
        await hostelAllocationService.update({ ...data }, id);
      }
      this.props.history.goBack();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns, transactions, hostelFeeDetail,messFeeDetail, roomAllocations, data } = this.state;
    const id = this.props.match.params.id;
    const pay = new URLSearchParams(this.props.location?.search).get("pay")
    console.log("block data:",data);
    return (
      <>
        <Grid container direction={"row-reverse"} item md="12" >
          <BackButton {...this.props} />
        </Grid>
        <Grid container spacing={2}>
          {/* {pay !== "v" && */}
           <Grid item md={"5"}>
            <Card>
              <form onSubmit={(event) => this.handleSubmit(event, id)}>
                {/* <CardHeader title={t("Hostel Allocation")} /> */}

                {this.renderForm(this.formJson, dropDowns)}


                {/* <CardContent>
                  <Grid container item sm={12} md={12} direction="row-reverse">
                  {this.renderMatButton(t("Submit"))}
                  {this.renderMatButton(t("common:Cancel"), "reset", "secondary")}
                  </Grid>
                  </CardContent>  */}


              </form>
            </Card>
          </Grid>
          {/* } */}
          <Grid item md={"7"}>
            {/* <Grid item md="12"> */}
              <HostelRoomAllocationView onRoomSelect={this.handleRoomSelect} data={{ ...data,roomAllocations, hostelFeeDetail, messFeeDetail }} selectedRoom={data.roomId} />
            {/* </Grid> */}
            <br />
            {/* <Grid container item md="12">
              {hostelFeeDetail && <HostelFeeCollectionTableView data={[{ ...hostelFeeDetail }]} />}
            </Grid>
            <br />
            <Grid container item md="12">
              {transactions && <TransactionTable data={transactions} />}
            </Grid> */}

          </Grid>
        </Grid>
      </>
    );
  }
}

export default withTranslation(["hostel", "common"])(
  HostelAllocationForm
);
