import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import Add from "../../common/add";
import PagingAndSorting from "../../common/pagingAndSorting";
import blockService from "./block.service";
import FormDialog from "../../common/formDialog";
import BlocksTable from "./blocksTable";
import BlockFormPage from "./blockForm.page";
import RoomsPage from "./manage-rooms/rooms.page";

class Blocks extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    confirmDialogOpen: false,
    numOfStopsDilog:false,
    pagination: { page: 0, rowsPerPage: 25, count: 0, onPageChange: this.handlePageChange, onRowsPerPageChange: this.handleRowsPerPageChange }

  };

  componentDidMount() {
    this.getPagedData({ ...this.query() });
  }


  onConfirmOk = async (id) => {
    try {
      await blockService.deleteById(id);
      await this.getPagedData({ ...this.query() });
    } catch (ex) {
      logger.error(ex);
    }
  };


  getPagedData = async (query) => {
    const {hostelId}=this.props;
    try {
      const { data, headers } = await blockService.getAllByQuery(hostelId, query);
      const totalElements = headers["x-total-elements"];
      const pagination = { ...this.state.pagination };
      pagination.count = totalElements;
      this.setState({ data, pagination });
    } catch (ex) {
      logger.error(ex);
    }
  };

  handleNumOfRoomsClick = (item) => {
    this.setState({numOfRoomsDilog:true,blockId:item.id});

  }

  closeDialog=()=>{
    this.setState({dialogOpen:false});
    this.getPagedData({...this.query()})
  }

  render() {
    const { sortColumn, data, pagination, confirmDialogOpen,numOfRoomsDilog, dialogOpen, blockId,id } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item xs={12} sm={12} md={12} lg={12}>
            <Grid container item sm={12} md={12} direction="row-reverse">
              <Add item={{id:"new"}} title={t("Add Block")} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BlocksTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
              onNumOfRoomsClick={this.handleNumOfRoomsClick}
              pagination={pagination}
              t={t}
            />
          </Grid>
        </Grid>
        {
          numOfRoomsDilog &&
          <FormDialog
            title={t("Rooms Details")}
            content={<RoomsPage blockId={blockId}/>}
            open={numOfRoomsDilog}
            onClose={()=>this.setState({numOfRoomsDilog:false})}
          />
        }

{
          dialogOpen &&
          <FormDialog
            title={t("Rooms Details")}
            content={<BlockFormPage id={id} onSave={this.closeDialog} />}
            open={dialogOpen}
            onClose={()=>this.setState({dialogOpen:false})}
          />
        }

        {confirmDialogOpen && this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["block", "common"])(Blocks);
