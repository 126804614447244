import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
const MatSelect = ({ label, value, options, isOptionAllRequired, idKey="id",valueKey="name", onChange }) => {
  return (<>

    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel id={label + "-id"}>{label}</InputLabel>
      <Select
        labelId={label + "-id"}
        id="program"
        value={value}
        onChange={onChange}
        label={label}
      >
        {isOptionAllRequired && <MenuItem value="-1">
          <strong>All</strong>
        </MenuItem>}
        {options?.map(option => <MenuItem value={option[idKey]}>{option[valueKey]}</MenuItem>)}

      </Select>
    </FormControl>
  </>);
}

export default MatSelect;