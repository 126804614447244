import http from "./httpService";

// const apiEndpoint =
//   "/hedge-brown-server/api/admission-application-form-fee-configs";

  const apiEndpoint ="api/admission-application-form-fee-configs";

function create(data) {
  return http.post(apiEndpoint, data);
}

function update(data) {
  return http.put(apiEndpoint, data);
}

function get(id) {
  return http.get(`${apiEndpoint}/${id}`);
}
function getByProgramId(programId) {
  return http.get(`${apiEndpoint}/program/${programId}`);
}
function getAll() {
  return http.get(apiEndpoint);
}

function getAllByQuery(query) {
  return http.get(apiEndpoint,{params:query});
}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getByProgramId,
  getAll,
  getAllByQuery,
  deleteById,
};
