import { Divider, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import queryString from "query-string";
import authService from "../../../services/authService";
import enquiryService from "../../../services/enquiryService";
import logger from "../../../services/logger";
import MatChips from "../../common/matChips";
import MatInputBase from "../../common/matInputBase";
import MatTablePagination from "../../common/matTablePagination";
import PagingAndSorting from "../../common/pagingAndSorting";
import ApplicantsCopyTable from "./applicantsCopyTable";
import programService from "../../../services/programService";
import MatSelect from "../../common/matSelect";
import { debounce } from "lodash";
// import UploadEnquiriesForm from "./uploadEnquiriesForm";

class ApplicantsCopy extends PagingAndSorting {
  state = {
    data: [],
    programs: [],
    programId: -1,
    enquiryType: "ALL",
    allocatedTo: "ME",
    sortColumn: { path: "modifiedDate", order: "desc" },
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    confirmDialogOpen: false,
    formDialogOpen: false,
  };

  async componentDidMount() {
    const queryParams = queryString.parse(window.location.search);
    const { q: enquiryType, programId } = queryParams;
    if (enquiryType)
      this.setState({ enquiryType });

    if (programId)
      this.setState({ programId });

    const role = authService.getCurrentRole();
    let allocatedTo = "ALL";
    switch (role) {
      case "COUNSELLOR":
        allocatedTo = "ME";
        this.setState({ allocatedTo });
        break;
      default:
        allocatedTo = "ALL";
        this.setState({ allocatedTo });
        break;
    }
    // console.log("allocatedTo:" + this.state.allocatedTo);


    const stats = sessionStorage.getItem("stats");
    if (stats)
      this.setState({ stats: JSON.parse(stats) })

    await this.getPrograms();
    this.getPagedData({
      ...this.query(),
      programId: this.state.programId,
      enquiryType: this.state.enquiryType,
      allocatedTo: role === "COUNSELLOR" ? this.state.allocatedTo : "ALL",
    });
  }

  getPrograms = async () => {
    try {
      const { data: programs } = await programService.getAll();

      this.setState({ programs });
    } catch (ex) {
      logger.error(ex);
    }
  };

  handleProgramChange = async (event) => {
    this.setState({ programId: event.target.value })
    console.log("event.target.value:", event.target.value);
    // const {data:terms}=await termDataService.getAllByProgram(event.target.value);
    // this.setState({terms});
    this.getPagedData({ ...this.query(), programId: event.target.value });

  }

  query(search = "") {
    const { page, rowsPerPage: size, sortColumn } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    return { search, page, size, sort };
  }

  handleUpload = (item) => {
    this.setState({ formDialogOpen: true });
  };

  handleAllocatedToClick = async (allocatedTo) => {
    //console.log("allocated to :", allocatedTo);
    this.setState({ allocatedTo });

    this.getPagedData({
      ...this.query(),
      enquiryType: this.state.enquiryType,
      programId: this.state.programId,
      allocatedTo,
    });
  };

  handleEnquiryTypeClick = (type) => {
    //console.log("type :", type);
    this.setState({ enquiryType: type });

    this.getPagedData({
      ...this.query(),
      enquiryType: type,
      programId: this.state.programId,
      allocatedTo: this.state.allocatedTo,
    });
  };

  // handleInputBaseClick = async (item) => {
  //   //console.log("inputbase :", item);
  //   this.setState({ enquiryType: "ALL" });

  //   this.getPagedData({
  //     ...this.query(item),
  //     enquiryType: "ALL",
  //     programId: this.state.programId,
  //     allocatedTo: this.state.allocatedTo,
  //   });
  // };

  handleInputBaseClick = async (item) => {
    this.setState({search:''})
    this.setState({ enquiryType: "ALL" });
    this.getPagedData({
      ...this.query(),
      enquiryType: "ALL",
      programId: this.state.programId,
      allocatedTo: this.state.allocatedTo,
    });
  };

   debouncedSearch = debounce((item) => {
    this.getPagedData({
      ...this.query(item),
      enquiryType: "ALL",
      programId: this.state.programId,
      allocatedTo: this.state.allocatedTo,
    });
  }, 300); 

  handleInputBaseChange = async (item) => {
    console.log("input base change:",item);
    this.setState({search:item})
    this.setState({ enquiryType: "ALL" });
    this.debouncedSearch(item);

  };



  getPagedData = async (query) => {
    try {
      query.enquiryType = query.enquiryType ? query.enquiryType : this.state.enquiryType;
      query.allocatedTo = query.allocatedTo ? query.allocatedTo : this.state.allocatedTo;
      const {programId,termId}=query;
      query.programId=programId?programId:this.state.programId;
      query.termId=termId?termId:this.state.termId;
      const { data: applicantData, headers } = await enquiryService.getApplicantsByQuery(query);
      const totalElements = headers["x-total-elements"];

      this.setState({ data: applicantData.page, applicantTypes: applicantData.count, totalElements });
    } catch (ex) {
      logger.error(ex);
    }
  };

  getChipItem(label, count) {
    return <div><span>{label}</span> <br></br> <span className="count-label" >{count ? count : 0}</span></div>
  }

  onConfirmOk = async (id) => {
    try {
      await enquiryService.deleteById(id);
      await this.getPagedData(this.query());
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const {
      sortColumn,
      data,
      programs,
      programId,
      rowsPerPage,
      page,
      totalElements,
      enquiryType,
      allocatedTo,
      confirmDialogOpen,
      formDialogOpen,
      stats,
      applicantTypes,
      search
    } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Divider/>
        <br />
        <Grid container>
          {/* <Grid item xs={12}> */}
          {/* <Grid item xs={12} md={3}>
            <MatInputBase
              txtLabel="Search by Application #"
              btnLabel="serach"
              icon="search"
              onInputBaseClick={this.handleInputBaseClick}
            />
          </Grid> */}
          <Grid item xs={12} md={3}>
            <MatInputBase
              input={search}
              txtLabel="Search by Name/Mobile"
              btnLabel="serach"
              icon="search"
              onInputBaseClick={this.handleInputBaseClick}
              onChange={this.handleInputBaseChange}
            />
          </Grid>
          {(authService.getCurrentRole() === "COUNSELLOR" || authService.getCurrentRole() === "ADMISSION_MANAGER") && (
            <Grid container item xs={12} md={9} direction="row-reverse">
              <MatChips
              color={'primary'}
                items={[
                  { label: "ALL", value: "ALL" },
                  { label: "Allocated to me", value: "ME" },
                ]}
                selected={allocatedTo}
                onChipClick={this.handleAllocatedToClick}
              />
            </Grid>
          )}
          {/* </Grid> */}
        </Grid>
        <br />
        <Divider />
        <br />
        <Grid container>
          <Grid item xs={12} md={2}>
            <MatSelect label={t("Filter By Program")} isOptionAllRequired={true} onChange={this.handleProgramChange} options={programs} value={programId} />
          </Grid>
          <Grid item xs={12} md={10}>
            <Grid container direction="row-reverse">
              <MatChips
                color={"primary"}
                items={[
                  { label: this.getChipItem("Followup Done", applicantTypes?.total), value: "TOTAL" },
                  { label: this.getChipItem("Followup Remaining", applicantTypes?.remaining), value: "REMAINING" },
                  { label: this.getChipItem("Type C", applicantTypes?.C), value: "C" },
                  { label: this.getChipItem("Type B", applicantTypes?.B), value: "B" },
                  { label: this.getChipItem("Type A", applicantTypes?.A), value: "A" },
                  { label: this.getChipItem("All", applicantTypes?.all), value: "ALL" },
                ]}
                selected={enquiryType}
                onChipClick={this.handleEnquiryTypeClick}
              />
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Divider />
        <br />
        <Grid item container xs={12}>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ApplicantsCopyTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            ></ApplicantsCopyTable>
            <MatTablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>

        {confirmDialogOpen &&
          this.renderDeleteConfirmDialog(
            t("Delete Confirmation"),
            t("Are you sure you want to delete?")
          )}


      </React.Fragment>
    );
  }
}

export default withTranslation(["enquiries","common"])(ApplicantsCopy);
