import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import enquiryService from "../../../services/enquiryService";
import logger from "../../../services/logger";
import Form from "../../common/form";
import {
  getFormButtonText,
  getStateObject,
} from "../../common/formUtils";

import json from "./verifyAndApproveApplication.json"
import BackButton from "../../common/backButton";

class VerifyAndApproveApplicationForm extends Form {
  constructor() {
    super();
    this.formJson = json;
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    // this.schema = getJoiSchema(this.formJson.groups);
  }

  async populateForm() {
    const user = this.props.user;
    const id = this.props.match.params.id;
    try {
      //console.log("user:", user);
      const { data } = await enquiryService.get(id);
      if (data) this.mapToViewModel(data);
      else return;
    } catch (ex) {
      // logger.error(ex);
      // if (user.id) this.mapToViewModel({ ...user });
    }
  }

  // mapToViewModel(obj) {
  //   this.setState({ data: getStateObject(this.formJson.groups, obj) });
  // }

  async componentDidMount() {
    await this.populateForm();

  }

  raiseBack = () => {
    this.props.onBack();
  };

  doSubmit = async (id) => {
    //console.log("user id:", id);
    try {
      // if (this.state.data.id) {
      //update method
      await enquiryService.submit(id);
      // } else {
      // await enquiryService.create(id);
      // }
      this.props.onNext();
    } catch (ex) {
      logger.log(ex);
    }
  };

  handleClick=(label)=>{
    //console.log("label:",label);
    const {data}=this.state;
    switch (label.toLowerCase()) {
      case "approve":
        enquiryService.approve(data.id);   
        this.props.history.goBack();     
        break;    
      case "reject":
        enquiryService.reject(data.id);
        this.props.history.goBack();
        break;
    }
  }

  render() {
    const { t, id } = this.props;
    const { dropDowns, data } = this.state;

    const buttonText = getFormButtonText(id);

    const titleText = data.applicationStatus === "SUBMITTED"? "Approve the Application":"";

    return (
      <Card>
        <CardHeader
          title={t(titleText)}
          action={
            <BackButton {...this.props} />
          }
        />
        <form onSubmit={(event) => this.handleSubmit(event, id)}>
          {this.renderView(this.formJson)}

          { data.applicationStatus === "SUBMITTED" &&
            <CardContent>
              <Grid container item md={12} justifyContent="center">
                {this.renderMatButton(t("common:Approve"),"button")}
                {this.renderMatButton(t("common:Reject"),"button","default")}
               
              </Grid>
            </CardContent>}
        </form>
      </Card>
    );
  }
}

export default withTranslation(["verifyAndApproveApplication", "common"])(
  VerifyAndApproveApplicationForm
);
