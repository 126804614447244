import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import { useContext } from "react";
import OrgContext from "../../app-context";
import Header from "./header";
import NavBar from "./navBar";
import { makeStyles } from "@mui/styles";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));



export default function HeaderAndNavbar({ role, classes, user,setDrawer }) {
  const [open, setOpen] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const org = useContext(OrgContext);
  const style = useStyles();
  // console.log("org in header navbar:",org);
  const toggleMobileDrawer = () => {
    // console.log("drawer clicked");
    // setOpen(!open);
    
    setMobileOpen(!mobileOpen)
  };
  const toggleDrawer = () => {
    if(style){}
    // console.log("drawer clicked:",open);
    setOpen(!open);
    setDrawer(!open)
    
  };
  // const classes=useStyles(); 
  return (
    <div className={classes.root}>
      <CssBaseline />

      <Header org={org} user={user} classes={classes} open={open} onMobileDrawerClick={toggleMobileDrawer} onDrawerClick={toggleDrawer} />


      {user && <NavBar
        mobileOpen={mobileOpen}
        open={open}
        role={role}
        org={org}
        classes={classes}
        onMobileDrawerClick={toggleMobileDrawer}
        onDrawerClick={toggleDrawer}
      />}
    </div>
  );
}
