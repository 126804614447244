import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import Form from "../../common/form";
import {
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";
import hostelFeeCollectionFormJson from "./hostelFeeCollection.json"
import hostelFeeCollectionService from "./hostelFeeCollection.service";
import blockService from "../manage-blocks/block.service";
import messFeeDetailService from "../hostel-mess-fee-details/messFeeDetail.service";
import hostelFeeDetailService from "../hostel-fee-details/hostelFeeDetail.service";
import TransactionTable from "../../manage-billing/collections/transactionTable";
import transactionService from "../../manage-billing/collections/transaction.service";
import HostelFeeCollectionTableView from "./hostelFeeCollectionTableView.page";
import HostelRoomAllocationView from "./hostelRoomAllocationView.page";
import BackButton from "../../common/backButton";
import roomService from "../manage-blocks/manage-rooms/room.service";

class HostelFeeCollectionForm extends Form {
  constructor() {
    super();
    this.formJson = hostelFeeCollectionFormJson;
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
      sharing: undefined
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }


  async componentDidMount() {
    
    await this.getSharingInfo();
    await this.populateForm();
  }

  async populateForm() {
    const id = this.props.match.params.id;
    const pay = new URLSearchParams(this.props.location?.search).get("pay")

    if (id === "new") return;
    try {
      const { data } = await hostelFeeCollectionService.get(id);
      // data={...data,...this.state.feeDetail}
      console.log("fee data:", data);
      if (pay === "r") {
        this.onSelectOf("sharing", data.sharing);
        this.onSelectOf("hostelId", data.hostelId);
        this.onSelectOf("blockId", data.blockId);
        this.onSelectOf("messRequired", data.messRequired)
        delete data.dueDate;
        this.mapToViewModel({ ...data,total:data.totalFeeAmount,totalFeeAmount: data.remainingAmount, netPayAmount: data.remainingAmount, discountAmount: "", discountApplied: false });
      } else {
        this.mapToViewModel({ ...data,total:data.totalFeeAmount,netPayAmount: data.totalFeeAmount, discountAmount: "" });
      }
      this.setState({ hostelFeeDetail: data })
      this.getTransactions(data.userId);
    } catch (ex) {
      logger.error(ex);
    }
  }

  onSelectOf = (selected, value) => {
    const { data } = this.state;
    switch (selected) {
      case "sharing":
        this.setState({ sharing: value })
        this.getHostels(value);
        break;
      case "hostelId":
        // alert("num of sharing:"+value)
        this.setState({ hostelId: value })
        this.getHostelBlocks(value)
        break;
      case "blockId":
        // alert("num of sharing:"+value)
        this.getHostelRooms(value)
        this.getHostelRoomAllocationDetails(value)
        break;
      case "messRequired":
        // alert("num of sharing:"+value)
        if (value === "Yes") {
          this.getMeals(this.state.hostelId);
        } else {
          this.getHostelFee();
        }
        break;
      case "numOfMeals":
        // alert("num of sharing:"+value)
        data.numOfMeals = value;
        this.setState({ data })
        this.getHostelAndMessFee(value);
        break;
      case "paymentType":
        if (value === "FULL_PAYMENT") {
          data.netPayAmount = data.totalFeeAmount - (data.discountAmount ? data.discountAmount : 0)
          data.partPayAmount = "";
        } else
          data.netPayAmount = "";
        data.paymentType = value;
        this.setState({ data })
        break;
      case "partPayAmount":
        if (value) {
          data.partPayAmount = value;
          data.netPayAmount = value;
          this.setState({ data: data })
        } else {

        }
        break;
      case "discountAmount":
        //console.log("value :",value);
        const amount = value ? value : 0;
        if (data.paymentType === "PART_PAYMENT") {
          if (value) {
            data.discountAmount = amount;
            data.netPayAmount = data.partPayAmount
            // this.setState({ data: data })
          } else {
            data.discountAmount = "";
            data.netPayAmount = data.partPayAmount;
          }
        } else {
          if (amount) {
            data.discountAmount = amount;
            data.netPayAmount = data.totalFeeAmount - amount
            // this.setState({ data: data })
          } else {
            data.discountAmount = "";
            data.netPayAmount = data.totalFeeAmount
          }
        }
        this.setState({ data: data })

        break;

      case "discountApplied":
        //console.log("discount applied:",value);
        if (value) {

        } else {
          data.discountApplied = value;
          data.discountAmount = "";
          this.setState({ data });
        }
        break;
    }
  }

  async getTransactions(userId) {
    try {
      const { data: transactions } = await transactionService.getAllByQuery({ userId, "transactionFor": "HOSTEL_FEE" });
      this.setState({ transactions });
    } catch (error) {
      logger.error(error)
    }
  }


  async getHostels(sharing) {
    try {
      const { data: hostels } = await roomService.getSummaryBySharing(sharing);
      const dropDowns = { ...this.state.dropDowns }
      dropDowns.hostels = hostels;
      this.setState({ dropDowns });
    } catch (error) {
      logger.error(error)
    }
  }

  async getHostelBlocks(hostelId) {
    try {
      const { data: blocks } = await blockService.getAllByQuery(hostelId, {});
      const dropDowns = { ...this.state.dropDowns }
      dropDowns.blocks = blocks;
      this.setState({ dropDowns });
    } catch (error) {
      logger.error(error)
    }
  }

  async getHostelRooms(blockId) {
    try {
      const { data: rooms } = await roomService.getAllByBlock(blockId);
      const dropDowns = { ...this.state.dropDowns }
      dropDowns.rooms = rooms;
      this.setState({ dropDowns });
    } catch (error) {
      logger.error(error)
    }
  }

  async getHostelRoomAllocationDetails(blockId) {
    try {
      const { data: roomAllocations } = await roomService.getRoomAllocationDetails({ hostelId: this.state.hostelId, blockId });
      // const dropDowns = { ...this.state.dropDowns }
      // dropDowns.rooms = rooms;
      this.setState({ roomAllocations });
    } catch (error) {
      logger.error(error)
    }
  }

  async getSharingInfo() {
    try {
      const { data: sharings } = await roomService.getSharings();
      // const { data: sharings } = await roomService.getSharingsByHostelType(this.data.hostelType);
      const dropDowns = { ...this.state.dropDowns }
      dropDowns.sharings = sharings;
      this.setState({ dropDowns, sharings });
    } catch (error) {
      logger.error(error)
    }
  }

  async getMeals(hostelId) {
    try {
      const { data: meals } = await messFeeDetailService.getAllByQuery({ "hostelId": hostelId });
      const dropDowns = { ...this.state.dropDowns }
      dropDowns.meals = meals;
      this.setState({ dropDowns, meals });
    } catch (error) {
      logger.error(error)
    }
  }

  async getHostelFee() {
    try {
      const { hostelId, sharing ,data} = this.state
      const { data: feeDetail } = await hostelFeeDetailService.getByHostelAndSharing(hostelId, sharing);
      this.setState({ feeDetail,data: { ...data, ...feeDetail,netPayAmount:feeDetail.totalFeeAmount }  });
    } catch (error) {
      logger.error(error)
    }
  }

  async getHostelAndMessFee(meals) {
    try {
      const { hostelId, sharing, data } = this.state
      const { data: feeDetail } = await hostelFeeDetailService.getHostelAndMessFee(hostelId, sharing, meals);
      this.setState({ feeDetail, data: { ...data, ...feeDetail,netPayAmount:feeDetail.totalFeeAmount } });
    } catch (error) {
      logger.error(error)
    }
  }

  handleRoomSelect=(item)=>{
    console.log("selected room:",item);
    const data={...this.state.data}
    data.roomNum=item.roomNum;
    data.roomId=item.roomId;
    this.setState({data})

  }


  doSubmit = async (id) => {
    try {
      const { data, sharing,feeDetail } = this.state;
      // console.log("total amount:",data," - fee details:",feeDetail);
      if (id === "new") {
        await hostelFeeCollectionService.create({ ...data });
      } else {
        delete data.totalFeeAmount;
        this.setState({data});
        await hostelFeeCollectionService.update({ ...data }, id);
      }
      this.props.history.goBack();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns, transactions, hostelFeeDetail, roomAllocations,data } = this.state;
    const id = this.props.match.params.id;
    const pay = new URLSearchParams(this.props.location?.search).get("pay")
    return (
      <>
        <Grid container direction={"row-reverse"} item md="12" >
          <BackButton {...this.props}/>
        </Grid>
        <Grid container spacing={2}>
          {pay!=="v" && <Grid item md={pay!=="v"?"5":"7"}>
            <Card>
              <form onSubmit={(event) => this.handleSubmit(event, id)}>
                <CardHeader title={t("Hostel Allocation and Fee Payment")} />

                {this.renderForm(this.formJson, dropDowns)}

                
                  {/* <CardContent>
                  <Grid container item sm={12} md={12} direction="row-reverse">
                  {this.renderMatButton(t("Submit"))}
                  {this.renderMatButton(t("common:Cancel"), "reset", "secondary")}
                  </Grid>
                  </CardContent>  */}
                  
               
              </form>
            </Card>
          </Grid>}
          <Grid  item md={pay!=="v"?"7":"12"}>
            <Grid  item md="12">
             {data && roomAllocations && <HostelRoomAllocationView onRoomSelect={this.handleRoomSelect} data={{roomAllocations,hostelFeeDetail}} selectedRoom={data.roomId} />}
            </Grid>
            <br/>
            <Grid container item md="12">
             {hostelFeeDetail && <HostelFeeCollectionTableView data={[{...hostelFeeDetail}]} />}
            </Grid>
            <br />
            <Grid container item md="12">
             {transactions && <TransactionTable data={transactions} />}
            </Grid>

          </Grid>
        </Grid>
      </>
    );
  }
}

export default withTranslation(["hostel", "common"])(
  HostelFeeCollectionForm
);
