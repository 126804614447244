import enquiryService from "../../services/enquiryService";


export function verifyPayment(forthe, withData) {
    switch (forthe) {
        case "ADMISSION_FEE":
            return enquiryService.verifyAdmissionFeePayment(withData);
        case "APPLICATION_FEE":
            return enquiryService.verifyPayment(withData);
        default:
            break;
    }
}

export function createPaymentOrder(forthe, withData, gateway) {
    switch (forthe) {
        case "ADMISSION_FEE":
            return enquiryService.createPaymentOrder(withData, forthe);
        case "APPLICATION_FEE":
            return enquiryService.createPaymentOrder(withData, forthe);
        default:
            break;
    }
}


export function paymentCheckout(of, withData, forthe) {

    switch (of) {
        case "razorPay":
            razorPayCheckout(forthe, withData);
            break;
        case "easeBuzz":
            easeBuzzCheckout(forthe, withData);
            break;

        default:
            alert("Payment Gateway not setup")
            break;
    }

}

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}


//Razorpay Checkout
async function razorPayCheckout(forthe, data) {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
    }
    const result = await createPaymentOrder(forthe, data, "razorPay");
    if (!result) {
        alert("Server error. Are you online?");
        return;
    }
    console.log("result:",result);
    console.log("this.props.pay:",forthe);
    const { userName, userEmail, userMobile, description, amount, id: order_id, currency, key, receiverName, notes } = result.data;
    const options = {
        key: key, // Enter the Key ID generated from the Dashboard
        amount: amount,
        currency: currency,
        name: receiverName,
        description: description,
        image: "",
        order_id: order_id,
        pay: forthe,
        handler: async function (response) {
            const data = {
                id: order_id,
                paymentId: response.razorpay_payment_id,
                orderId: response.razorpay_order_id,
                signature: response.razorpay_signature,
            };
            await verifyPayment(forthe, data)
            window.location.reload();
        }
        ,
        prefill: {
            name: userName,
            email: userEmail,
            contact: userMobile,
        },
        notes: { ...notes },
        theme: {
            color: "#61dafb",
        },
    };

    const paymentObject = new window.Razorpay(options);
    const obj = await paymentObject.open();
}


async function easeBuzzCheckout(forthe, data) {

    const res = await loadScript("https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/v2.0.0/easebuzz-checkout-v2.min.js");
    if (!res) {
        alert("EaseBuzz SDK failed to load. Are you online?");
        return;
    }
    const result = await createPaymentOrder(forthe, data, "easeBuzz");

    const { userName, userEmail, userMobile, description, amount, id: order_id, currency, key, receiverName, notes } = result.data;
    var easebuzzCheckout = new window.EasebuzzCheckout("2PBP7IABZ2", "test")
    var options = {
        access_key: key, // access key received via Initiate Payment
        onResponse: async (response) => {
            console.log("resp:", response);

            const data = {
                id: response.easepayid,
                paymentId: response.easepayid,
                orderId: response.txnid,
            };

            await verifyPayment(forthe, data)
            window.location.reload();


        },
        theme: "#123456" // color hex
    }
    try {

        easebuzzCheckout.initiatePayment(options);
    } catch (error) {
        console.log("try :", error);
    } finally {

    }
}