import { Divider, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import queryString from "query-string";
import authService from "../../../services/authService";
import enquiryService from "../../../services/enquiryService";
import logger from "../../../services/logger";
import MatInputBase from "../../common/matInputBase";
import PagingAndSorting from "../../common/pagingAndSorting";
import programService from "../../../services/programService";
import MatSelect from "../../common/matSelect";
import CollectablesTable from "./collectablesTable";
import termDataService from "../../../services/termDataService";
import admissionFeePaymentService from "../../../services/admissionFeePaymentService";
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MatChip from "../../common/matChip";
import { debounce } from "lodash";
import MatDatePicker from "../../common/matDatePicker";
import { formatInLakhsCrores } from "../../common/util";
// import UploadEnquiriesForm from "./uploadEnquiriesForm";

class Collectables extends PagingAndSorting {
  state = {
    data: [],
    programs: [],
    programId: -1,
    terms: [],
    termId: -1,
    enquiryType: "ALL",
    allocatedTo: "ME",
    sortColumn: { path: "programName", order: "desc" },
    pagination:{page: 0,rowsPerPage: 25,count:0,onPageChange:this.handlePageChange,onRowsPerPageChange:this.handleRowsPerPageChange},
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    confirmDialogOpen: false,
    formDialogOpen: false,
  };

  async componentDidMount() {
    const queryParams = queryString.parse(window.location.search);
    const { programId, termId } = queryParams;


    if (programId && termId) {
      this.setState({ programId, termId });
    }

    const role = authService.getCurrentRole();
    let allocatedTo = "ALL";
    switch (role) {
      case "COUNSELLOR":
        allocatedTo = "ME";
        this.setState({ allocatedTo });
        break;
      default:
        allocatedTo = "ALL";
        this.setState({ allocatedTo });
        break;
    }

    await this.getPrograms();
    this.getPagedData({
      ...this.query(),
      programId: this.state.programId,
      termId: this.state.termId,
      allocatedTo: role === "COUNSELLOR" ? this.state.allocatedTo : "ALL",
    });
  }

  getPrograms = async () => {
    try {
      const { data: programs } = await programService.getAll();
      this.setState({ programs });
    } catch (ex) {
      logger.error(ex);
    }
  };

  handleProgramChange = async (event) => {
    const programId = event.target.value;
    this.setState({ programId })
    console.log("event.target.value:", programId);
    const { from, to } = this.state;

    if (programId != -1) {
      const { data: terms } = await termDataService.getAllByProgram(event.target.value);
      this.setState({ terms });
      if (terms.length === 1) {
        this.getPagedData({ ...this.query(), programId: event.target.value, termId: terms[0].id, from, to });
      }
    } else {
      this.getPagedData({ ...this.query(), programId: -1, termId: -1, from, to });

    }
  }

  handleTermChange = async (event) => {
    this.setState({ termId: event.target.value });
    const { programId, from, to } = this.state;

    this.getPagedData({ ...this.query(), programId, termId: event.target.value, from, to });

  }

  handleFromDateChange = (date) => {
    console.log("date:", date);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const from = new Date(date.$d).toLocaleDateString('en-IN', options);
    // const from = new Date(date);
    console.log("from date:", from);
    // const from = new Date(date).toLocaleDateString();
    //.toLocaleDateString();
    this.setState({ fromDate: date })
    this.setState({ from });

  }
  handleToDateChange = (date) => {
    console.log("date:", date);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const to = new Date(date).toLocaleDateString('en-IN', options);
    // const to = new Date(date);
    console.log("from date:", to);

    // const to = new Date(date).toLocaleDateString()

    this.setState({ toDate: date, to })
    const { programId, termId, from, fromDate } = this.state;
    console.log("from:", from, "-to:", to);
    this.getPagedData({ ...this.query(), programId, termId, from, to })

  }

  handleEnquiryTypeClick = (type) => {
    this.setState({ enquiryType: type });

    this.getPagedData({
      ...this.query(),
      enquiryType: type,
      programId: this.state.programId,
      allocatedTo: this.state.allocatedTo,
    });
  };


  handleInputBaseClick = async (item) => {
    this.setState({ search: '' })
    this.setState({ enquiryType: "ALL" });
    this.getPagedData({
      ...this.query(),
      enquiryType: "ALL",
      programId: this.state.programId,
      allocatedTo: this.state.allocatedTo,
    });
  };

  debouncedSearch = debounce((item) => {
    this.getPagedData({
      ...this.query(item),
      enquiryType: "ALL",
      programId: this.state.programId,
      allocatedTo: this.state.allocatedTo,
    });
  }, 300);

  handleInputBaseChange = async (item) => {
    console.log("input base change:", item);
    this.setState({ search: item })
    this.setState({ enquiryType: "ALL" });
    this.debouncedSearch(item);

  };



  query(search = "") {
    const { page, rowsPerPage: size, sortColumn } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    return { search, page, size, sort };
  }

  getPagedData = async (query) => {
    try {
      const { programId, termId } = query;
      query.programId = programId ? programId : this.state.programId;
      query.termId = termId ? termId : this.state.termId;
      const { data, headers } = await admissionFeePaymentService.getCollectables(query);
      const totalElements = headers["x-total-elements"];
      const pagination={...this.state.pagination};
      pagination.count=totalElements;
      this.setState({ data: data.page,pagination, collectable: data.collectableAmount, totalElements });
    } catch (ex) {
      logger.error(ex);
    }
  };

  getChipItem(label, count) {
    return <div><span>{label}</span> <br></br> <span className="count-label" >{count ? count : 0}</span></div>
  }

  onConfirmOk = async (id) => {
    try {
      await enquiryService.deleteById(id);
      await this.getPagedData(this.query());
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const {
      sortColumn,
      data,
      programs,
      programId,
      terms,
      termId,

      confirmDialogOpen,
      fromDate, toDate, collectable, search,pagination
    } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Divider />
        <br />
        <Grid container>
          {/* <Grid item xs={12}> */}
          {/* <Grid item xs={12} md={3}>
            <MatInputBase
              txtLabel="Search by Application #"
              btnLabel="serach"
              icon="search"
              onInputBaseClick={this.handleInputBaseClick}
            />
          </Grid> */}
          <Grid item xs={12} md={3}>
            <MatInputBase
              input={search}
              txtLabel="Search by Name/Mobile"
              btnLabel="serach"
              icon="search"
              onInputBaseClick={this.handleInputBaseClick}
              onChange={this.handleInputBaseChange}
            />
          </Grid>

          {/* </Grid> */}
        </Grid>
        <br />
        <Divider />
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <MatSelect label={t("common:Filter By Program")} isOptionAllRequired={true} onChange={this.handleProgramChange} options={programs} value={programId} />
          </Grid>
          {terms && terms.length > 1 &&
            <Grid item xs={12} md={2}>
              <MatSelect label={"Filter By Year"} isOptionAllRequired={true} onChange={this.handleTermChange} options={terms} value={termId} />
            </Grid>
          }

          <Grid item xs={12} md={terms && terms.length > 1?6:8}>
            <MatDatePicker label={t("From")} date={fromDate} onChange={this.handleFromDateChange} />
            <MatDatePicker label={t("To")} date={toDate} onChange={this.handleToDateChange} />
          </Grid>

          <Grid item xs={12} md={2} >
            <Grid container direction="row-reverse">

              <MatChip  color={"primary"} label={this.getChipItem("Total Collectable", formatInLakhsCrores(collectable))} />
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Divider />
        <br />
        <Grid item container xs={12}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CollectablesTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              pagination={pagination}
            />
            
          </Grid>
        </Grid>

        {confirmDialogOpen &&
          this.renderDeleteConfirmDialog(
            t("Delete Confirmation"),
            t("Are you sure you want to delete?")
          )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["collections","common"])(Collectables);
