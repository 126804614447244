import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import admissionFeeDetailService from "../../../services/admissionFeeDetailService";
import logger from "../../../services/logger";
import Add from "../../common/add";
import PagingAndSorting from "../../common/pagingAndSorting";
import FeeDetailTable from "./feeDetailTable";
import MatSelect from "../../common/matSelect";

class FeeDetails extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
    calendarId:"",
  };

  getAll = async (query) => {
    try {
      const { data } = await admissionFeeDetailService.getAllByQuery(query);
      this.setState({ data });
    } catch (ex) {
      logger.error(ex);
    }
  };

  onConfirmOk = async (id) => {
    try {
      await admissionFeeDetailService.deleteById(id);
      await this.getAll({});
    } catch (ex) {
      logger.error(ex);
    }
  };

 async componentDidMount() {
    await this.getAdmissionCalendars()
    this.getAll({});
  }

  handleCalendarChange = (event) => {
    this.setState({ calendarId: event.target.value })
    this.getAll({ calendarId: event.target.value });
  }

  render() {
    const { sortColumn, data,calendarId,admissionCalendars } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
        <Grid container>
            <Grid item xs={12} md={2}>
              <MatSelect valueKey="academicYear" label={t("Filter By Academic Year")} isOptionAllRequired={false} onChange={this.handleCalendarChange} options={admissionCalendars} value={calendarId} />
            </Grid>
            <Grid container item sm={12} md={10} lg={10} direction="row-reverse">
              <Add link={"admission-fee-details/new/new"} title={t("New Fee Details")} />
            </Grid>
          </Grid>
          <br />
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FeeDetailTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            />
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["feeDetail", "common"])(
  FeeDetails
);
