import { Chip } from "@mui/material";
import React from "react";

const MatChip = ({ value,icon, label, color,variant="outlined",size="medium", onClick}) => {
  // console.log("selected:",color);
  return (
      <Chip
        icon={icon}
        label={label}
        color={color}
        onClick={onClick?() => onClick(value):null}
        variant={variant}
        size={size}
        // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
        // className={classes.chip}
      />
  )
};

export default MatChip;
