import { Card, CardHeader } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import Form from "../../common/form";
import {
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";
import hostelFeeFormJson from "./hostelFee.json"
import hostelFeeService from "./hostelFeeDetail.service";
import hostelService from "../hostel.service";
import roomService from "../manage-blocks/manage-rooms/room.service";

class HostelFeeDetailForm extends Form {
  constructor() {
    super();
    this.formJson = hostelFeeFormJson;
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
      sharing:undefined
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }


  async componentDidMount() {
    this.getHostels();
    await this.populateForm();
  }
  onSelectOf = (selected, value) => {
    switch (selected) {
      case "hostelId":
        this.getSharingInfo(value);
        break;
      case "numOfSharing":
        // alert("num of sharing:"+value)
        const sharing=this.state.dropDowns.sharings.find(s=>s.sharing===value);
        if(sharing){

          console.log("numOfSharing value:", sharing);
          this.setState({sharing:sharing})
        }
        break;
    }
  }

  async populateForm() {
    const id = this.props.match.params.id;
    if (id === "new") return;
    try {
      const { data } = await hostelFeeService.get(id);
      if (data)
        this.getSharingInfo(data.hostelId)
      this.mapToViewModel(data);
    } catch (ex) {
      logger.error(ex);
    }
  }

  async getHostels() {
    const { data: hostels } = await hostelService.getAll();
    const dropDowns = { ...this.state.dropDowns }
    dropDowns.hostels = hostels;
    this.setState({ dropDowns });
  }

  async getSharingInfo(hostelId) {
    const { data: sharings } = await roomService.getSharingsByHostel(hostelId);
    const dropDowns = { ...this.state.dropDowns }
    dropDowns.sharings = sharings;
    this.setState({ dropDowns,sharings });
  }

  doSubmit = async (id) => {
    try {
      const {data,sharing}=this.state;
      if (id === "new") {
        await hostelFeeService.create({ ...data,totalAvailableRooms:sharing.numberOfRooms,totalAvailableBeds: sharing.numberOfBeds });
      } else {
        await hostelFeeService.update({...data,totalAvailableRooms:sharing.numberOfRooms,totalAvailableBeds: sharing.numberOfBeds },id);
      }
      this.props.history.goBack();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.match.params.id;


    const titleText = getFormTitleText(id, this.formJson.alias);

    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, id)}>
          <CardHeader title={t(titleText)} />

          {this.renderForm(this.formJson, dropDowns)}

          {/* <CardContent>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {this.renderMatButton(t(buttonText))}
              {this.renderMatButton(t("common:Reset"), "reset", "secondary")}
            </Grid>
          </CardContent> */}
        </form>
      </Card>
    );
  }
}

export default withTranslation(["hostelFee", "common"])(
  HostelFeeDetailForm
);
