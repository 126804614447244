import React, { Component } from "react";
import authService from "../services/authService";
import instituteService from "../services/instituteService";
class Home extends Component {
  state = {};

  async componentDidMount() {
    try {
      const { data: institute } = await instituteService.getInstituteOrTrust(authService.getInstituteTenant());
      if (institute) {
        this.setState({ institute });
        this.handleLocalStorage(institute?.name)
      }
    } catch (error) {

    }
  }
  handleLocalStorage = (name) => {
    localStorage.setItem("institute", name)
    window.dispatchEvent(new Event("storage"));
  };
  render() {
    return (
      <React.Fragment>
        <h1>
          Dashboard
        </h1>
      </React.Fragment>
    );
  }
}

export default Home;
