import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { AlternateEmail, Call, Home, Mail, MessageOutlined, RadioButtonUnchecked } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { formatToDate, formatToDateAndTime } from '../../common/dateUtil';

const EnquiryFollowupTimeline = ({ data }) => {

    function getTypeColor(item) {
        switch (item.enquiryType) {
            case "A":
                return "success";
            case "B":
                return "warning"
            case "C":
                return "default"
        }
    }
    function getIcon(item) {
        switch (item.followUpType) {
            case "CALL":
                return <Call style={{color:"white"}} />
            case "SMS":
                return <MessageOutlined style={{color:"white"}} />
            case "VISIT":
                return <Home style={{color:"white"}} />
            case "EMAIL":
                return <AlternateEmail style={{color:"white"}} />
            default:
                return <RadioButtonUnchecked style={{color:"white"}}/>
        }
    }

    return (
        <Timeline 
        sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.2,
            },
          }}
        >
            {data.map(item =>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align="right"
                        variant="body2"
                        color={getTypeColor(item)}
                    >
                        {formatToDateAndTime(item.followUpDate)}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot title={item.enquiryType}  color={getTypeColor(item)}>
                            {getIcon(item)}
                        </TimelineDot>
                        <TimelineConnector />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography>{item.note}</Typography>
                        <span title={"Next Followup Date"}>
                            {formatToDate(item.nextFollowUpDate)}
                            
                        </span>
                    </TimelineContent>
                </TimelineItem>)
            }
        </Timeline>
    );
}

export default EnquiryFollowupTimeline;