import { Card, CardContent, CardHeader, Grid} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { capitalize } from "lodash";
import React from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = makeStyles((theme) => ({
  dashboardCard:{
    height:"100%",
    [theme.breakpoints.up('md')]: {
     height:"325px"
    }
  }
}))
function prepOptions(data) {
  return {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        // min: 1,
        max: data.total,
        width: 3,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };
}

function prepChartData(data) {
  if (data && data.source) {
    delete data.total;
    const labels =  data.source.map((s) => capitalize(s.label));
    const l= data.source.map((s) => s.label);

    const dataSet = l.map(
      (l) => data.source.find((s) => s.label === l).count
    );
    const options = prepOptions(data);
    return {
      options: options,
      data: {
        labels,
        datasets: [
          {
            data: dataSet,
            backgroundColor: '#3498db',
          },
        ],
      },
    };
  }
  
}

const EnquirySourceStatsCard = ({ data }) => {
  const { t } = useTranslation(["enquiries", "common"]);
  const classes=useStyles();
  // console.log("data in chart:",data);
  const dataSet = prepChartData(data);
  return (
    <React.Fragment>
      <Card className={classes.dashboardCard}>
        <CardHeader title={t("Enquiry Sources")}></CardHeader>
        <CardContent>
          <Grid container alignItems={"baseline"} spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <br />
              {dataSet && (
                <Bar
                  options={dataSet.options}
                  data={dataSet.data ? dataSet.data : {}}
                  height={180}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default EnquirySourceStatsCard;
