import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import authService from "../../services/authService";
import icamelService from "../../services/icamelService";
import instituteService from "../../services/instituteService";
import logger from "../../services/logger";
import Form from "../common/form";

import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../common/formUtils";
import instituteJson  from "./institute.json";

class InstituteForm extends Form {
  constructor() {
    super();
    this.formJson = instituteJson;
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
      countryCodes:[]
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }



  async populateForm() {
    let instituteId = this.props.match.params.id;
    if (instituteId === "new") return;
    if (instituteId === "edit") instituteId = authService.getInstituteTenant();
    try {
      const { data } = await instituteService.getInstitute(instituteId);

      this.mapToViewModel(data);
    } catch (ex) { }
  }
  onSelectOf = (item, value) => {
    switch (item) {
      case "country":
        this.getStatesByCountry(value);
        break;
      case "state":
        this.getCitiesByState(value);
        break;
    }
  };
  getCountries = async () => {
    try {
      const { data: countries } = await icamelService.getCountries();
      const { dropDowns } = this.state;
      dropDowns.countries = countries;
      this.setState({ dropDowns });
      const { data } = this.state;
      if (data.country) {
        const { data: states } = this.getStatesByCountry(data.country);

      }

    } catch (ex) {
      logger.error(ex);
    }
  };

  getStatesByCountry = async (countryCode) => {
    try {
      const { data: states } = await icamelService.getStatesByCountry(
        countryCode
      );
      const { dropDowns } = this.state;
      dropDowns.states = states;
      this.setState({ dropDowns });
      const { data } = this.state;
      if (data.state) {
        this.getCitiesByState(data.state);
      }
    } catch (ex) {
      logger.error(ex);
    }
  };

  getCitiesByState = async (stateCode) => {
    try {
      const { data: cities } = await icamelService.getCitiesByState(stateCode);
      const { dropDowns } = this.state;
      dropDowns.cities = cities;
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error(ex);
    }
  };
  async componentDidMount() {
    this.getCountryCodes();

    await this.populateForm();
    this.getCountries();
    // await this.getGroups();
    // await this.getPrograms();
  }

  doSubmit = async (id) => {
    try {
      if (id === "new") await instituteService.createInstitute(this.state.data);
      else await instituteService.updateInstitute(this.state.data);
      // this.props.history.goBack();
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.match.params.id;

    const buttonText = getFormButtonText(id);

    const titleText = getFormTitleText(id, this.formJson.alias);
    return (
      <Card>
        <CardHeader title={t(titleText)} />
        <form onSubmit={(event) => this.handleSubmit(event, id)}>

          {this.renderForm(this.formJson, dropDowns)}

         
        </form>
      </Card>
    );
  }
}

export default withTranslation(["institute", "common"])(InstituteForm);
