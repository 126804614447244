import { Button, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ADMISSION_ENQUIRY_TARGET_ROUTE } from "../../../data/routerConstants";
import enquiryTargetService from "../../../services/enquiryTargetService";
import logger from "../../../services/logger";
import Add from "../../common/add";
import ButtonLink from "../../common/buttonLink";
import PagingAndSorting from "../../common/pagingAndSorting";
import EnquiryTargetsTable from "./enquiryTargetsTable";
import MatSelect from "../../common/matSelect";

class EnquiryTargets extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
    calendarId:"",
  };

  getAll = async (query) => {
    try {
      const { data } = await enquiryTargetService.getAllByQuery(query);
      this.setState({ data });
    } catch (ex) {
      logger.error(ex);
    }
  };

  onConfirmOk = async (id) => {
    try {
      await enquiryTargetService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

 async componentDidMount() {
    await this.getAdmissionCalendars();
    this.getAll({});
  }

  handleCalendarChange = (event) => {
    this.setState({ calendarId: event.target.value })
    this.getAll({ calendarId: event.target.value });
  }

  render() {
    const { sortColumn, data,calendarId,admissionCalendars } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container>
            <Grid item xs={12} md={2}>
              <MatSelect valueKey="academicYear" label={t("Filter By Academic Year")} isOptionAllRequired={false} onChange={this.handleCalendarChange} options={admissionCalendars} value={calendarId} />
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} direction="row-reverse">
              <Add link={"/admission-enquiry-targets/new"} title={t("New Enquiry Target")} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <EnquiryTargetsTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            ></EnquiryTargetsTable>
          </Grid>
        </Grid>

        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["enquiryTargets"])(EnquiryTargets);
