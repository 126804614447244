import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import Form from "../../common/form";
import {
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";
import customerJson from "./customer.json"
import customerService from "./customer.service";
import icamelService from "../../../services/icamelService";

class CustomerExternalForm extends Form {
  constructor() {
    super();
    this.formJson = customerJson;
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }


  async componentDidMount() {
    this.getCountryCodes();
    this.getCountries();
    await this.populateForm();
  }


  async populateForm() {
    const id = this.props.match.params.id;
    console.log("populate form id:",id);
    if (id === "new") return;
    try {
      const { data } = await customerService.get(id);
      this.mapToViewModel(data);
    } catch (ex) {
      logger.error(ex);
    }
  }

  onSelectOf = (item, value) => {
    switch (item) {
      case "country":
        this.getStatesByCountry(value);
        break;
      case "state":
        this.getCitiesByState(value);
        break;
    }
  };
  getCountries = async () => {
    try {
      const { data: countries } = await icamelService.getCountries();
      const { dropDowns } = this.state;
      dropDowns.countries = countries;
      this.setState({ dropDowns });
      const { data } = this.state;
      if (data.country) {
        const { data: states } = this.getStatesByCountry(data.country);

      }

    } catch (ex) {
      logger.error(ex);
    }
  };

  getStatesByCountry = async (countryCode) => {
    try {
      const { data: states } = await icamelService.getStatesByCountry(
        countryCode
      );
      const { dropDowns } = this.state;
      dropDowns.states = states;
      this.setState({ dropDowns });
      const { data } = this.state;
      if (data.state) {
        this.getCitiesByState(data.state);
      }
    } catch (ex) {
      logger.error(ex);
    }
  };

  getCitiesByState = async (stateCode) => {
    try {
      const { data: cities } = await icamelService.getCitiesByState(stateCode);
      const { dropDowns } = this.state;
      dropDowns.cities = cities;
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error(ex);
    }
  };



  

  doSubmit = async (id) => {
    try {
      if (id === "new") {
        await customerService.create(this.state.data);
      } else {
        await customerService.update(this.state.data,id);
      }
      window.location.reload();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.match.params.id;
    console.log("cutomer id:",id);

    // const titleText = getFormTitleText(id, this.formJson.alias);

    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, id)}>
          <CardHeader title={t("Register Here")} />
          {this.renderForm(this.formJson, dropDowns,false)}
          <CardContent>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {this.renderMatButton(t("common:Submit"))}
              {/* {this.renderMatButton(t("common:Reset"), "reset", "secondary")} */}
            </Grid>
          </CardContent>
        </form>
      </Card>
    );
  }
}

export default withTranslation(["customer", "common"])(
  CustomerExternalForm
);
