import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PRE_ADMISSION_CALENDAR_ROUTE } from "../../../data/routerConstants";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";

const PreAdmissionCalendarsTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["preAdmissionCalendar"]);
  const columns = [
    // { path: "id", label: t("ID") },
    { path: "academicYear", label: t("Academic Year")},

    {
      path: "note",
      label: t("Description"),
      cardHeader:true
     
    },
    { path: "startDate", label: t("Start Date") },
    { path: "endDate", label: t("End Date") },
    {
      key: "active",
      label: "Enabled",
      content: (preAdmissionCalendar) =>
        preAdmissionCalendar.active ? "Yes" : "No",
    },
    {
      key: "action",
      content: (item) => {
        return (
          <>
          <Edit link={`${PRE_ADMISSION_CALENDAR_ROUTE}/${item.id}`}/> &nbsp; 
          <Delete onDelete={onDelete} item={item} />
          </>

        );
      },
    }
  ];

  return (
    <MatTable
      heading={"List of Calendars"}
      data={data}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default PreAdmissionCalendarsTable;
