import { Card, CardHeader, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import OrgContext from "../../app-context";
import BackButton from "../../common/backButton";
import { getDynamicFormJson } from "../../common/dynamicFormService";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";
import Form from "../../common/form";
import committeeMemberService from "../../../services/committeeMemberService";
import departmentService from "../../../services/departmentService";
import userService from "../../../services/userService";

class CommitteeMemberForm extends Form {
  constructor() {
    super();
    this.formJson = {};
    this.schema = {};
    // this.memberType=""
    // this.getForm();
    this.state = {
      memberType: "",
      dropDowns: {},
      errors: {}
    }
  }

  getForm(type) {
    switch (type) {
      case "INTERNAL":
        this.formJson = getDynamicFormJson("committeeMember");
        this.setState({ data: getStateObject(this.formJson.groups) });
        this.schema = getJoiSchema(this.formJson.groups);
        break;
      case "EXTERNAL":
        this.formJson = getDynamicFormJson("externalCommitteeMember");
        this.setState({ data: getStateObject(this.formJson.groups) });
        this.schema = getJoiSchema(this.formJson.groups);
        break;

    }


  }


  async getUsers(id) {
    if (id) {
      const { data: users } = await userService.getAllByDepartment(id)
      const dropDowns = this.state.dropDowns;
      dropDowns.users = users;
      this.setState({ dropDowns });
    }
  }

  onSelectOf = (selected, value) => {
    //console.log("on select of:", selected, ":", value);
    switch (selected) {
      case "departmentId":
        this.getUsers(value)
        break;

    }
  }

  getDepartments = async () => {
    try {
      const { data: departments } = await departmentService.getAll();
      const dropDowns = this.state.dropDowns;
      dropDowns.departments = departments
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error(ex);
    }
  };

  async populateForm() {
    // const id = this.props.match.params.id;
    const id = this.props.id;
    if (id === "new") {
      if (this.props.bom) {

        this.getForm("EXTERNAL");
        this.setState({ memberType: "EXTERNAL" })
      } else {

        this.getForm("INTERNAL");
        this.setState({ memberType: "INTERNAL" })

      }
      return;
    }
    try {
      const { data } = await committeeMemberService.get(id);
      this.setState({ memberType: data.memberType });

      this.getForm(data.memberType);

      this.mapToViewModel(data);
    } catch (ex) { }
  }

  async componentDidMount() {
    await this.populateForm();
    if (this.state.memberType === "INTERNAL") {
      await this.getDepartments();
      await this.getUsers(this.state.data.departmentId);
    }
  }

  doSubmit = async (id) => {
    try {
      let user = {};
      const { data } = this.state;
      if (this.state.memberType === "EXTERNAL") {
        user = {
          firstName: data.name,
          email: data.email,
          mobile: data.mobile
        }
      } else {
        user = { id: this.state.data.userId }
      }
      const req = {
        ...this.state.data,
        memberType: this.state.memberType,
        user: { ...user },
        committee: { id: this.props.committeeId }
      }
      if (id === "new") await committeeMemberService.create(req);
      else await committeeMemberService.update(req);
      //this.props.history.goBack();
      this.props.onSave();
    } catch (ex) {
      logger.log(ex.response.data.message);
    }
  };

  handleTypeChange = ({ target }) => {

    this.setState({ memberType: target.value });
    this.getForm(target.value);

  }

  renderType() {
    return (
      <FormControl component="fieldset">
        {/* <FormLabel component="legend">Gender</FormLabel> */}
        <RadioGroup row aria-label="type" name="memberType" value={this.state.memberType} onChange={(event) => this.handleTypeChange(event)}>
          <FormControlLabel value="INTERNAL" control={<Radio />} label="Internal Member" />
          <FormControlLabel value="EXTERNAL" control={<Radio />} label="External Member" />
        </RadioGroup>
      </FormControl>
    )
  }

  render() {
    const { t, bom, committee } = this.props;
    const { dropDowns } = this.state;
    // const id = this.props.match.params.id;
    const id = this.props.id;

    const buttonText = getFormButtonText(id);
    const titleText = getFormTitleText(id, this.formJson.alias);
    return (
      <Card>
        {/* <CardHeader title={t(titleText)} action={<BackButton {...this.props} />} /> */}

        {id === "new" && committee.name !== "BOM" && committee.name !== "GC" && this.renderType()}

        <form onSubmit={(event) => this.handleSubmit(event, id)}>

          {this.renderForm(this.formJson, dropDowns)}

        </form>
      </Card>
    );
  }
}
CommitteeMemberForm.contextType = OrgContext;
export default withTranslation(["committeeMember", "common"])(CommitteeMemberForm);
