import React from "react";
import Delete from "../../../common/delete";
import Edit from "../../../common/edit";
import MatTable from "../../../common/matTable";

const RoomsTable = ({ data, sortColumn, onSort, onDelete,onEdit,onNumOfStopsClick,t}) => {
  const columns = [

    {
      path:"floorNum", label:t("Floor #")
    },
    { path: "roomNum", label: t("Room #"),
    content:(item)=>{
      return <>Room {item.roomNum} </>
    }
  },
  {
    path:"numOfBeds", label:t("No.of Beds")
  },
   
    {
      key: "action",
      content: (item) => {
        return <><Edit item={item} onEdit={onEdit} /> &nbsp; <Delete onDelete={onDelete} item={item} /></>;
      },
    }
  ];

  return (
    <MatTable
      data={data}
      heading={t("List of Rooms")}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default RoomsTable;
