import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import Joi from "joi";
import queryString from "query-string";
import React from "react";
import { withTranslation } from "react-i18next";
import enquiryService from "../../../../services/enquiryService";
import degreeService from "../../../../services/degreeService"
import icamelService from "../../../../services/icamelService";
import logger from "../../../../services/logger";
import ButtonLink from "../../../common/buttonLink";
import Form from "../../../common/form";
import InfoDialog from "../../../common/infoDialog";
import TermsAndConditionsView from "../../../configurations/terms-and-conditions/termsAndConditionView";
import programService from "../../../../services/programService";
import termDataService from "../../../../services/termDataService";

class RegistrationForm extends Form {
  state = {
    data: {
      name: "",
      email: "",
      mobile: "",
      country: "",
      state: "",
      city: "",
      institute: "",
      // course: "",
      // specialization: "",
      program:"",
      tcAccepted: "",
      roles: [],
    },
    errors: {},
    countries: [],
    state: [],
    city: [],
    selectedCounty: "",
    selectedState: "",
    institutes: [],
    selectedInstitute: "",
    source: "COLLEGE PORTAL",
    dialogOpen: false,
  };

  schema = {
    name: Joi.string().required().label("Name"),
    email: Joi.string()
      .email({ minDomainSegments: 2, tlds: { allow: ["com", "net", "in"] } })
      .required()
      .label("Email"),
    mobile: Joi.string().length(10).required().label("Mobile"),
    country: Joi.string().allow("").label("Country"),
    state: Joi.string().allow("").label("State"),
    city: Joi.string().allow("").label("City"),
    institute: Joi.number().integer().required().label("Institute"),
    program: Joi.number().integer().required().label("Program"),
    tcAccepted: Joi.boolean().required().valid(true).label("Terms & Conditions"),
    roles: Joi.array().label("Roles"),
  };

  getCountries = async () => {
    try {
      const { data: countries } = await icamelService.getCountries();
      this.setState({ countries });
    } catch (ex) {
      logger.error(ex);
    }
  };

  getStatesByCountry = async (countryCode) => {
    try {
      const { data: states } = await icamelService.getStatesByCountry(
        countryCode
      );
      this.setState({ states });
    } catch (ex) {
      logger.error(ex);
    }
  };

  getCitiesByState = async (stateCode) => {
    try {
      const { data: cities } = await icamelService.getCitiesByState(stateCode);
      this.setState({ cities });
    } catch (ex) {
      logger.error(ex);
    }
  };

  getInstitutes = async () => {
   // console.log("institutes...");
    try {
      const { data: institutes } = await icamelService.getInstitutes();
      console.log("institutes :", institutes);
      this.setState({ institutes });
    } catch (ex) {
      logger.error(ex);
    }
  };

  getCoursesByInstitute = async (instituteId) => {
   // console.log("instituteId :", instituteId);
    try {
      const { data: courses } = await degreeService.getDegreesByInstitute(instituteId);
      this.setState({ courses });
    } catch (ex) {
      logger.error(ex);
    }
  };

  getProgramsByInstitute = async (instituteId) => {
    //console.log("instituteId :", instituteId);
    try {
      const { data: programs } = await programService.getAllByInstitute(instituteId);
      this.setState({ programs });
    } catch (ex) {
      logger.error(ex);
    }
  };

  getTermsByProgram = async (programId) => {
    try {
      const { data: terms } =
        await termDataService.getAllByProgramAndInstitute(programId,this.state.data.institute);
        //.getSpecializationsByDegree(courseId);
      this.setState({ terms });
      if(terms && terms.length>=1){
        this.setState({termId:terms[0].id});
      }
    } catch (ex) {
      logger.error(ex);
    }
  };

  onSelectOf = (item, value) => {
    switch (item) {
      case "institute":
        // this.getCoursesByInstitute(value);
        this.getProgramsByInstitute(value);

        break;
      case "program":
        //console.log("switch selected: ", value);
        this.getTermsByProgram(value);
        break;
      case "country":
        this.getStatesByCountry(value);
        break;
      case "state":
        this.getCitiesByState(value);
        break;
    }
  };

  handleButtonClick=()=>{
    this.setState({dialogOpen:true})
  }

  componentDidMount() {
    this.getCountries();
    this.getInstitutes();
    // this.getCoursesByInstitute("value");
    const { location } = this.props;
    const source = queryString.parse(location?.search).source;
    source
      ? this.setState({ source })
      : this.setState({ source: "COLLEGE_PORTAL" });
  }

  doSubmit = async () => {
    try {
      const {data,termId} = this.state;
      // data.country = countries.find((c) => c.id === data.country).name;
      // data.state = states.find((s) => s.id === data.state).name;
      // data.city = cities.find((ct) => ct.id === data.city).name;
      // const instituteName = institutes.find((inst) => inst.id === data.institute).name;

      // console.log("institute name:", instituteName);
      // const termId=this.state.termId;
console.log("term =============",termId);

      await enquiryService.register({
        ...data,
        userLogin: data.email,
        source: this.state.source,
        termId,
        tcAccepted: true,
      });

      // const { state } = this.props.location;
      //console.log("location: ", this.props.location);
      //  window.location = "/";
      this.props.history.push("/registration-success");
    } catch (ex) {
      const errors = { ...this.state.errors };
      // errors.username = ex.response.data.message;
      this.setState({ errors });
      // this.props.history.goBack();
    }
  };

  render() {
    const { t,onClick } = this.props;
    const {
      countries,
      states,
      cities,
      institutes,
      programs,
      dialogOpen,
    } = this.state;

    return (
      <div >
        {/* <Grid container component="main">
          <Grid item xs={12} sm={12} md={7}></Grid>
          <Grid item xs={12} sm={12} md={5}> */}
            <form onSubmit={this.handleSubmit}>
              <Card>
                <CardHeader title={t("Admission Open for 2024-25")} />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      {this.renderMatInput(
                        {},
                        "name",
                        "Name",
                        "text",
                        "outlined"
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      {this.renderMatInput(
                        {},
                        "email",
                        "Email",
                        "text",
                        "outlined"
                      )}
                     </Grid>

                  
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      {this.renderMatInput(
                        {},
                        "mobile",
                        "Mobile",
                        "text",
                        "outlined"
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      {this.renderMatSelect(
                        {},
                        "country",
                        "Country",
                        countries,
                        "outlined"
                      )}
                    </Grid>

                    <Grid item xs={6}>
                      {this.renderMatSelect(
                        {},
                        "state",
                        "State",
                        states,
                        "outlined"
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {this.renderMatSelect(
                        {},
                        "city",
                        "City",
                        cities,
                        "outlined"
                      )}
                    </Grid>

                    <Grid item xs={6}>
                      {this.renderMatSelect(
                        {},
                        "institute",
                        "Institute",
                        institutes,
                        "outlined"
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {this.renderMatSelect(
                        {},
                        "program",
                        "Program",
                        programs,
                        "outlined"
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      {this.renderMatCheckbox(
                        {},
                        "tcAccepted",
                       t("Accept Terms & Conditions")
                      )}
                      {
                        <ButtonLink
                          item={{ id: "id", value: "terms and conditions" }}
                          title={"Read more..."}
                          variant="outlined"
                          onButtonClick={this.handleButtonClick}
                        />
                      }
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {this.renderMatButton(
                        "Register",
                        "submit",
                        "info",
                        true
                      )}
                    </Grid>

                    <Grid item xs>
                      <Button variant="text" color="primary" onClick={()=>onClick(true)}>
                      {t("Existing User Login")}
                        {/* <Link to="/login">{t("Existing User? Login")}</Link> */}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          {/* </Grid>
        </Grid> */}

        {dialogOpen && (
          <InfoDialog
            id="info-dialog"
            keepMounted
            title={t("Terms and Conditions")}
            content={<TermsAndConditionsView />}
            open={dialogOpen}
            onClose={() => this.setState({ dialogOpen: false })}
            onOk={() => this.setState({ dialogOpen: false })}
          />
        )}
      </div>
    );
  }
}

export default withTranslation(["enquiryForm","common"])(RegistrationForm);
