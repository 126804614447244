import http from "../../../services/httpService";

const apiEndpoint = "/api/hostel-allocations";

function create(data) {
  return http.post(apiEndpoint, data);
}

function update(data,id) {
  return http.patch(`${apiEndpoint}/${id}`, data);
}

function get(id) {
  return http.get(`${apiEndpoint}/${id}`);
}
function getAll() {
  return http.get(apiEndpoint);
}
function getAllByQuery(query) {
  return http.get(`${apiEndpoint}/q`,{params:query});
}

function getAllList() {
  return http.get(apiEndpoint + "/list");
}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getAll,
  getAllByQuery,
  getAllList,
  deleteById,
};
