import logger from "../../services/logger";
import preAdmissionCalendarService from "../../services/preAdmissionCalendarService";
import programService from "../../services/programService";
import DialogBox from "./dialogBox";

class PagingAndSorting extends DialogBox {
  state = { page: 0, rowsPerPage: 25, sortColumn: {},expanded:"",
  // pagination:{page: 0,rowsPerPage: 20,count:0,onPageChange:this.handlePageChange,onRowsPerPageChange:this.handleRowsPerPageChange}

};
  query(search = "") {
    const { page, rowsPerPage: size, sortColumn } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    return { search, page, size, sort };
  }
  prepareQueryString() {
    const { page, rowsPerPage: size, sortColumn } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    const search="";
    return { search,page, size, sort };
  }

  
  handleChangePage = (event, newPage) => {
    //console.log("new page:", newPage);
    this.setState({ page: newPage });
    let query = this.prepareQueryString();
    query.page = newPage;
    this.getPagedData(query);
  };

  handleChangeRowsPerPage = (event) => {
    const size = event.target.value;
    //console.log("rows per page :", size);
    this.setState({ rowsPerPage: size, page: 0 });
    let query = this.prepareQueryString();
    query.size = size;
    query.page = 0;
    this.getPagedData(query);
  };


  handlePageChange = (event, newPage) => {
    // console.log("new page:", newPage,"-",this.state.pagination);
    const pagination={...this.state.pagination}
    pagination.page=newPage
    this.setState({pagination});
    let query = this.prepareQueryString();
    query.page = newPage;
    this.getPagedData(query);
  };

  handleRowsPerPageChange = (event) => {
    const size = event.target.value;
    // console.log("rows per page :", this.state.pagination);
    const pagination={...this.state.pagination}
    pagination.page=0;
    pagination.rowsPerPage=size;
    this.setState({pagination});
    let query = this.prepareQueryString();
    query.size = size;
    query.page = 0;
    this.getPagedData(query);
  };


  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
    const sort = sortColumn.path + "," + sortColumn.order;
    let query = this.prepareQueryString();
    query.sort = sort;
    this.getPagedData(query);
  };

  handleDelete = (item, event) => {
    //console.log("handle delete item id:", item);
    if (event)
      event.stopPropagation();
    this.setState({ id: item.id, confirmDialogOpen: true });
  };

  handleClick = (item,dialog="confirmDialogOpen") => {
    this.setState({ item, [dialog]: true });
  };

  handleEdit = (item, event) => {
    //console.log("item :", item);
    if (event)
      event.stopPropagation();
    this.setState({ id: item.id, dialogOpen: true });
  };

  handleAdd = (item, event) => {
    //console.log("item :", item);
    if (event)
      event.stopPropagation();
    this.setState({ id: item.id, dialogOpen: true });
  };

  handleAccordionExpandChange = (id) => {
    //console.log("panel id:", id);
    const {expanded}=this.state;
    if(expanded===id)
    this.setState({expanded:""})
    else
    this.setState({ expanded: id })
  }

  getPrograms = async () => {
    try {
      const { data: programs } = await programService.getAll();
      this.setState({ programs });
    } catch (ex) {
      logger.error(ex);
    }
  };

  getAdmissionCalendars=async()=>{
    try {
      const { data: admissionCalendars } = await preAdmissionCalendarService.getPreAdmissionCalendars();
      const activeCalendar=admissionCalendars.find(c=>c.active===true);
      // console.log("active calendars:",activeCalendar.id);
      this.setState({ admissionCalendars, calendarId:activeCalendar.id });
    } catch (ex) {
      logger.error(ex);
    }
  }

}

export default PagingAndSorting;
