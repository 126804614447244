import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../services/logger";
import Add from "../common/add";
import PagingAndSorting from "../common/pagingAndSorting";
import hostelService from "./hostel.service";
import HostelTable from "./hostelsTable";
import FormDialog from "../common/formDialog";
import BlocksPage from "./manage-blocks/blocks.page";

class Hostels extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    confirmDialogOpen: false,
    pagination:{page: 0,rowsPerPage: 25,count:0,onPageChange:this.handlePageChange,onRowsPerPageChange:this.handleRowsPerPageChange}

  };

  componentDidMount() {
    this.getPagedData({...this.query()});
  }

  onConfirmOk = async (id) => {
    try {
      await hostelService.deleteById(id);
      await this.getPagedData({...this.query()});
    } catch (ex) {
      logger.error(ex);
    }
  };


  getPagedData = async (query) => {
    try {
      const { data, headers } = await hostelService.getAllByQuery(query);
      const totalElements = headers["x-total-elements"];
      const pagination={...this.state.pagination};
      pagination.count=totalElements;
      this.setState({ data, pagination });
    } catch (ex) {
      logger.error(ex);
    }
  };

  handleNumOfBlocksClick = (item) => {
    this.setState({numOfBlocksDilog:true,hostelId:item.id});

  }
  render() {
    const {sortColumn,data,pagination,numOfBlocksDilog,hostelId} = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item xs={12} sm={12} md={12} lg={12}>
            <Grid container item sm={12} md={12} direction="row-reverse">
              <Add link={"hostels/new"} title={t("Add Hostel")} />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <HostelTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              onNumOfBlocksClick={this.handleNumOfBlocksClick}
              pagination={pagination}
            />
          </Grid>
        </Grid>
        {
          numOfBlocksDilog &&
          <FormDialog
            title={t("Blocks Details")}
            content={<BlocksPage hostelId={hostelId}/>}
            open={numOfBlocksDilog}
            onClose={()=>this.setState({numOfBlocksDilog:false})}
          />
        }
        {this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["hostel","common"])(Hostels);
