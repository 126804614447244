import React from "react";
import { useTranslation } from "react-i18next";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";
import { formatToDate, formatToDateAndTime } from "../../common/dateUtil";
import RupeeIcon from "../../common/rupeeIcon";
import { Typography } from "@mui/material";
import ButtonLink from "../../common/buttonLink";
import { formatInLakhsCrores } from "../../common/util";

const CollectablesTable = ({ data, sortColumn,pagination, onSort, onDelete }) => {
  const { t } = useTranslation(["admissions", "common"]);
  const columns = [
   
    
    { path: "firstName", label: t("Student Name") },
    { path: "mobile", label: t("Mobile") },

    { path: "programName", label: t("common:Program") },
    { path: "termLabel", label: t("Year") },

    // { path: "gender", label: t("Gender") },
    { path: "totalFeeAmount", label: t("Total Fee Amount"),numeric:true,
    className:"text-green",
    content:(item)=>{
      if (item.totalFeeAmount) 
      return formatInLakhsCrores(item.totalFeeAmount)
    } },
    {
      path: "totalPaidAmount",
      label: t("Total Paid"),
      numeric: true,
      className:"text-green",
      content:(item)=>{
        if (item.totalPaidAmount) 
        return formatInLakhsCrores(item.totalPaidAmount)
      }
    },
    { path: "remainingAmount", label: t("Due Amount"),numeric:true,
    className:"text-red",
      content:(item)=>{
        if (item.remainingAmount) 
        return formatInLakhsCrores(item.remainingAmount)
      }
  
  },
     {
      path: "dueDate",
      label: t("Due Date"),
      sort: true,
      content: (item) => {
        return formatToDate(item.dueDate)
      }
    },
    {
      key: "action",
      content: (item) => {
        switch (item.admissionFeePaymentStatus) {
       
          case "PAYMENT_PARTIALLY_DONE":
            return (
              <ButtonLink
                title={t("Pay")}
                link={"/admission-fee-payments/" + item.userId + "?pay=r&programId=" + item.programId+"&termId="+item.termId}
                item
                icon={"currency_rupee"}
              />
            )
          case "PAYMENT_PENDING":
            return (
              <ButtonLink
                title={t("Pay")}
                link={"/admission-fee-payments/" + item.userId + "?programId=" + item.programId+"&termId="+item.termId}
                item
                icon={"currency_rupee"}
              />
            )
        }
      },
    },
    
    
  ];

  return (
    <MatTable
      data={data}
      heading="List of Collectables"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
      paginate={true}
      pagination={pagination}
    />
  );
};

export default CollectablesTable;
