import { Button, Card, CardContent, CardHeader, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import admissionFeeService from "../../../services/admissionFeePaymentService";
import admissionFeeDetailService from "../../../services/admissionFeeDetailService";
import enquiryFollowupService from "../../../services/enquiryFollowupService";
import enquiryService from "../../../services/enquiryService";
import logger from "../../../services/logger";
import AdmissionFeeConfigTableView from "../../admission/admission-fee-config/admissionFeeConfigTableView";
import Add from "../../common/add";
import BackButton from "../../common/backButton";
import ButtonLink from "../../common/buttonLink";
import FormDialog from "../../common/formDialog";
import MatChips from "../../common/matChips";
import MatTablePagination from "../../common/matTablePagination";
import PagingAndSorting from "../../common/pagingAndSorting";
import EnquiryFollowupForm from "./enquiryFollowupForm";
import EnquiryFollowupsTable from "./enquiryFollowupsTable";
import Edit from "../../common/edit";
import EnquiryFollowupTimeline from "./enquiryFollowupTimeline";

class EnquiryFollowups extends PagingAndSorting {
  state = {
    data: [],
    fees: [],
    selectedChip: "ALL",
    sortColumn: { path: "followUpDate", order: "desc" },
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    confirmDialogOpen: false,
    dialogOpen: false,
    enquiryId: "",
  };

  prepSortColumn() {
    const { sortColumn } = this.state;
    return sortColumn.path + "," + sortColumn.order;
  }

  async componentDidMount() {
    await this.populateData();
    await this.populateFeeData();
  }

  async populateFeeData() {
    // const { data: fees } = await admissionFeeService.getAll();
    try {

      const { data: fees } = await admissionFeeDetailService.getByProgramAndTermAndCalendar(this.state.enquiry.programId, this.state.enquiry.termId, this.state.enquiry.calendarId)
      //console.log("fee details:",fees);
      this.setState({ fees: [fees] });
    } catch (error) {

    }
  }

  async populateData() {
    const enquiryId = this.props.match.params.enquiryId;
    this.setState({ enquiryId });

    const { data: enquiry } = await enquiryService.get(enquiryId);
    this.setState({ enquiry });

    const { page, rowsPerPage, selectedChip } = this.state;
    this.getPagedData({
      page,
      size: rowsPerPage,
      followUpType: selectedChip,
      sort: this.prepSortColumn(),
      enquiryId,
    });

  }

  handleChipClick = (selectedChip) => {
    //console.log("selectedchip:", selectedChip);
    this.setState({ selectedChip });
    const { page, rowsPerPage } = this.state;
    const sort = this.prepSortColumn();
    this.getPagedData({
      page,
      size: rowsPerPage,
      sort,
      followUpType: selectedChip,
    });
  };

  getPagedData = async (query) => {
    try {
      const enquiryId = query.enquiryId
        ? query.enquiryId
        : this.state.enquiryId;

      query.followUpType = query.followUpType
        ? query.followUpType
        : this.state.selectedChip;

      const { data, headers } = await enquiryFollowupService.getAllByQuery(
        enquiryId,
        query
      );
      const totalElements = headers["x-total-elements"];
      this.setState({ data, totalElements });
    } catch (ex) {
      logger.error(ex);
    }
  };

  onConfirmOk = async (id) => {
    try {
      const { page, rowsPerPage: size, selectedChip } = this.state;
      await enquiryFollowupService.deleteById(this.state.enquiryId, id);
      await this.getPagedData({ page, size, followUpType: selectedChip });
    } catch (ex) {
      logger.error(ex);
    }
  };

  detailsCard(enquiry) {
    const { t } = this.props;
    return (
      <>
        <Card>
          <CardHeader title="Enquiry Details" action={<BackButton {...this.props} />} />
          <br />
          <CardContent>
            <Grid container spacing={3}>


              <Grid item xs={12} md={3}>
                <Typography>
                  {<strong>Candidate Name:&nbsp;&nbsp; </strong>}
                  {enquiry.userName}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>
                  {<strong>Mobile:&nbsp;&nbsp;</strong>}
                  {enquiry.userMobile}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>
                  {<strong>Program: &nbsp;&nbsp;</strong>}
                  {enquiry.programName}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>
                  {<strong> Institute: &nbsp;&nbsp;</strong>}{" "}
                  {enquiry.instituteName}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>
                  {<strong>Father's Name:&nbsp;&nbsp;</strong>}
                  {enquiry.fatherName}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>
                  {<strong>Father's Mobile:&nbsp;&nbsp;</strong>}
                  {enquiry.fatherMobile}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>
                  {<strong>Father's Occupation:&nbsp;&nbsp;</strong>}
                  {enquiry.fatherOccupation}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>
                  {<strong>City:&nbsp;&nbsp;</strong>}
                  {enquiry.city}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>
                  {<strong>Area:&nbsp;&nbsp;</strong>}
                  {enquiry.area}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>
                  {<strong>Source:&nbsp;&nbsp;</strong>}
                  {enquiry.source}
                </Typography>
              </Grid>
              {(this.state.fees && this.state.fees[0]) &&
               <><Grid item xs={12} md={3}>
                  <Typography>
                    {<strong>Fee Amount:&nbsp;&nbsp;</strong>}
                    {this.state.fees[0].totalFeeAmount}
                  </Typography>
                </Grid>
                {/* <Grid item xs={12} md={3}>
                    <Typography>
                      {<strong>Source:&nbsp;&nbsp;</strong>}
                      {enquiry.source}
                    </Typography>
                  </Grid> */}
                  </>
              }

            </Grid>
            {/* <p>
            <span>
            {<strong>Name: </strong>}
              {enquiry.userName}
            </span>
            <span>
              {"        "}
              {<strong> &nbsp;&nbsp;&nbsp; Mobile:</strong>}{" "}
              {enquiry.userMobile}
            </span>
            <span>
              {<strong>&nbsp;&nbsp;&nbsp;Email:</strong>}
              {enquiry.userEmail}
            </span>
          </p>
          <p>
            <span>
              {<strong> Institute: &nbsp;&nbsp;</strong>}{" "}
              {enquiry.instituteName}
            </span>
            <span>
              {<strong>&nbsp;&nbsp;&nbsp;Program: &nbsp;</strong>}
              {enquiry.programName}{" "}
            </span>
          </p> */}
            {/* <Grid container item alignItems={"center"}>
          <Edit icon="article" link={`/admission-enquiries/${enquiry.id}?q=b`}  title={t("Purchase")} />
          </Grid> */}
          </CardContent>
        </Card>
        <br></br>
      </>
    );
  }

  closeDialog = () => {
    this.setState({ dialogOpen: false });
    this.populateData();
  };

  render() {
    const {
      enquiryId,
      enquiry,
      sortColumn,
      data,
      fees,
      rowsPerPage,
      page,
      totalElements,
      selectedChip,
      dialogOpen,
      id
    } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {/* <br /> */}
            {enquiry && this.detailsCard(enquiry)}
          </Grid>
          {/* <Grid item xs={12} sm={12} md={12} lg={12}>
             {<AdmissionFeeConfigTableView
                data={fees}
                heading={"Followup Notes"}
                sortColumn={sortColumn}
                
              />}
          </Grid> */}
          {/* <Grid container item xs={12} sm={12} md={8}>
            <br />
            <MatChips
              items={[
                { label: "ALL", value: "ALL" },
                { label: "CALL", value: "CALL" },
                { label: "SMS", value: "SMS" },
                { label: "E-Mail", value: "EMAIL" },
                { label: "Visit", value: "VISIT" },
              ]}
              selected={selectedChip}
              onChipClick={this.handleChipClick}
            />
          </Grid> */}
          <Grid container item xs={12} sm={12} md={12} >
            <Grid item md={6}>
            <Typography component={"span"} variant="h6">
                Followup History Timeline
              </Typography>
 
            </Grid>
            <Grid item md={6} textAlign={"end"}>

            <Add item={{ id: "new" }} onAdd={this.handleAdd} title={t("New Followup")} />
            </Grid>
            {/* &nbsp;        <Edit  icon="article" link={`/admission-enquiries/${enquiry?.id}?q=b`}  title={t("Issue Application")} /> */}

          </Grid>

          <Grid  item md={6} >
            {/* <Grid item xs={12} sm={12} md={8} lg={8}> */}
              {/* <br /><br /> */}
              {/* <EnquiryFollowupsTable
                data={data}
                heading={"Folloup Notes"}
                sortColumn={sortColumn}
                onSort={this.handleSort}
                onDelete={this.handleDelete}
                t={t}
              />
              <MatTablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              /> */}
             
              <Divider></Divider>
              <EnquiryFollowupTimeline data={data}></EnquiryFollowupTimeline>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={4} lg={4}>
              <br /><br />
             {<AdmissionFeeConfigTableView
                data={fees}
                heading={"Followup Notes"}
                sortColumn={sortColumn}
                // onSort={this.handleSort}
                // onDelete={this.handleDelete}
              />}
            </Grid> */}
          {/* </Grid> */}
        </Grid>

        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}

        {dialogOpen && (
          <FormDialog
            id="form-dialog"
            keepMounted
            title={"Followup Details"}
            content={
              <EnquiryFollowupForm
                id={id ? id : "new"}
                onSave={this.closeDialog}
                enquiryId={enquiryId}
                t={t}
              />
            }
            open={dialogOpen}
            onClose={() => this.setState({ dialogOpen: false })}
          />
        )}

      </React.Fragment>
    );
  }
}

export default withTranslation(["enquiryFollowup"])(EnquiryFollowups);
