import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import Add from "../../common/add";
import PagingAndSorting from "../../common/pagingAndSorting";
import messFeeDetailService from "./messFeeDetail.service";
import MessFeeDetailsTable from "./messFeeDetailsTable.page";
import MatSelect from "../../common/matSelect";

class MessFeeDetails extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    confirmDialogOpen: false,
    calendarId:"",
    pagination:{page: 0,rowsPerPage: 25,count:0,onPageChange:this.handlePageChange,onRowsPerPageChange:this.handleRowsPerPageChange}

  };

 async componentDidMount() {
   await this.getAdmissionCalendars();
    this.getPagedData({...this.query()});
  }

  onConfirmOk = async (id) => {
    try {
      await messFeeDetailService.deleteById(id);
      await this.getPagedData({...this.query()});
    } catch (ex) {
      logger.error(ex);
    }
  };


  getPagedData = async (query) => {
    try {
      const { data, headers } = await messFeeDetailService.getAllByQuery(query);
      const totalElements = headers["x-total-elements"];
      const pagination={...this.state.pagination};
      pagination.count=totalElements;
      this.setState({ data, pagination });
    } catch (ex) {
      logger.error(ex);
    }
  };

  handleCalendarChange = (event) => {
    this.setState({ calendarId: event.target.value },
      ()=>this.getPagedData({...this.query(), calendarId:this.state.calendarId}))
  }



  render() {
    const {sortColumn,data,pagination,admissionCalendars,calendarId} = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12} md={2}>
              <MatSelect valueKey="academicYear" label={t("Filter By Academic Year")} isOptionAllRequired={false} onChange={this.handleCalendarChange} options={admissionCalendars} value={calendarId} />
            </Grid>
            <Grid container item sm={12} md={10} direction="row-reverse">
              <Add link={"mess-fee-details/new"} title={t("Add Mess Fee")} />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MessFeeDetailsTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              pagination={pagination}
            />
          </Grid>
        </Grid>
       
        {this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["hostel","common"])(MessFeeDetails);
