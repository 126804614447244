import { Button, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logger from "../../services/logger";
import PagingAndSorting from "../common/pagingAndSorting";
import committeeService from "../../services/committeeService";
import CommitteeTable from "./committeeTable";
import ButtonLink from "../common/buttonLink";
import CommitteeForm from "./committeeForm";
import FormDialog from "../common/formDialog";
import CommitteeMemberForm from "./committee-member/committeeMemberForm";
import CommitteeMembers from "./committee-member/committeeMembers";
import committeeMemberService from "../../services/committeeMemberService";
import Add from "../common/add";

class Committees extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
  };

  getAll = async () => {
    try {
      const { data } = await committeeService.getAll();
      const committee=data[0];
      this.setState({selectedCommittee:committee})
      this.setState({ data });
      this.getAllByCommittee(committee.id);
    } catch (ex) {
      logger.error(ex);
    }
  };

  onConfirmOk = async (id) => {
    try {
      await committeeService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

 
  handleCommitteeClick=async(committee)=>{
    //console.log("committee id:",committee.id);
    this.setState({selectedCommittee:committee})

    this.getAllByCommittee(committee.id);
  }
  
  async getAllByCommittee(committeeId){
    const {data:committeeMembers}=await  committeeMemberService.getAllByCommittee(committeeId);
    // this.setState({committeeId:committee.id}) 
    this.setState({committeeMembers});
  }

  handleMemberAdd=()=>{
    this.getAllByCommittee(this.state.selectedCommittee?.id);
  }

  closeDialog = () => {
    this.setState({ dialogOpen: false });
    this.getAll();
  };

  componentDidMount() {
    this.getAll();
  }

  render() {
    const { sortColumn, data,dialogOpen,id ,committeeId,committeeMembers,selectedCommittee} = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
         {data.map(committee=> <ButtonLink color={selectedCommittee?.id===committee.id?"secondary":"default"}  title={committee.name} onButtonClick={()=>this.handleCommitteeClick(committee)}/>)}
        </Grid>
        <Grid container>
          &nbsp;
          </Grid>
        <Grid container>
          <Grid container item sm={12} md={12} lg={12} direction="row">

            <Add onAdd={this.handleAdd} item={{id:"new"}} title={t("Add Committee")}/>

          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
           
          <CommitteeMembers onMemberAdd={this.handleMemberAdd} committee={selectedCommittee} members={committeeMembers} />
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}
        {dialogOpen && (
          <FormDialog
            id="category-dialog"
            keepMounted
            title={"Add or Update"}
            content={
              <CommitteeForm
                id={id ? id : "new"}
                onSave={this.closeDialog}
              />
            }
            open={dialogOpen}
            onClose={() => this.setState({ dialogOpen: false })}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["committee"])(
  Committees
);
