import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import authService from "../../services/authService";
import groupService from "../../services/groupService";
import icamelService from "../../services/icamelService";
import logger from "../../services/logger";
import { getDynamicFormJson } from "../common/dynamicFormService";
import Form from "../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../common/formUtils";

class BasicInfoTrustForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("group");
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  async populateForm() {
    const id = authService.getInstituteTenant()
    try {
      const { data } = await groupService.getGroup(id);
      // this.setState({ data: group });
      this.mapToViewModel(data);
    } catch (ex) {
      logger.log(ex);
    }
  }

  async componentDidMount() {
    await this.populateForm();
    this.getCountries();
  }
  onSelectOf = (item, value) => {
    switch (item) {
      case "country":
        this.getStatesByCountry(value);
        break;
      case "state":
        this.getCitiesByState(value);
        break;
    }
  };
  getCountries = async () => {
    try {
      const { data: countries } = await icamelService.getCountries();
      const { dropDowns } = this.state;
      dropDowns.countries = countries;
      this.setState({ dropDowns });
      const { data } = this.state;
      if (data.country) {
        const { data: states } = this.getStatesByCountry(data.country);

      }

    } catch (ex) {
      logger.error(ex);
    }
  };

  getStatesByCountry = async (countryCode) => {
    try {
      const { data: states } = await icamelService.getStatesByCountry(
        countryCode
      );
      const { dropDowns } = this.state;
      dropDowns.states = states;
      this.setState({ dropDowns });
      const { data } = this.state;
      if (data.state) {
        this.getCitiesByState(data.state);
      }
    } catch (ex) {
      logger.error(ex);
    }
  };

  getCitiesByState = async (stateCode) => {
    try {
      const { data: cities } = await icamelService.getCitiesByState(stateCode);
      const { dropDowns } = this.state;
      dropDowns.cities = cities;
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error(ex);
    }
  };

  doSubmit = async (id) => {
    try {
      if (id === "new") await groupService.createGroup(this.state.data);
      else await groupService.updateGroup(this.state.data);
      this.props.history.goBack();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    // const id = this.props.match.params.id;

    // const buttonText = getFormButtonText(id);

    const titleText = getFormTitleText("id", this.formJson.alias);
    return (
      <Card>
          <CardHeader title={t(titleText)}  />
        <form onSubmit={(event) => this.handleSubmit(event, "edit")}>

          {this.renderForm(this.formJson, dropDowns)}

        
        </form>
      </Card>
    );
  }
}

export default withTranslation(["group", "common"])(BasicInfoTrustForm);
