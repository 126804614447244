import { createTheme } from "@mui/material";


const font = "'Heebo', serif";
export function getTheme(theme) {
  switch (theme.name) {
    case "gvet":
      return createGvetTheme(theme);
    default:
     return createDefaultTheme(theme)
  }
 
}

function createGvetTheme1(theme){
  return createTheme(
    // adaptV4Theme(
      {
        direction:"rtl",
     typography: {
       fontFamily: font,
       button: {
         textTransform: "none",
       },
     },
     palette: {
       secondary: {
         light: "#5c9ff6",
         main: "#0c71c3",
         dark: "#004792",
         contrastText: "#ffffff",
       },
       primary: {
         light: "#6a59be",
         main: "#382f8d",
         dark: "#00095f",
         contrastText: "#ffffff",
       },
       success: {
        light: "#6a59be",
        main: "#44b155",
        dark: "#00095f",
        contrastText: "#ffffff",
      },
      neutral:{
        
        main: "#00095f",
      
      },  
      default: {
        light: "#ffffff",
        main: "#ffffff",
        dark: "#ffffff",
        contrastText: "#fefefe",
      },
      
       
     },
   }
  //  )
   );
 }

function createDefaultTheme(theme){
 return createTheme(
    {
    // typography: {
    //   fontFamily: font,
    //   button: {
    //     textTransform: "none",
    //   },
    // },
    palette: {
      secondary: {
        light: "#ecbe91",
        main: "#b88e63",
        dark: "#866138",
        contrastText: "#000000",
      },
      primary: {
        light: "#5970b8",
        main: "#3450a3",
        dark: "#162b75",
        contrastText: "#ffffff",
      },
      success:{
        light: "#81c784",
        main: "#5bc09a",
        dark: "#388e3c",
        contrastText: "rgba(0, 0, 0, 0.87)"
      },
      error:{
        main:"#ED5C4D"
      },
      default:{
        light:"#757575",
        // main:"#cacacae3",
        main:"#cacaca78",
        contrastText: "#151414"
      },
      outlinedDefault:{
        main:"#151414"
      }
    },
  }
  );
}

function createGvetTheme(theme){
  return createTheme(
   // adaptV4Theme(
     {
     typography: {
       fontFamily: font,
       button: {
         textTransform: "none",
       },
     },
     palette: {
      primary: {
        light: "#735852",
        main: "#472f2a",
        dark: "#210600",
        contrastText: "#ffffff",
      },
       secondary: {
         light: "#ecbe91",
         main: "#b88e63",
         dark: "#866138",
         contrastText: "#000000",
       },
       success:{
         light: "#81c784",
         main: "#4caf50",
         dark: "#388e3c",
         contrastText: "rgba(0, 0, 0, 0.87)"
       }
     },
   }
   // )
   );
 }
