import React from 'react';
import MatTable from '../../common/matTable';
import { formatInLakhsCrores } from '../../common/util';
const InstitutesFeeTable = ({data}) => {
    const columns = [
        {
          path: 'institute_name',
          label: "Institute Name"
        }, {
          path: 'billing',
          label: "Billing",
          content:(item)=>{
            return formatInLakhsCrores(item.billing)
          },
          numeric: true
  
        },
        {
          path: 'collection',
          label: "Collection",
          content:(item)=>{
            return formatInLakhsCrores(item.collection)
          },
          numeric: true
  
        }, {
          path: 'discount',
          label: "Discount",
          content:(item)=>{
            return formatInLakhsCrores(item.discount)
          },
          numeric: true
  
        }, {
          path: 'collectable',
          label: "Balance",
          content:(item)=>{
            return formatInLakhsCrores(item.collectable)
          },
          numeric: true
        }
      ]
  
      return <>
        <MatTable heading={"Fee Collection"} columns={columns} data={data} />
      </>
}
 
export default InstitutesFeeTable;