import React from "react";
import { useTranslation } from "react-i18next";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";

const MessFeeDetailsTable = ({ data, sortColumn, onSort, onDelete,pagination }) => {
  const { t } = useTranslation(["hostel","common"]);
  const columns = [
    { path: "academicYear", label: t("Academic Year") },
    { path: "hostelName", label: t("Hostel Name")},
  { path: "numOfMeals", label: t("# of Meals/Day"),numeric:true},
    { path: "feeAmount", label: t("Fee Amount"),numeric:true,
  },
  
  {
    key: "action",
    content: (item) => {
      return <> 
      <Edit link={`mess-fee-details/${item.id}`} /> &nbsp; 
      <Delete onDelete={onDelete} item={item} /></>;
    },
  }
  ];

  return (
    <MatTable
      data={data}
      heading={t("Mess Fee Details")}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
      paginate={true}
      pagination={pagination}
    />
  );
};

export default MessFeeDetailsTable;
