import React from "react";
import { useTranslation } from "react-i18next";
import ButtonLink from "./buttonLink";
import ButtonLinkIcon from "./buttonLinkIcon";
import { Hidden } from "@mui/material";
const Edit = ({ link, item, onEdit, title = "Edit", icon = "edit", variant = "contained", iconOnly = false  }) => {
  const { t } = useTranslation(["common"]);

  return (
    <React.Fragment>
      <Hidden only={["xs", "sm"]}>
        {iconOnly ? <ButtonLinkIcon
          title={title}
          link={link}
          item={item}
          icon={icon}
          variant={variant}
          onButtonClick={onEdit}
          // style={{ padding: "0px 8px" }}
        />
          : <ButtonLink
            title={title}
            link={link}
            item={item}
            icon={icon}
            variant={variant}
            onButtonClick={onEdit}
            style={{ marginLeft:"auto" }}

          />
        }
      </Hidden>
      <Hidden only={["md","lg","xl"]}>
        <ButtonLinkIcon
          title={title}
          link={link}
          item={item}
          icon={icon}
          variant={variant}
          onButtonClick={onEdit}
          // style={{ padding: "0px 8px" }}
        />
      </Hidden>
    </React.Fragment>
  );
};

export default Edit;
