import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ADMISSION_ENQUIRY_ROUTE } from "../../../data/routerConstants";
import authService from "../../../services/authService";
import ButtonLink from "../../common/buttonLink";
import MatTable from "../../common/matTable";
import { Chip, Icon, Typography } from "@mui/material";
import { formatToDate } from "../../common/dateUtil";
import RupeeIcon from "../../common/rupeeIcon";
import { formatInLakhsCrores } from "../../common/util";

const AdmissionApplicantTable = ({ data, totalFeeAmount, sortColumn,pagination, onSort, onDelete }) => {
  const { t } = useTranslation(["enquiries", "common"]);
  const columns = [
    {
      path: "submittedDate",
      label: t("Submitted Date"),
      content: (item) => {  
        return formatToDate(item.submittedDate)
      },
      sort: true,
    },
    {
      path: "firstName", label: t("Name"),
      content: (item) => {
        return (
          <Link to={`/fill-admission-details/${item.userId}/${item.status}`}>
            {item.firstName}
          </Link>
        );
      },
    },
    { path: "mobile", label: t("Mobile #") },
    // { path: "userEmail", label: t("Email") },
    {
      path: "programName",
      label: t("common:Program"),
      sort: true,
    },

    {
      path: "totalFeeAmount",
      label: t("Total Fee"),
      numeric: true,
      content:(item)=>{
        return formatInLakhsCrores(item.totalFeeAmount)
      },
      className:"text-blue"


    },
    {
      path: "totalPaidAmount",
      label: t("Total Paid"),
      numeric: true,
      className:"text-green",
      content:(item)=>{
        // if (item.totalPaidAmount) 
        return item.totalPaidAmount!=null && item.totalPaidAmount!=0? formatInLakhsCrores(item.totalPaidAmount):"0"
      }
    },
    {
      key: "discountAmount",
      label: t("Discount"),
      numeric:true,
      content: (item) => {
        // if (item.discountApplied) {
          return item.discountAmount && item.discountAmount!=0?formatInLakhsCrores(item.discountAmount):"0"
        // }

      }
    },
    {
      path: "remainingAmount",
      label: t("Balance"),
      numeric: true,
      className:"text-red",
      content:(item)=>{
        // if (item.remainingAmount) 
        return (item.remainingAmount && item.remainingAmount!=0)?formatInLakhsCrores(item.remainingAmount):"0"
      }
    },
    {
      path: "dueDate",
      label: t("Due Date"),
      numeric: true,
      // className:"text-red",
      content:(item)=>{
        if (item.remainingAmount) 
        return <Typography><span> {formatToDate(item.dueDate)} </span></Typography>
      }
    },
    {
      key: "action",
      content: (item) => {
        switch (item.admissionFeePaymentStatus) {
          case "PAYMENT_DONE":
            return (
              <ButtonLink
                title={t("Tnxs")}
                link={"/admission-fee-payments/" + item.userId + "?pay=r&programId=" + item.programId+"&termId="+item.termId}
                item
                icon={"payments"}
              />
            )
          case "PAYMENT_PARTIALLY_DONE":
            return (
              <ButtonLink
                title={t("Pay")}
                link={"/admission-fee-payments/" + item.userId + "?pay=r&programId=" + item.programId+"&termId="+item.termId}
                item
                icon={"currency_rupee"}
              />
            )
          case "PAYMENT_PENDING":
            return (
              <ButtonLink
                title={t("Pay")}
                link={"/admission-fee-payments/" + item.userId + "?programId=" + item.programId+"&termId="+item.termId}
                item
                icon={"currency_rupee"}
              />
            )
        }
      },
    },
  ];

  return (
    <MatTable
      data={data}
      heading="List of Applications/Admissions"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
      paginate={true}
      pagination={pagination}
    />
  );
};

export default AdmissionApplicantTable;
