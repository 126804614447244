import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import applicationFeeConfigService from "../../../services/applicationFeeConfigService";
import logger from "../../../services/logger";
import Add from "../../common/add";
import PagingAndSorting from "../../common/pagingAndSorting";
import ApplicationFeeConfigsTable from "./applicationFeeConfigsTable";
import MatSelect from "../../common/matSelect";

class ApplicationFeeConfigs extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
    calendarId:"",
  };

  async componentDidMount() {
    await this.getAdmissionCalendars();
    this.getAll();
  }

  onConfirmOk = (id) => {
    this.deleteById(id);

  };

  getAll = async (query) => {
    try {
      const { data } = await applicationFeeConfigService.getAllByQuery(query);
      this.setState({ data });
    } catch (ex) {
      logger.error(ex);
    }
  };

  deleteById = async (id) => {
    try {
      await applicationFeeConfigService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  handleCalendarChange = (event) => {
    this.setState({ calendarId: event.target.value })
    this.getAll({ calendarId: event.target.value });
  }

  render() {
    const { sortColumn, data, admissionCalendars, calendarId } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container>
            <Grid item xs={12} md={2}>
              <MatSelect valueKey="academicYear" label={t("Filter By Academic Year")} isOptionAllRequired={false} onChange={this.handleCalendarChange} options={admissionCalendars} value={calendarId} />
            </Grid>
            <Grid container item xs={12} sm={10} direction="row-reverse">
              <Add link={"/admission-application-form-fee-configs/new"} title={t("New Application Fee")} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ApplicationFeeConfigsTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            ></ApplicationFeeConfigsTable>
          </Grid>
        </Grid>

        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["applicationFeeConfigs"])(
  ApplicationFeeConfigs
);
