import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import classnames from "classnames";
import Joi from "joi";
import React from "react";
import { withTranslation } from "react-i18next";
import auth from "../../../services/authService";
import logger from "../../../services/logger";
import Form from "../../common/form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
class ResetPasswordForm extends Form {
  state = {
    data: { password: "" },
    errors: {},
  };

  schema = {
    password: Joi.string().required().label("Password"),
  };
  handleClickShowPassword = (showPassword) => {
    this.setState({ showPassword })
  }
  doSubmit = async () => {
    try {
      const { data } = this.state;
      const key = this.props.match.params.key;
      await auth.resetPassword(data.password, key);

      // const { state } = this.props.location;
      //console.log("location: ", this.props.location);
      window.location = "/";
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { classes, t } = this.props;
    const { data, showPassword } = this.state
    return (
      <div style={{ marginTop: "100px" }}>
        <Grid container>
          <Grid item md={4}></Grid>

          <Grid item md={4}>
            <Card variant="outlined">
              <CardHeader
                title={t("Reset Your Password Here")}
                className={classnames(classes.textColorPrimary)}
              />
              <Divider />
              <CardContent>
                <form onSubmit={this.handleSubmit}>
                  <Grid item>
                    <br />
                    {/* {this.renderMatInput(
                      {},
                      "password",
                      t("New Password"),
                      "password",
                      "outlined",
                      "medium"
                    )} */}

                    <TextField
                      fullWidth
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => this.handleClickShowPassword(!showPassword)}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      label="Create New Password"
                      name="password"
                      value={data["password"]}
                      size={"small"}
                      onChange={(event) => this.handleChange(event, "password", {})}
                    />
                  </Grid>

                  <Grid item>
                    {this.renderMatButton(
                      t("Submit"),
                      "submit",
                      "secondary",
                      true
                    )}
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>
      </div>
    );
  }
}

export default withTranslation(["common"])(ResetPasswordForm);
