import { AppBar, Container} from "@mui/material";
import {makeStyles} from "@mui/styles"
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import "./App.css";
import admissionCalendarForm from "./components/admission/admission-calendar/admissionCalendarForm";
import admissionCalendars from "./components/admission/admission-calendar/admissionCalendars";
import admissionFeeConfigForm from "./components/admission/admission-fee-config/admissionFeeConfigForm";
import admissionFeeConfigs from "./components/admission/admission-fee-config/admissionFeeConfigs";
import admissionPartnerForm from "./components/admission/admission-partners/admissionPartnerForm";
import admissionPartners from "./components/admission/admission-partners/admissionPartners";
import HeaderAndNavbar from "./components/base/header-navbar/headerAndNavbar";
import ActivatePasswordForm from "./components/base/manage-password/activatePasswordForm";
import ForgotPasswordForm from "./components/base/manage-password/forgotPasswordForm";
import ForgotPasswordSuccess from "./components/base/manage-password/forgotPasswordSuccess";
import ResetPasswordForm from "./components/base/manage-password/resetPasswordForm";
import RegistrationForm from "./components/base/auth/registration/registrationForm";
import RegistrationSuccess from "./components/base/auth/registration/registrationSuccess";
import RoleForm from "./components/base/role-management/roleForm";
import Roles from "./components/base/role-management/roles";
import UserForm from "./components/base/user-management/userForm";
import Users from "./components/base/user-management/users";
import BoardOrUniversities from "./components/board-or-university/boardOrUniversities";
import BoardOrUniversityForm from "./components/board-or-university/boardOrUniversityForm";
import ProtectedRoute from "./components/common/protectedRoute";
import enquiryDocumentConfigForm from "./components/configurations/enquiry-document-configuration/enquiryDocumentConfigForm";
import enquiryDocumentConfigs from "./components/configurations/enquiry-document-configuration/enquiryDocumentConfigs";
import termsAndConditionForm from "./components/configurations/terms-and-conditions/termsAndConditionForm";
import termsAndConditions from "./components/configurations/terms-and-conditions/termsAndConditions";
// import EnquiryTimeline from "./components/enquiry-timeline/enquiryTimeline";
import enquiryTimelineCopy from "./components/enquiry-timeline/enquiryTimelineCopy";
import GroupForm from "./components/goup/groupForm";
import Groups from "./components/goup/groups";
import Home from "./components/home";
import instituteBasicForm from "./components/institute/instituteBasicForm";
import instituteBasics from "./components/institute/instituteBasics";
import InstituteForm from "./components/institute/instituteForm";
import Institutes from "./components/institute/institutes";
import applications from "./components/manage-applications/verifyApprove/applications";
import applicantFollowupForm from "./components/manage-applications/applicant-followup/applicantFollowupForm";
import applicantFollowups from "./components/manage-applications/applicant-followup/applicantFollowups";
import applicantsCopy from "./components/manage-applications/applicant/applicantsCopy";
import ApplicationFeeConfigForm from "./components/manage-applications/application-fee/applicationFeeConfigForm";
import ApplicationFeeConfigs from "./components/manage-applications/application-fee/applicationFeeConfigs";
// import myApplicationForm from "./components/manage-applications/application-form/myApplicationForm";
import myApplications from "./components/manage-applications/application-form/myApplications";
import enquiryAllocations from "./components/manage-enquiries/enquiry-allocation/enquiryAllocations";
import EnquiryFollowupForm from "./components/manage-enquiries/enquiry-followup/enquiryFollowupForm";
import EnquiryFollowups from "./components/manage-enquiries/enquiry-followup/enquiryFollowups";
import enquiryTargetForm from "./components/manage-enquiries/enquiry-target/enquiryTargetForm";
import enquiryTargets from "./components/manage-enquiries/enquiry-target/enquiryTargets";
import Enquiries from "./components/manage-enquiries/enquiry/enquiries";
import enquiriesQueue from "./components/manage-enquiries/enquiry/enquiriesQueue";
import enquiryForm from "./components/manage-enquiries/enquiry/enquiryForm";
import myEnquiry from "./components/manage-enquiries/enquiry/myEnquiry";
import NotFound from "./components/notFound";
import PreAdmissionCalendarForm from "./components/pre-admission/calendar/preAdmissionCalendarForm";
import PreAdmissionCalendars from "./components/pre-admission/calendar/preAdmissionCalendars";
import AddressForm from "./components/profile/address/addressForm";
import EducationForm from "./components/profile/education/educationForm";
import Educations from "./components/profile/education/educations";
import MyProfile from "./components/profile/myProfile";
import StudentPersonalInfoForm from "./components/profile/personal-info/studentPersonalInfoForm";
import uploadDocForm from "./components/profile/upload-docs/uploadDocForm";
import uploadDocs from "./components/profile/upload-docs/uploadDocs";
import ProgramForm from "./components/program/programForm";
import Programs from "./components/program/programs";
import SpecializationDynamicForm from "./components/program/specialization/specializationDynamicForm";
import Specializations from "./components/program/specialization/specializations";
import {
  ADMISSION_APPLICATION_FORM_FEE_CONFIG_FORM_ROUTE,
  ADMISSION_APPLICATION_FORM_FEE_CONFIG_ROUTE,
  ADMISSION_ENQUIRY_FORM_ROUTE,
  ADMISSION_ENQUIRY_ROUTE,
  BOARD_OR_UNIVERSITY_FORM_ROUTE,
  BOARD_OR_UNIVERSITY_ROUTE,
  DEPT_OR_PRGM_FORM_ROUTE,
  DEPT_OR_PRGM_ROUTE,
  EDUCATION_FORM_ROUTE,
  EDUCATION_ROUTE,
  ENQUIRY_FOLLOWUP_FORM_ROUTE,
  ENQUIRY_FOLLOWUP_ROUTE,
  GROUP_FORM_ROUTE,
  GROUP_ROUTE,
  INSTITUTE_FORM_ROUTE,
  INSTITUTE_ROUTE,
  MY_ADMISSION_APPLICATION_ROUTE,
  PRE_ADMISSION_CALENDAR_FORM_ROUTE,
  PRE_ADMISSION_CALENDAR_ROUTE,
  STUDENT_PERSONAL_INFO_FORM_ROUTE,
} from "./data/routerConstants";
import authService from "./services/authService";
import Auth from "./components/base/auth/auth";
import OrgContext from "./components/app-context";
import instituteService from "./services/instituteService";
import groupService from "./services/groupService";
import SynizaTrust from "./components/accredize/synizaTrust";
import feeCategories from "./components/manage-admissions/fee-template/feeCategories";
import feeDetails from "./components/manage-admissions/fee-details/feeDetails";
import feeDetailForm from "./components/manage-admissions/fee-details/feeDetailForm";
import verifyAndApproveApplicationForm from "./components/enquiry-timeline/verify-approve-application/verifyAndApproveApplicationForm";
import committeeForm from "./components/committee/committeeForm";
import committees from "./components/committee/committees";
import departmentForm from "./components/department/departmentForm";
import departments from "./components/department/departments";
import SynizaInstitute from "./components/accredize/synizaInstitute";
import employees from "./components/employee/employees";
import AdmissionManagerDashboard from "./components/dashboards/admissionManagerDashboard";
import feeDetailView from "./components/manage-admissions/fee-details/feeDetailView";
import Departments from "./components/department/departments";
import NonTeachingDepartments from "./components/department/nonTeachingDepartments";
import userTicketForm from "./components/ticketing/userTicketForm";
import userTickets from "./components/ticketing/userTickets";
import resolveTickets from "./components/ticketing/resolveTickets";
import resolveTicketForm from "./components/ticketing/resolveTicketForm";
// import ApplicationFeeReceiptPDF from "./components/enquiry-timeline/payment-and-receipt/applicationFeeReceiptPDF";
import FillApplicantDetails from "./components/manage-applications/applicant/fillApplicantDetails";
import admissionApplicants from "./components/manage-admissions/admission-fee/admissionApplicants";
import admissoinFeePaymentForm from "./components/manage-admissions/admission-fee/admissoinFeePaymentForm";
import AdmissionFeePayments from "./components/manage-admissions/admission-fee/admissionFeePayments";
import FillAdmissionDetails from "./components/manage-admissions/admission-fee/fillAdmissionDetails";
import feeStructures from "./components/manage-admissions/fee-template/feeStructures";
import academicCalendars from "./components/academics/academic-calendar/academicCalendars";
import academicCalendarForm from "./components/academics/academic-calendar/academicCalendarForm";
import eventsCalendarForm from "./components/academics/events-calendar/eventsCalendarForm";
import eventsCalendars from "./components/academics/events-calendar/eventsCalendars";
import holidayCalendarForm from "./components/academics/holiday-calendar/holidayCalendarForm";
import holidayCalendars from "./components/academics/holiday-calendar/holidayCalendars";
import eventsCalendarsView from "./components/academics/events-calendar/eventsCalendarsView";
import admissionFeePaymentTransactions from "./components/manage-admissions/admission-fee/admissionFeePaymentTransactions";
import studentForm from "./components/manage-students/studentForm";
import students from "./components/manage-students/students";
import BillingAnalysisByProgram from "./components/manage-admissions/dashboard-cards/billingAnalysisByProgram";
import EnquiryAnalysisByProgram from "./components/manage-enquiries/dashboard-cards/enquiryAnalysisByProgram";
import ApplicationAnalysisByProgram from "./components/manage-applications/dashboard-cards/applicationAnalysisByProgram";
import AdmissionAnalysisByProgram from "./components/manage-admissions/dashboard-cards/admissionAnalysisByProgram";
import admissions from "./components/manage-admissions/admissions/admissions";
import collections from "./components/manage-billing/collections/collections";
import collectables from "./components/manage-billing/collectables/collectables";
import XmppClient from "./components/chat/xmppClient";
import StanzaChat from "./components/chat/stanzaChat";
import XmppChat from "./components/chat/xmppChat";
import academicCourseForm from "./components/academics/academic-course/academicCourseForm";
import academicCourses from "./components/academics/academic-course/academicCourses";
import classTimetableConfigForm from "./components/academics/academic-class-timetable/configuration/classTimetableConfigForm";
import classTimmetableConfigs from "./components/academics/academic-class-timetable/configuration/classTimmetableConfigs";
import classSectionFormPage from "./components/academics/academic-class-section/classSectionForm-page";
import classSectionsPage from "./components/academics/academic-class-section/classSections-page";
import busesPage from "./components/transportation/manage-buses/buses-page";
import busFormPage from "./components/transportation/manage-buses/busForm-page";
import routeFormPage from "./components/transportation/manage-routes/routeForm.page";
import routesPage from "./components/transportation/manage-routes/routes.page";
import mileagesPage from "./components/transportation/manage-mileages/mileages.page";
import hostelFormPage from "./components/hostel/hostelForm.page";
import hostelsPage from "./components/hostel/hostels.page";
import blockFormPage from "./components/hostel/manage-blocks/blockForm.page";
import blocksPage from "./components/hostel/manage-blocks/blocks.page";
import ExternalEnquiryForm from "./components/base/auth/registration/externalEnquiryForm";
import MDDashboard from "./components/dashboards/md/mdDashboard";
import customersPage from "./components/cmp/customer/customers.page";
import CustomerExternalFormPage from "./components/cmp/customer/customerExternalForm.page";
import hostelFeeFormPage from "./components/hostel/hostel-fee-details/hostelFeeDetailForm.page";
import hostelFeesPage from "./components/hostel/hostel-fee-details/hostelFeeDetails.page";
import messFeeDetailFormPage from "./components/hostel/hostel-mess-fee-details/messFeeDetailForm.page";
import messFeeDetailsPage from "./components/hostel/hostel-mess-fee-details/messFeeDetails.page";
import hostelFeeCollectionFormPage from "./components/hostel/hostel-fee-payment/hostelFeeCollectionForm.page";
import hostelFeeCollectionsPage from "./components/hostel/hostel-fee-payment/hostelFeeCollections.page";
import applicantBasicDetailsForm from "./components/enquiry-timeline/applicant-basic-details/applicantBasicDetailForm";
import hostelAllocationFormPage from "./components/hostel/hostel-allocation/hostelAllocationForm.page";
import hostelAllocationsPage from "./components/hostel/hostel-allocation/hostelAllocations.page";

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({

  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  appBarFull: {
    [theme.breakpoints.up('md')]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
  },
  content: {
    marginTop: "30px",
    flexGrow: 1,
    padding: theme.spacing(3),
    // marginLeft:`${drawerWidth}px`
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth
    },

  },

  contentFull: {
    marginTop:"30px",
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginLeft: 0
    },
    // background: "linear-gradient(to bottom, #0052CC, #2684FF)",
    // backgroundAttachment: "fixed",
    // height: "100vh",
    // margin: 0,
    // overflow: "hidden"

  },

  authContent: {
    border: "2px solid white",
    borderRadius: "4px",
    background: "white",
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
    margin:"Auto"

  },

  authContentFull:{
    border: "2px solid white",
    borderRadius: "4px",
    background: "white",
    // boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
    margin:"Auto"
  },

  // root: {
  //   display: "flex",
  // },
  // toolbar: {
  //   paddingRight: 24, // keep right padding when drawer closed
  // },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  // appBar: {
  //   zIndex: theme.zIndex.drawer + 1,
  //   transition: theme.transitions.create(["width", "margin"], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen,
  //   }),
  // },
  // appBarShift: {
  //   marginLeft: drawerWidth,
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   transition: theme.transitions.create(["width", "margin"], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // },
  // menuButton: {
  //   marginRight: 36,
  // },
  // menuButtonHidden: {
  //   display: "none",
  // },
  title: {
    flexGrow: 1,
  },
  // drawerPaper: {
  //   position: "relative",
  //   // background:theme.palette.primary.main,
  //   // color:theme.palette.primary.contrastText,
  //   whiteSpace: "nowrap",
  //   width: drawerWidth,
  //   transition: theme.transitions.create("width", {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(0),
    },
  },
  // appBarSpacer: theme.mixins.toolbar,
  // content: {
  //   flexGrow: 1,
  //   height: "100vh",
  //   overflow: "auto",
  //   background: '#e7e3db'
  // },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),

  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  navActive: {
    '&:hover': {
      color: theme.palette.secondary.dark,
      borderRight: "3px solid " + theme.palette.secondary.dark,
      // backgroundColor:theme.palette.secondary.dark
    },
    color: theme.palette.secondary.dark,
    borderRight: "3px solid " + theme.palette.secondary.dark,
    // backgroundColor:theme.palette.secondary.dark
  },
  navMainActive: {
    '&:hover': {
      color: theme.palette.secondary.dark,
      // borderRight: "3px solid " + theme.palette.secondary.dark,
      // backgroundColor:theme.palette.secondary.dark
    },
    color: theme.palette.secondary.dark,
    // borderRight: "3px solid " + theme.palette.secondary.dark,
    // backgroundColor:theme.palette.secondary.dark
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
  listItemIcon: {
    minWidth: 38,
  },
  footer: {
    paddingLeft: "25px",
    paddingRight: "25px",
    backgroundColor: theme.palette.primary.dark,
    paddingTop: "5px",
    paddingBottom: "5px",
    color: theme.palette.primary.contrastText,
    position: "fixed",
    bottom: 0,
    width: "100%",
    padding: "5px"
  }
}));


function App() {
  console.warn = console.error = () => { };
  const classes = useStyles();
  const [showHeader,setShowHeader]=useState(true)
  const [open,setOpen]=useState(true)

  const user = authService.getCurrentUser();
  async function getInstituteOrTrust() {
    if (authService.getInstituteTenant()) {
      try {
        const { data } = await instituteService.getInstituteOrTrust(authService.getInstituteTenant());
        const org = data;
        // subscribeToNotification();
        return org ? org : {};
      } catch (error) {

      }
    }
    return {}
  }

  async function getGroup() {
    try {
      const { data: groups } = await groupService.getGroupsInLogin();
      const org = groups ? groups[0] : {};
      // console.log("group data resolve:", org?.name);
      // subscribeToNotification()
      return org;
    } catch (error) {

    }
  }

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    // console.log("header:",urlParams);
    const header = urlParams.get('header');
    // console.log("header value:",header);
    if(header)
      setShowHeader(header)
  },[])


 const handleDrawer=(open)=>{
setOpen(open);
  }



  return (
    <OrgContext.Provider value={user ? getInstituteOrTrust() : getGroup()}>
      <div className={classes.root}>
      {showHeader==true && <HeaderAndNavbar classes={classes} user={user} setDrawer={handleDrawer} />}
        {/* <ToastContainer /> */}
        <main className={user ?( open? classes.content :classes.contentFull): classes.authContentFull}>
          {user && <div className={classes.appBarSpacer} />}
          <Container maxWidth="xxl" className={[classes.container]}>
            <Switch>


            <ProtectedRoute
                path={"/xyz"}
                component={applicantBasicDetailsForm}
              />
              {/* CMP */}
              <ProtectedRoute
                path={"/customers"}
                component={customersPage}
              />

              <Route
                path="/external-customer-registration/:id"
                render={(routeProps) => (
                  <CustomerExternalFormPage {...routeProps} classes={classes} />
                )}
              />

                {/*MD Dashboard */}
                <ProtectedRoute
                path={"/md-dashboard"}
                component={MDDashboard}
              />
              {/*Adission Manager Dashboards */}
              <ProtectedRoute
                path={"/admission-manager-dashboard"}
                component={AdmissionManagerDashboard}
              />

              <ProtectedRoute
                path={"/enquiry-analysis-by-program"}
                component={EnquiryAnalysisByProgram}
              />
              <ProtectedRoute
                path={"/application-analysis-by-program"}
                component={ApplicationAnalysisByProgram}
              />
              <ProtectedRoute
                path={"/admission-analysis-by-program"}
                component={AdmissionAnalysisByProgram}
              />
              <ProtectedRoute
                path={"/billing-analysis-by-program"}
                component={BillingAnalysisByProgram}
              />

             
              {/* Configurations */}

              <ProtectedRoute
                path={"/configurations/terms-and-conditions/:id"}
                component={termsAndConditionForm}
              />

              <ProtectedRoute
                path={"/configurations/terms-and-conditions"}
                component={termsAndConditions}
              />

              <ProtectedRoute
                path={"/configurations/enquiry-document-config/:id"}
                component={enquiryDocumentConfigForm}
              />

              <ProtectedRoute
                path={"/configurations/enquiry-document-config"}
                component={enquiryDocumentConfigs}
              />

              {/* Profile Management */}
              <ProtectedRoute
                path={"/upload-docs/:id"}
                component={uploadDocForm}
              />
              <ProtectedRoute path={"/upload-docs"} component={uploadDocs} />

              <ProtectedRoute
                path={EDUCATION_FORM_ROUTE}
                component={EducationForm}
              />
              <ProtectedRoute path={EDUCATION_ROUTE} component={Educations} />

              <ProtectedRoute path="/addresses/:id" component={AddressForm} />

              <ProtectedRoute
                path={STUDENT_PERSONAL_INFO_FORM_ROUTE}
                component={StudentPersonalInfoForm}
              />

              <ProtectedRoute path="/my-profile" component={MyProfile} />
              {/* <ProtectedRoute path="/enquiry-timeline" component={EnquiryTimeline} /> */}
              <ProtectedRoute path="/enquiry-timeline-copy" component={enquiryTimelineCopy} />
              {/* <ProtectedRoute path="/application-fee-receipt-pdf" component={ApplicationFeeReceiptPDF} /> */}



              {/* Admission Partners */}
              <ProtectedRoute
                path="/admission-partners/:id"
                component={admissionPartnerForm}
              />
              <ProtectedRoute
                path="/admission-partners"
                component={admissionPartners}
              />

              {/* Manage Enquiries / Leads */}
              <ProtectedRoute
                path={"/admission-enquiry-targets/:id"}
                component={enquiryTargetForm}
              />
              <ProtectedRoute
                path={"/admission-enquiry-targets"}
                component={enquiryTargets}
              />

              <ProtectedRoute
                path={"/manage-enquiries/enquiry-allocations"}
                component={enquiryAllocations}
              />

              <ProtectedRoute
                path={ENQUIRY_FOLLOWUP_FORM_ROUTE}
                component={EnquiryFollowupForm}
              />
              <ProtectedRoute
                path={ENQUIRY_FOLLOWUP_ROUTE}
                component={EnquiryFollowups}
              />

              <ProtectedRoute
                path={ADMISSION_ENQUIRY_FORM_ROUTE}
                component={enquiryForm}
              />
              <ProtectedRoute
                path={"/admission-enquiry/:id"}
                component={enquiryForm}
              />

              <ProtectedRoute
                path={ADMISSION_ENQUIRY_ROUTE}
                component={Enquiries}
              />
              <ProtectedRoute
                path={"/manage-enquiries/my-enquiry"}
                component={myEnquiry}
              />

              <ProtectedRoute
                path={"/manage-enquiries/enquiries-queue"}
                component={enquiriesQueue}
              />

              {/* Manage Applications */}

              <ProtectedRoute
                path={"/admission-application/:id"}
                component={enquiryForm}
              />
              <ProtectedRoute
                path={"/manage-applications/:applicantId/applicant-followups/:id"}
                component={applicantFollowupForm}
              />
              <ProtectedRoute
                path={"/manage-applications/:applicantId/applicant-followups"}
                component={applicantFollowups}
              />
              <ProtectedRoute
                path={"/manage-applications/applicants"}
                component={applicantsCopy}
              />

              <ProtectedRoute
                path={"/manage-applications/applications"}
                component={applications}
              />
              <ProtectedRoute
                path={"/manage-applications/verify-approve/:id"}
                component={verifyAndApproveApplicationForm}
              />

              <ProtectedRoute
                path={"/manage-applications/:applicantId/fill-application/"}
                component={FillApplicantDetails}
              />

              <ProtectedRoute
                path={"/verify-and-approve-applications/:id"}
                component={verifyAndApproveApplicationForm}
              />
              <ProtectedRoute
                path={"/verify-and-approve-applications"}
                component={applications}
              />

              <ProtectedRoute
                path={MY_ADMISSION_APPLICATION_ROUTE}
                component={myApplications}
              />

              <ProtectedRoute
                path={ADMISSION_APPLICATION_FORM_FEE_CONFIG_FORM_ROUTE}
                component={ApplicationFeeConfigForm}
              />
              <ProtectedRoute
                path={ADMISSION_APPLICATION_FORM_FEE_CONFIG_ROUTE}
                component={ApplicationFeeConfigs}
              />
              {/* Manage Applications End */}

              {/* Manage Admissions */}
              <ProtectedRoute
                path={"/admissions"}
                component={admissions}
              />
              <ProtectedRoute
                path={"/admission-fee-structure/:id/setup"}
                component={feeCategories}
              />

              <ProtectedRoute
                path={"/admission-fee-structure"}
                component={feeStructures}
              />


              <ProtectedRoute
                path={"/admission-fee-details/:id/:feeStructureId"}
                component={feeDetailForm}
              />

              <ProtectedRoute
                path={"/admission-fee-details"}
                component={feeDetails}
              />

              <ProtectedRoute
                path={"/admission-fee-details-view/:id/:program/:feeStructureId"}
                component={feeDetailView}
              />

              <ProtectedRoute
                path={"/admission-fee-payment/:id"}
                component={admissoinFeePaymentForm}
              />

              <ProtectedRoute
                path={"/admission-fee-payments/:id"}
                component={AdmissionFeePayments}
              />

              <ProtectedRoute
                path={"/admision-applications"}
                component={admissionApplicants}
              />

              <ProtectedRoute
                path={"/fill-admission-details/:applicantId/:status"}
                component={FillAdmissionDetails}
              />
              <ProtectedRoute
                path={"/admission-fee-payment-transactions/:applicantId"}
                component={admissionFeePaymentTransactions}
              />
              {/* Manage Admissions End */}

              {/* Manage Fee Collection */}
              <ProtectedRoute
                path={"/admision-collections"}
                component={collections}
              />
               <ProtectedRoute
                path={"/admision-collectables"}
                component={collectables}
              />
              {/* Manage Fee Collection End */}

              {/* Manage Students */}
              <ProtectedRoute
                path={"/manage-students/:id"}
                component={studentForm}
              />

              <ProtectedRoute
                path={"/manage-students"}
                component={students}
              />

              {/* Admission Admin Configurations */}
              <ProtectedRoute
                path={PRE_ADMISSION_CALENDAR_FORM_ROUTE}
                component={PreAdmissionCalendarForm}
              />
              <ProtectedRoute
                path={PRE_ADMISSION_CALENDAR_ROUTE}
                component={PreAdmissionCalendars}
              />

              <ProtectedRoute
                path={"/admission-calendars/:id"}
                component={admissionCalendarForm}
              />
              <ProtectedRoute
                path={"/admission-calendars"}
                component={admissionCalendars}
              />

              <ProtectedRoute
                path={"/admission-fee-configs/:id"}
                component={admissionFeeConfigForm}
              />
              <ProtectedRoute
                path={"/admission-fee-configs"}
                component={admissionFeeConfigs}
              />

              {/* Basic Data Management */}
              <ProtectedRoute
                path={DEPT_OR_PRGM_FORM_ROUTE}
                component={SpecializationDynamicForm}
              />
              <ProtectedRoute
                path={DEPT_OR_PRGM_ROUTE}
                component={Specializations}
              />
              {/* Program Routes */}
              <ProtectedRoute path={"/programs/:id"} component={ProgramForm} />
              <ProtectedRoute path={"/programs"} component={Programs} />

              {/* Department Routes */}
              <ProtectedRoute path={"/departments/:id"} component={departmentForm} />
              <ProtectedRoute path={"/departments"} component={departments} />

              {/* <ProtectedRoute path={"/departments-teaching/:id"} */}
              {/* render={(routeProps)=><DepartmentForm type={"TEACHING"} {...routeProps} classes={classes}/>  }/> */}
              <ProtectedRoute path={"/teaching-departments"}
                render={(routeProps) => <Departments type={"TEACHING"} {...routeProps} classes={classes} />} />
              <ProtectedRoute path={"/non-teaching-departments"}
                render={(routeProps) => <NonTeachingDepartments type={"NON_TEACHING"} {...routeProps} classes={classes} />} />

              {/* Institute Routes */}
              <ProtectedRoute path={INSTITUTE_FORM_ROUTE} component={InstituteForm} />
              <ProtectedRoute path={INSTITUTE_ROUTE} component={Institutes} />
              <ProtectedRoute path={"/about-institute/:id"} component={InstituteForm} />
              <ProtectedRoute path={"/institute-basics/:id"} component={instituteBasicForm} />
              <ProtectedRoute path={"/institute-basics"} component={instituteBasics} />

              {/* Board or University routes */}
              <ProtectedRoute
                path={BOARD_OR_UNIVERSITY_FORM_ROUTE}
                component={BoardOrUniversityForm}
              />
              <ProtectedRoute
                path={BOARD_OR_UNIVERSITY_ROUTE}
                component={BoardOrUniversities}
              />

              {/* Committee Routes */}
              <ProtectedRoute
                path={"/committees/:id"}
                component={committeeForm}
              />
              <ProtectedRoute
                path={"/committees"}
                component={committees}
              />
              {/* Trust or Group or Organization routes */}
              <ProtectedRoute path={GROUP_FORM_ROUTE} component={GroupForm} />
              <ProtectedRoute path={GROUP_ROUTE} component={Groups} />
              <ProtectedRoute path={"/about-organization/:id"} component={GroupForm} />
              <ProtectedRoute path={"/syniza-trust"} component={SynizaTrust} />
              <ProtectedRoute path={"/syniza-institute"} component={SynizaInstitute} />

              {/* Ticket Management System */}
              <ProtectedRoute path={"/user-tickets/:id"} component={userTicketForm} />
              <ProtectedRoute path={"/user-tickets"} component={userTickets} />

              <ProtectedRoute path={"/resolve-tickets/:id"} component={resolveTicketForm} />
              <ProtectedRoute path={"/resolve-tickets"} component={resolveTickets} />


              {/* Academic Features Routing starts */}

              <ProtectedRoute path={"/academic-calendars/:id"} component={academicCalendarForm} />
              <ProtectedRoute path={"/academic-calendars"} component={academicCalendars} />
              <ProtectedRoute path={"/events-calendars/:id"} component={eventsCalendarForm} />
              <ProtectedRoute path={"/events-calendars"} component={eventsCalendars} />
              <ProtectedRoute path={"/events-calendars-view"} component={eventsCalendarsView} />

              <ProtectedRoute path={"/holiday-calendars/:id"} component={holidayCalendarForm} />
              <ProtectedRoute path={"/holiday-calendars"} component={holidayCalendars} />
              <ProtectedRoute path={"/academic-courses/:id"} component={academicCourseForm} />
              <ProtectedRoute path={"/academic-courses"} component={academicCourses} />
              <ProtectedRoute path={"/class-timetable-configs/:id"} component={classTimetableConfigForm} />
              <ProtectedRoute path={"/class-timetable-configs"} component={classTimmetableConfigs} />
              <ProtectedRoute path={"/class-sections/:id"} component={classSectionFormPage} />
              <ProtectedRoute path={"/class-sections"} component={classSectionsPage} />

              {/* Academic Features Routing ends */}

              {/* Transportation Routing starts */}
              <ProtectedRoute path={"/buses/:id"} component={busFormPage} />
              <ProtectedRoute path={"/buses"} component={busesPage} />

              <ProtectedRoute path={"/mileages/:busId"} component={mileagesPage} />

              <ProtectedRoute path={"/routes/:id"} component={routeFormPage} />
              <ProtectedRoute path={"/routes"} component={routesPage} />
               {/* Transportation Routing ends */}

              {/* Hostel Routing starts */}
              <ProtectedRoute path={"/hostels/:id"} component={hostelFormPage} />
              <ProtectedRoute path={"/hostels"} component={hostelsPage} />

              <ProtectedRoute path={"/blocks/:id"} component={blockFormPage} />
              <ProtectedRoute path={"/blocks"} component={blocksPage} />

              <ProtectedRoute path={"/hostel-fee-details/:id"} component={hostelFeeFormPage} />
              <ProtectedRoute path={"/hostel-fee-details"} component={hostelFeesPage} />
              
              <ProtectedRoute path={"/hostel-allocations/:id"} component={hostelAllocationFormPage} />
              <ProtectedRoute path={"/hostel-allocations"} component={hostelAllocationsPage} />
              <ProtectedRoute path={"/hostel-fee-collections/:id"} component={hostelFeeCollectionFormPage} />
              <ProtectedRoute path={"/hostel-fee-collections"} component={hostelFeeCollectionsPage} />

              <ProtectedRoute path={"/mess-fee-details/:id"} component={messFeeDetailFormPage} />
              <ProtectedRoute path={"/mess-fee-details"} component={messFeeDetailsPage} />
               {/* Hostel Routing ends */}

              {/* Chat  */}
              {/* <ProtectedRoute path={"/chat"} component={XmppClient} /> */}
              <Route
                path="/xmpp-chat"
                render={(routeProps) => (
                  <XmppClient {...routeProps} classes={classes} />
                )}
              />

              <Route
                path="/chat"
                render={(routeProps) => (
                  <StanzaChat {...routeProps} classes={classes} />
                )}
              />

              <Route
                path="/xmpp-chat1"
                render={(routeProps) => (
                  <XmppChat {...routeProps} classes={classes} />
                )}
              />
              {/* Chat Ends */}

              {/* App Base features */}
              <Route
                path="/forgot-password"
                render={(routeProps) => (
                  <ForgotPasswordForm {...routeProps} classes={classes} />
                )}
              />

              <Route
                path="/forgot-password-success"
                component={ForgotPasswordSuccess}
              />

              {/* <Route
              path="/login"
              render={(routeProps) => (
                <SignInForm {...routeProps} classes={classes} />
              )}
            /> */}

              <Route
                path="/login"
                render={(routeProps) => (
                  <Auth {...routeProps} classes={classes} />
                )}
              />
              <Route
                path="/registration/new"
                render={(routeProps) => (
                  <RegistrationForm {...routeProps} classes={classes} />
                )}
              />

              <Route
                path="/external-registration/new"
                render={(routeProps) => (
                  <ExternalEnquiryForm {...routeProps} classes={classes} />
                )}
              />

              <Route
                path="/registration-success"
                component={RegistrationSuccess}
              />

              <ProtectedRoute path="/role-management/:id" component={RoleForm} />

              <ProtectedRoute path="/role-management" component={Roles} />

              <ProtectedRoute path="/user-management/:id" component={UserForm} />

              <ProtectedRoute path="/user-management" component={Users} />
              <ProtectedRoute path="/employees" component={employees} />

              <Route
                path="/account/reset/finish/:key"
                render={(routeProps) => (
                  <ResetPasswordForm {...routeProps} classes={classes} />
                )}
              />
              <Route
                path="/account/activate/:key"
                render={(routeProps) => (
                  <ActivatePasswordForm {...routeProps} classes={classes} />
                )}
              />

              <Route path="/not-found" component={NotFound} />
              <ProtectedRoute path="/" exact component={Home} />
              <Redirect to="/not-found" />
            </Switch>
          </Container>
          {/* <div className={classNames(classes.footer, user ? classes.content : classes.contentFull)}>
            &copy; 2023  {2023 !== new Date().getFullYear() && "- " + new Date().getFullYear()}  Powerd By eMagna Solutions
          </div> */}
        </main>

        {showHeader==true && 
        <AppBar position="fixed"
          className={user ? classes.appBar : classes.appBarFull}>
          <div className={classes.footer}>
            &copy; 2023  {2023 !== new Date().getFullYear() && "- " + new Date().getFullYear()}  Powerd By eMagna Solutions
          </div>
        </AppBar>
        }
      </div>
    </OrgContext.Provider>
  );
}

export default App;
