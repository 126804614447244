import React from "react";
import { withTranslation } from "react-i18next";
import authService from "../../services/authService";
import programService from "../../services/degreeService";
import groupService from "../../services/groupService";
import instituteService from "../../services/instituteService";
import logger from "../../services/logger";
import { getDynamicFormJson } from "../common/dynamicFormService";
import Form from "../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../common/formUtils";
import instituteBasicJson  from "./instituteBasic.json";
class InstituteBasicForm extends Form {
  constructor() {
    super();
    // this.formJson = getDynamicFormJson("instituteBasic");
    this.formJson =instituteBasicJson;
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
      countryCodes:[]
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  getGroups = async () => {
    try {
      const { data } = await groupService.getGroups();

      const dropDowns = { ...this.state.dropDowns };
      dropDowns.groups = data;
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error(ex);
    }
  };

  getPrograms = async () => {
    try {
      const { data } = await programService.getPrograms();

      const dropDowns = { ...this.state.dropDowns };
      dropDowns.degrees = data;
      this.setState({ dropDowns });
    } catch (ex) {
      console.log("programs ", ex);
    }
  };

  async populateForm() {
    // const id = this.props.match?.params?.id;
   const id=this.props.id;
    if (id === "new") return;
    try {
      const { data } = await instituteService.getInstitute(id);

      this.mapToViewModel(data);
    } catch (ex) { }
  }
  async componentDidMount() {
     this.getCountryCodes();

    await this.populateForm();
    await this.getGroups();
    // await this.getPrograms();
  }

  doSubmit = async (id) => {
    try {
      const req={...this.state.data,groupId:authService.getInstituteTenant()};
      if (id === "new") await instituteService.createInstitute(req);
      else await instituteService.updateInstitute(req);
      // this.props.history.goBack();
      this.props.onSave();
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const { t,id } = this.props;
    const { dropDowns } = this.state;
    // const id = this.props.match.params.id;

    const buttonText = getFormButtonText(id);

    const titleText = getFormTitleText(id, this.formJson.alias);
    return (
      // <Card>
      //   <CardHeader
      //     title={t(titleText)}
      //     action={
      //       <BackButton {...this.props} />
      //     }
      //   />
        <form onSubmit={(event) => this.handleSubmit(event, id)}>

          {this.renderForm(this.formJson, dropDowns)}

          {/* <CardContent>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {this.renderMatButton(t(buttonText))}
              {this.renderMatButton(t("common:Reset"), "reset")}
            </Grid>
          </CardContent> */}
        </form>
      // </Card>
    );
  }
}

export default withTranslation(["institute", "common"])(InstituteBasicForm);
