
export function getStatsData(){
    const json= sessionStorage.getItem("stats");
    if(json)
        return JSON.parse(json);
}

export function formatInLakhsCrores(number) {
    if (number && number >= 10000000) {
        return `₹${(number / 10000000).toFixed(2)}Cr`;
    } else if (number && number >= 100000) {
        return `₹${(number / 100000).toFixed(2)}L`;
    } else if(number){
        return `₹${number.toFixed(0)}`;
    }else{
        return 0
    }
}


