import React from "react";
import { useTranslation } from "react-i18next";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";
import ButtonLink from "../../common/buttonLink";

const BusesTable = ({ data, sortColumn, onSort, onDelete,pagination }) => {
  const { t } = useTranslation(["admissionCalendar","common"]);
  const columns = [
    // { path: "id", label: t("ID") },

    { path: "regNum", label: t("Reg. No"),
    content:(item)=>{
     return <ButtonLink variant="text" onButtonClick={()=>{}} icon={"directions_bus"} title={item.regNum}></ButtonLink>;
    }
  },
    { path: "model", label: t("Model")},
    {
      key: "action",
      content: (item) => {
        return <> <Edit title={t("Mileage Details")} icon="local_gas_station" link={`mileages/${item.id}`} /> &nbsp;     <Edit link={`buses/${item.id}`} /> &nbsp; <Delete onDelete={onDelete} item={item} /></>;
      },
    }
  ];

  return (
    <MatTable
      data={data}
      heading={t("List of Buses")}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
      paginate={true}
      pagination={pagination}
    />
  );
};

export default BusesTable;
