import JoiDate from "@hapi/joi-date";
import JoiBase from "joi";

const Joi = JoiBase.extend(JoiDate);

export const capitalize = ([first, ...rest]) =>
  first.toUpperCase() + rest.join("");

export function getFormButtonText(type) {
  return type === "new" ? "Add" : "Update";
}

export function getFormTitleText(type, formName) {
  return type === "new" ? "Add " + formName : type==="edit"?"About "+formName: "Update " + formName;
}

export function getStateObject(groups, data = null,isJsonHasData=false) {
  let obj = {};
  if (data) {
    // console.log("data groups :", groups);
    groups.forEach((group) => {

      group.fields.forEach((field) => {
        // console.log("object obj:",field);
        if(field.inputType==='mobile'){
          obj = {
            ...obj,
            [field.name]: data[field.name],
            [field.name+"CountryCode"]: data[field.name+"CountryCode"],


          };
        }else{
          obj = {
            ...obj,
            [field.name]: data[field.name],
          };
        }
        
      });
    });
  }else if(isJsonHasData){
    groups.forEach((group) => {
      group.fields.forEach((field) => {
        console.log("json object obj:",field);

        obj = {
          ...obj,
          [field.name]: field.value,
        };
      });
    });
  }
   else {
    groups.forEach((group) => {
      group.fields.forEach((field) => {
        if (!field.notShow) {
          // const dataType = field.type ? field.type : field.dataType;
          obj = {
            ...obj,
            [field.name]:
              field.dataType === "Boolean" || field.dataType === "boolean" ? false : "",
          };
        }
      });
    });
  }
  return obj;
}

export function getJoiSchema(groups) {
  return prepareSchema(groups, {});
}

function prepareSchema(groups, schema) {
  groups.forEach((group) => {
    group.fields.forEach((field) => {
      // let joi;
      const dataType = field.dataType;
      switch (dataType.toLowerCase()) {
        case "string":
          if (field.dependency) {
            schema[field.name] = conditional(
              rules(Joi.string(), field),
              Joi.string().allow(""),
              field
            );
          } else {
            schema[field.name] = rules(
              field.required ? Joi.string() : Joi.string().allow(""),
              field
            );
          }
          break;
          case "mobile":
          if (field.dependency) {
            schema[field.name] = conditional(
              rules(Joi.string().regex(/^[0-9]{10}$/).messages({'string.pattern.base': `Phone number must have 10 digits.`}), field),
              Joi.string().regex(/^[0-9]{10}$/).messages({'string.pattern.base': `Phone number must have 10 digits.`}).allow(""),
              field
            );
            schema[field.name+"CountryCode"]=field.required ? Joi.string().required(): Joi.string().allow("");
          } else {
            schema[field.name] = rules(
              field.required ? Joi.string().regex(/^[0-9]{10}$/).messages({'string.pattern.base': `Phone number must have 10 digits.`}).required() : Joi.string().regex(/^[0-9]{10}$/).messages({'string.pattern.base': `Phone number must have 10 digits.`}).allow(""),
              field
            );
            schema[field.name+"CountryCode"]=field.required ? Joi.string().required(): Joi.string().allow("");

          }
          break;
        case "email":
          if (field.dependency) {
            schema[field.name] = conditional(
              rules(Joi.string().email({minDomainSegments: 2, tlds: { allow: ["com", "net", "in","org","edu","co"]} }), field),
              Joi.string()
                .email({ minDomainSegments: 2,tlds: { allow: ["com", "net", "in","org","edu","co"]} })
                .allow(""),
              field
            );
          } else {
            schema[field.name] = rules(
              field.required
                ? Joi.string().email({ minDomainSegments: 2,tlds: { allow: ["com", "net", "in","org","edu","co"]} })
                : Joi.string()
                  .email({ minDomainSegments: 2,tlds: { allow: ["com", "net", "in","org","edu","co"]} })
                  .allow(""),
              field
            );
          }
          break;
        case "boolean":
          if (field.dependency) {
            schema[field.name] = conditional(
              rules(Joi.boolean(), field),
              Joi.boolean(),
              field
            );
          } else {
            schema[field.name] = rules(Joi.boolean(), field);
          }
          break;
        case "integer":
          if (field.dependency) {
            schema[field.name] = conditional(
              rules(Joi.number().integer(), field),
              Joi.number().integer().allow(""),
              field
            );
          } else {
            schema[field.name] = rules(Joi.number().integer(), field);
          }
          break;
        case "date":
          if (field.dependency) {
            schema[field.name] = conditional(
              rules(Joi.date(), field),
              Joi.date(),
              field
            );
          } else {
            schema[field.name] = rules(Joi.date(), field);
          }
          break;
        case "array":
          if (field.dependency) {
            schema[field.name] = conditional(
              rules(Joi.array(), field),
              Joi.array(),
              field
            );
          } else {
            schema[field.name] = rules(Joi.array(), field);
          }
          break;
        case "file":
          if (field.dependency) {
            schema[field.name] = conditional(
              rules(Joi.object(), field),
              Joi.object(),
              field
            );
          } else {
            schema[field.name] = rules(Joi.object(), field);
          }
          break;
      }
    });
  });

  //console.log("schema : ", schema);

  return schema;
}

function rules(joi, field) {
  if (field.required) joi = joi.required();

  if (field.max) {
    joi = joi.max(field.max.ref ? Joi.ref(field.max.ref) : field.max);
  }

  if (field.min)
    joi = joi.min(field.min.ref ? Joi.ref(field.min.ref) : field.min);

  // if (field.label) {
  joi = joi.label(field.label ? field.label : capitalize(field.name));
  // }

  return joi;
}

function conditional(thenJoi, otherWiseJoi, field) {
  let isSchema;
  switch (field.dependency.is.key) {
    case "eq":
      isSchema = field.dependency.is.value;
      break;
      case "contains":
        isSchema = field.dependency.is.value;
  //     isSchema =Joi.string().when(field.dependency.is.key, {
  //       contains:  field.dependency.is.value, // contains is not valid
  //       then: Joi.required()
  // })
      break;
    case "gt":
      isSchema = Joi.number().greater(parseInt(field.dependency.is.value));
      break;
    case "lt":
      isSchema = Joi.number().greater(field.dependency.is.value);
    default:
      break;
  }

  return Joi.alternatives()
    .conditional(field.dependency.on, [
      {
        is: isSchema,
        then: thenJoi,
        otherwise: otherWiseJoi,
      },
    ])
    .label(field.label ? field.label : capitalize(field.name));
}
