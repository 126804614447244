import { Card, CardHeader } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import Form from "../../common/form";
import {
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";
import messFeeDetailFormJson from "./messFeeDetail.json"
import messFeeDetailService from "./messFeeDetail.service";
import hostelService from "../hostel.service";
import roomService from "../manage-blocks/manage-rooms/room.service";

class MessFeeDetailForm extends Form {
  constructor() {
    super();
    this.formJson = messFeeDetailFormJson;
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }


  async componentDidMount() {
    this.getHostels();
    await this.populateForm();
  }
  onSelectOf=(selected, value)=>{
    switch (selected) {
      case "hostelId":
        this.getSharingInfo(value);
        break;
    }
  }

  async populateForm() {
    const id = this.props.match.params.id;
    if (id === "new") return;
    try {
      const { data } = await messFeeDetailService.get(id);
      if(data)
        this.getSharingInfo(data.hostelId)
      this.mapToViewModel(data);
    } catch (ex) {
      logger.error(ex);
    }
  }

  async getHostels(){
    const {data:hostels}=await hostelService.getAll();
    const dropDowns={...this.state.dropDowns}
    dropDowns.hostels=hostels;
    this.setState({dropDowns});
  }

  async getSharingInfo(hostelId){
    const {data:sharings}=await roomService.getSharingsByHostel(hostelId);
    const dropDowns={...this.state.dropDowns}
    dropDowns.sharings=sharings;
    this.setState({dropDowns});
  }

  doSubmit = async (id) => {
    try {
      if (id === "new") {
        await messFeeDetailService.create(this.state.data);
      } else {
        await messFeeDetailService.update(this.state.data,id);
      }
      this.props.history.goBack();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.match.params.id;


    const titleText = getFormTitleText(id, this.formJson.alias);

    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, id)}>
          <CardHeader title={t(titleText)} />

          {this.renderForm(this.formJson, dropDowns)}

          {/* <CardContent>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {this.renderMatButton(t(buttonText))}
              {this.renderMatButton(t("common:Reset"), "reset", "secondary")}
            </Grid>
          </CardContent> */}
        </form>
      </Card>
    );
  }
}

export default withTranslation(["hostel", "common"])(
  MessFeeDetailForm
);
