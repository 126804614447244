import { Paper } from "@mui/material";
import React from "react";
const RegistrationSuccess = (props) => {
  return (
    <Paper>
      <h2>Thank you for filling the form...!</h2>
      <p>Please check your email to activate your account to login.</p>
      <a href="/">Click here to Login</a>
    </Paper>
  );
};

export default RegistrationSuccess;
