import http from "../../../../services/httpService";

const apiEndpoint = "/api/rooms";

function create(data) {
  return http.post(apiEndpoint, data);
}

function update(data,id) {
  return http.put(`${apiEndpoint}/${id}`, data);
}

function partialUpdate(data,id) {
  return http.patch(`${apiEndpoint}/${id}`, data);
}

function get(id) {
  return http.get(`${apiEndpoint}/${id}`);
}
function getAll() {
  return http.get(apiEndpoint);
}
function getAllByBlock(blockId) {
  return http.get(`${apiEndpoint}/block/${blockId}`);
}
function getAllByQuery(query) {
  return http.get(`${apiEndpoint}/q`,{params:query});
}

function getAllList() {
  return http.get(apiEndpoint + "/list");
}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

function getSharingsByHostel(hostelId){
  return http.get(`${apiEndpoint}/summary/${hostelId}`);

}
function getSummaryBySharing(sharing){
  return http.get(`${apiEndpoint}/sharing/${sharing}`);

}
function getRoomAllocationDetails(query) {
  return http.get(`${apiEndpoint}/allocation-details`,{params:query});
}


function getSharings(){
  return http.get(`${apiEndpoint}/sharing`);

}
function getSharingsByHostelType(hostelType){
  return http.get(`${apiEndpoint}/sharing/hostel-type/${hostelType}`);

}

export default {
  create,
  update,
  partialUpdate,
  get,
  getAll,
  getAllByBlock,
  getAllByQuery,
  getAllList,
  deleteById,
  getSharingsByHostel,
  getSharings,
  getSharingsByHostelType,
  getSummaryBySharing,
  getRoomAllocationDetails
};
