import http from "../../../services/httpService";

const apiEndpoint = "/api/hostel-fee-details";

function create(data) {
  return http.post(apiEndpoint, data);
}

function update(data,id) {
  return http.patch(`${apiEndpoint}/${id}`, data);
}

function get(id) {
  return http.get(`${apiEndpoint}/${id}`);
}
function getByHostelAndSharing(hostelId,sharing) {
  return http.get(`${apiEndpoint}/hostel/${hostelId}/sharing/${sharing}`);
}
function getHostelFeeByHostelAndSharing(hostelId,sharing) {
  return http.get(`${apiEndpoint}/hostel/${hostelId}/sharing/${sharing}`);
}
function getHostelFeeBySharing(hostelId,sharing) {
  return http.get(`${apiEndpoint}/sharing`,{params:{hostelId,sharing}});
}
function getHostelAndMessFee(hostelId,sharing,meals) {
  return http.get(`${apiEndpoint}/hostel-and-mess-fee-details`,{params:{hostelId,sharing,meals}});
}

function getAll() {
  return http.get(apiEndpoint);
}
function getAllByQuery(query) {
  return http.get(`${apiEndpoint}/q`,{params:query});
}

function getAllList() {
  return http.get(apiEndpoint + "/list");
}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getByHostelAndSharing,
  getHostelFeeByHostelAndSharing,
  getHostelFeeBySharing,
  getHostelAndMessFee,
  getAll,
  getAllByQuery,
  getAllList,
  deleteById,
};
