import React from 'react';
import Form from '../../common/form';
import { Button, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
class HostelRoomAllocationView extends Form {
    state = {}
    render() {
        const { data, selectedRoom, onRoomSelect } = this.props;
        // console.log("room allocations:", data);
        return <>
            <Card>
                <CardHeader title="Room Allocation"
                    subheader={
                        <><Typography>
                            <strong>Student:</strong> {data.hostelFeeDetail.studentName} &nbsp; <strong>Hostel:</strong> {data.hostelFeeDetail.hostelName} &nbsp;<strong>Block:</strong> {data.hostelFeeDetail.blockName}
                        </Typography><hr /></>
                    }
                />
                <CardContent>
                    <Grid container>

                        {data && data.roomAllocations && data.roomAllocations.map(r =>
                            <Grid item md={1} marginRight={1} marginBottom={1}>
                                {!r.roomIsFull ?
                                    <Button style={{ marginRight: "5px" }} title={"Allocated:"+r.allocatedBeds+"  Un-Allocated:"+r.unallocatedBeds} onClick={() => onRoomSelect(r)} variant={selectedRoom === r.roomId ? 'contained' : 'outlined'}> {r.roomNum}</Button>
                                    : <Button title='Room is full' disabled style={{ marginRight: "5px" }} variant={selectedRoom === r.roomId ? 'contained' : 'outlined'}> {r.roomNum}</Button>
                                }
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </>;
    }
}

export default HostelRoomAllocationView;