function barChartOptions(data, max, indexAxis) {
  return {
    responsive: false,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        // min: 1,
        // max: max,
        // width: 50,
      },
    },
    indexAxis: indexAxis,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };


}




export function prepareMapDataToChartData(data, max, colors) {
  console.log("chart data:", data);
  if (data) {

    const labels = Object.keys(data).sort((e1, e2) => e1 - e2);
    const dataSet = labels.map((l) => data[l]);
    const options = barChartOptions(data, max);
    return {
      options: options,
      data: {
        labels,
        datasets: [
          {
            data: dataSet,
            backgroundColor: [...colors, '#3498db'],
          },
        ],
      },
    };
  }
}

export function prepareChartData(data, colors, countLabel = "count", label = "label", indexAxis = 'x') {
  console.log("data:", data);

  if (data) {
    const labels = data.map((s) => s[label]);
    const dataSet = labels.map(
      (l) => data.find((s) => s[label] === l)[countLabel]
    );

    const options = barChartOptions(data, 10, indexAxis);
    return {
      options: options,
      data: {
        labels,
        datasets: [
          {
            label:"",
            data: dataSet,
            backgroundColor: [...colors, '#3498db'],
          },
        ],
      },
    };
  }
}


function stackedBarChartOptions(titel) {
  return {
    plugins: {
      title: {
        display: true,
        text: titel,
      },
    },
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          precision: 0
        }
      },
    },
  }
}



export function prepareFeeChartData(data, label) {
  if (data) {
    const labels = data.map((s) => s[label]);
    const billingDataSet = labels.map(
      (l) => data.find((s) => s[label] === l).billing
    );
    const collectionDataSet = labels.map(
      (l) => data.find((s) => s[label] === l).collection
    );

    const discountDataSet = labels.map(
      (l) => data.find((s) => s[label] === l).discount
    );

    const collectableDataSet = labels.map(
      (l) => data.find((s) => s[label] === l).collectable
    );

    return {
      options: stackedBarChartOptions("Fee Collection"),
      data: {
        labels,
        datasets: [
          {
            label: "Billing",
            data: billingDataSet,
            backgroundColor: 'rgb(53, 162, 235)',
            stack: 'Stack 0',
          },
          {
            label: "Collection",
            data: collectionDataSet,
            backgroundColor: 'rgb(75, 192, 192)',
            stack: 'Stack 1',
          },
          {
            label: "Discount",
            data: discountDataSet,
            backgroundColor: '#bab6b6',
            stack: 'Stack 1',
          },
          {
            label: "Balance",
            data: collectableDataSet,
            backgroundColor: 'rgb(255, 99, 132)',
            stack: 'Stack 1',
          }
        ],
      },
    };
  }
}


export function prepareInstitutesFeeChartData(input) {
  const keys = Object.keys(input);
  const data = keys.map((l) => input[l]);
  const chartOutput = prepareFeeChartData(data, "institute_name");
  const output = { chart: chartOutput, instituteFeeSummary: data }
  return output;
}

