import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import Joi from "joi";
import React from "react";
import { Link } from "react-router-dom";
import authService from "../../../../services/authService";
import { getNavItems } from "../../../../services/navService";
import Form from "../../../common/form";
import { Visibility, VisibilityOff } from "@mui/icons-material";

class SignInForm extends Form {
  state = {
    data: { username: "", password: "" },
    groups: [],
    errors: {},
  };

  schema = {
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().label("Password"),
    // rememberme: Joi.boolean(),
  };

  // handleClickShowPassword(showPassword) {
  //   this.setState({ showPassword })
  // }

  componentDidMount() {
    // this.getGroups()
  }


  doSubmit = async () => {
    try {
      const { data } = this.state;
      await authService.login(data.username, data.password);
      authService.setCurrentRole();
      const defaultUrl = getNavItems().defaultUrl;
      console.log("default url:", defaultUrl);
      // this.push(defaultUrl);
      window.location.replace(defaultUrl);
    } catch (ex) {

    }
  };

  render() {
    const { classes, onClick } = this.props;
    const { groups, showPassword, data } = this.state;
    return (
      <>
        <Card variant="outlined" style={{ background: "#f2f2f2" }}>
          <CardContent >
            <form onSubmit={this.handleSubmit}>
              <Grid container>
                {this.renderMatInput(
                  {},
                  "username",
                  "Username",
                  "text",
                  "outlined",
                  "small"
                )}
                {this.renderMatPassword(
                      {},
                      "password",
                      "Password",
                      "password",
                      "outlined",
                      "small")}


                {/* {this.renderMatCheckbox({}, "rememberme", "Remember me")} */}

                {this.renderMatButton(
                  "Log in",
                  "submit",
                  "info",
                  true
                )}
              </Grid>
              <Grid container item spacing={5}>
                <Grid item xs>
                  <Button
                    variant="text"
                    color="primary"
                  >
                    <Link to="/forgot-password">
                      {"Forgot Password ?"}
                    </Link>
                  </Button>
                </Grid>
                <Grid item>
                  <Button color="primary" variant="text" onClick={() => onClick(false)}>
                    {"New Registration"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </>
    );
  }
}

export default SignInForm;
