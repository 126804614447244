import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../../services/logger";
import Add from "../../../common/add";
import PagingAndSorting from "../../../common/pagingAndSorting";
import RoomsTable from "./roomsTable";
import roomService from "./room.service";
import RoomFormPage from "./roomForm.page";
import FormDialog from "../../../common/formDialog";

class Rooms extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "roomNum", order: "asc" },
    confirmDialogOpen: false,
    numOfStopsDilog:false,
    pagination: { page: 0, rowsPerPage: 25, count: 0, onPageChange: this.handlePageChange, onRowsPerPageChange: this.handleRowsPerPageChange }

  };

  componentDidMount() {
    this.getRooms();
  }


  onConfirmOk = async (id) => {
    try {
      await roomService.deleteById(id);
      await this.getRooms();
    } catch (ex) {
      logger.error(ex);
    }
  };

  closeDialog=()=>{
    this.setState({dialogOpen:false});
    this.getRooms();
  }


  getRooms = async () => {
    try {
      const {data} = await roomService.getAllByBlock(this.props.blockId);
      this.setState({data});
    } catch (ex) {
      logger.error(ex);
    }
  };

 

  render() {
    const { sortColumn, data, confirmDialogOpen,dialogOpen,id  } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item xs={12} sm={12} md={12} lg={12}>
            <Grid container item sm={12} md={12} direction="row-reverse">
              <Add item={{id:"new"}} title={t("Add Room")} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <RoomsTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
              t={t}
            />
          </Grid>
        </Grid>
        {
          dialogOpen &&
          <FormDialog
            title={t("Room Details")}
            content={<RoomFormPage id={id} onSave={this.closeDialog}/>}
            open={dialogOpen}
            onClose={()=>this.setState({dialogOpen:false})}
            

          ></FormDialog>
        }
        {confirmDialogOpen && this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["room", "common"])(Rooms);
