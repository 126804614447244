// import moment from 'moment';
import dayjs from 'dayjs';


export function  formatToDate(date){
  // console.log("format to date",date);
  if(date)
  return dayjs(date).format("DD-MMM-YYYY");
  // if(date)
  // return  moment(date).format('DD-MMM-YYYY');
}

export function formatToDateAndTime(dateAndTime){
    // if(dateAndTime)
    // return  moment(dateAndTime).format('DD-MMM-YYYY hh:mm A');
    if(dateAndTime)
    return  dayjs(dateAndTime).format('DD-MMM-YYYY hh:mm A');
}

export function formatToTime(dateAndTime){
  // if(dateAndTime)
  // return  moment(dateAndTime).format('hh:mm A');
  if(dateAndTime)
  return  dayjs(dateAndTime).format('hh:mm A');
}