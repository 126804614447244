import axios from "axios";
import { toast } from "react-toastify";
import logger from "./logger";

axios.defaults.baseURL = process.env.REACT_APP_ENV === "dev" ? process.env.REACT_APP_API_BASE_URL : "https://api.emagna.in"
axios.interceptors.response.use(
  (resp) => {
    const { headers } = resp;
    toast.success(headers["x-alert-success"],{position:"top-center"});
    return Promise.resolve(resp);
  },
  (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedError) {
      logger.error(error);
      toast.error("An unexpected error occurrred. reason " + error,{position:"top-center"});
    } else {
      // console.log("errororororo : ", error.response.data.message);
      toast.error(error.response.data.message,{position:"top-center"});
    }

    return Promise.reject(error);
  }
);

const config = {
  transformRequest: [
    function (data, headers) {
      // Do whatever you want to transform the data
      delete headers.common.Authorization;
      // delete headers.common["X-tenant-id"];

      return data;
    },
  ],
};

function setJwt(jwt) {
  if (jwt)
    axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
}

function setTenant(tenant) {
  axios.defaults.headers.common["X-tenant-id"] = tenant;
}

function setInstituteTenant(instituteTenant) {
  if (instituteTenant)
    axios.defaults.headers.common["X-institute-tenant-id"] = instituteTenant;
}


function deleteHeader(header) {
  delete axios.defaults.headers.common[header];
}
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch:axios.patch,
  setJwt,
  setTenant,
  setInstituteTenant,
  deleteHeader,
};
