
import { Button, Divider, Grid, ImageList } from '@mui/material';
import React, { useState } from 'react';
import GroupForm from '../goup/groupForm';
import InstituteBasicForm from '../institute/instituteBasicForm';
import InstituteBasics from '../institute/instituteBasics';
import BasicInfoTrustForm from './basicInfoTrustForm';
import BomComposition from './bomComposition';


const SynizaTrust = () => {
  const [option,setOption]=useState(null)
  const [selected,setSelected]=useState("basicinfo")
    // let option;
    function onClick(name) {
       // console.log("name trust info:",name);
        setSelected(name);
        setOption(getItem(name));
    }

    function getItem(name) {
        switch (name) {
            case "basicinfo":
                return <BasicInfoTrustForm />
            case "composition":
                return <BomComposition/>
            case "addinstitute":
                return <InstituteBasics />
            default:
                break;
        }
    }

    function getTabs(){
        return [
            {
                name:"basicinfo",label:"Basic Info"
            },
            {
                name:"composition",label:"Composition"
            },
            {
                name:"addinstitute",label:"Add Institute / University"
            }
        ]
    }


    return (
        <>
            <Grid container>
              { getTabs().map(tab=> <Grid item alignItems="center" sm={4}>
                    <Button variant='contained' style={{textAlign:"center"}}  color={tab.name===selected?'secondary':'default'} onClick={() => onClick(tab.name)}> {tab.label} </Button>
                </Grid>)}
              
            </Grid>
            <br />
            <Divider />
            <br />
            <Grid container item sm={12}>
                {getItem(selected)}
            </Grid>
        </>
    );
}

export default SynizaTrust;