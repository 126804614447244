import { Card, CardActions, CardHeader, Divider, Grid, Icon, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";

import { getDocumentTypes } from "../../../data/enums";
import logger from "../../../services/logger";
import { getDynamicFormJson } from "../../common/dynamicFormService";
import Form from "../../common/form";
import {
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";
import applicantDetailsJson from "../../../data/forms/applicantDetailsPreview.json"
import admissionFeePaymentService from "../../../services/admissionFeePaymentService";
import InfoDialog from "../../common/infoDialog";
import AdmissionFeePaymentTransactionTable from "./admissionFeePaymentTransactionTable";
import AdmissionFeePaymentTable from "./admissionFeePaymentTable";
import downloadFileService from "../../../services/downloadFileService";

class AdmissionFeePaymentForm extends Form {

  constructor() {
    super();
    this.applicantDetailsJson = applicantDetailsJson;
    this.formJson = {};
    this.formJson = getDynamicFormJson("admissionFeePaymentForm");
    this.isCheckDDAdded = false;
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
      infoDialogOpen: false
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  getEnumValue() {
    try {
      const documentTypes = getDocumentTypes();
      const dropDowns = { ...this.state.dropDowns };
      dropDowns["documentTypes"] = documentTypes;
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error("applicationFormStatus ", ex);
    }
  }



  checkDDJson(type) {

    if (type === "CHECK" || type === "DD") {
      const fields = [
        {
          dataType: "Integer",
          label: "Check / DD #",
          name: "checkDDNumber",
          required: true
        },
        {
          dataType: "String",
          label: "Bank Name",
          name: "bankName",
          required: true
        },
        {
          name: "checkDDDate",
          label: "Check / DD Date",
          dataType: "date",
          inputType: "date",
          required: true
        }
      ]
      const group = {
        id: "checkDD",
        name: "Check / DD Details",
        fields: fields

      }
      if (!this.isCheckDDAdded) {
        this.formJson.groups.push(group);
        this.isCheckDDAdded = true;
        this.schema = getJoiSchema(this.formJson.groups);
        //console.log("fields :", this.formJson);
      }
    } else {
      const groups = this.formJson.groups.filter(j => j.id !== "checkDD");
      //console.log("grous :", groups);
      let data = this.state.data;
      delete data.checkDDNumber;
      delete data.bankName;
      delete data.checkDDDate;
      data.paymentMode = type;
      this.setState({ data });
      this.formJson.groups = [];
      this.formJson.groups.push(...groups);
      this.schema = getJoiSchema(groups);

      this.isCheckDDAdded = false;
    }
  }


  onSelectOf = (item, value) => {
    //console.log("onSelectOf;", value, ":", item);
    const data = this.state.data;
    const discountAmount = data.discountAmount ? data.discountAmount : 0
    //console.log("discount amount:", discountAmount);
    switch (item) {
      case "paymentMode":
        this.checkDDJson(value);
        break;
      case "paymentType":
        if (value === "FULL_PAYMENT") {
          data.netPayAmount = data.totalFeeAmount - discountAmount
          data.partPayAmount = "";
        } else
          data.netPayAmount = "";
        data.paymentType = value;
        this.setState({ data })
        break;
      case "partPayAmount":
        if (value) {
          data.partPayAmount = value;
          data.netPayAmount = value;
          this.setState({ data: data })
        } else {

        }
        break;
      case "discountAmount":
        //console.log("value :",value);
        const amount = value ? value : 0;
        if (data.paymentType === "PART_PAYMENT") {
          if (value) {
            data.discountAmount = amount;
            data.netPayAmount = data.partPayAmount
            // this.setState({ data: data })
          } else {
            // data.discountAmount = "";
            data.netPayAmount = data.partPayAmount;
          }
        } else {
          if (amount) {
            data.discountAmount = amount;
            data.netPayAmount = data.totalFeeAmount - amount
            // this.setState({ data: data })
          } else {
            data.discountAmount = "";
            data.netPayAmount = data.totalFeeAmount
          }
        }
        this.setState({ data: data })

        break;

      case "discountApplied":
        //console.log("discount applied:",value);
        if (value) {

        } else {
          data.discountApplied = value;
          data.discountAmount = "";
          this.setState({ data });
        }
        break;

    }
  };

  handleOptIn = async (item) => {
    console.log("opted item:", item, "-data:", this.state.data);
    await admissionFeePaymentService.optInFeeItem(this.state.data.id, item);
    this.populateForm();
  }

  handleOptOut = async (item) => {
    await admissionFeePaymentService.optOutFeeItem(this.state.data.id, item);
    this.populateForm();
  }
  handleOptInAndOut = async (item, opt) => {
    if (opt)
      await admissionFeePaymentService.optInFeeItem(this.state.data.id, item);
    else
      await admissionFeePaymentService.optOutFeeItem(this.state.data.id, item);
    this.populateForm();
  }
  handleMoreClcik = () => {
    this.setState({ infoDialogOpen: true })
  }

  handleDownload = (item) => {
    // downloadFileService.downloadFile
    downloadFileService.downloadFile("admission-fee-payments/receipt/" + item.id, item.id + "_admission_fee.pdf")

  }
  renderFeeItems(items, title = "", direction = "row") {
    console.log("items:", items);
    return (
      <Card>
        <CardHeader title={title}></CardHeader>
        <Divider />
        <Typography>
          <List>
            <Grid container >
              {items?.map((item, index) => {
                return (
                  <Grid item xs={12} md={direction === "row" ? 3 : 12}>
                    {
                      (!item.optional ? this.renderFeeItem(item, index, "", "", true) :
                        this.renderFeeItem(item, index, "bg-green", "cancel_presentation", false)
                      )}
                  </Grid>
                );
              })}
            </Grid>
          </List>
        </Typography>
      </Card>
    );
  }

  renderFeeItem(item, index, bgColor, icon, opt) {

    return <div class="fee-items-list">
      <ListItem key={item.id} role={undefined} dense button color={"primary"} className={bgColor}>
        <ListItemIcon  >
          <Icon>{icon}</Icon>
        </ListItemIcon>
        <ListItemText id={"labelId-" + item.id} primary={`${index + 1}. ${item.label}`} />
        <ListItemSecondaryAction className={bgColor}>
          <Typography >
            {item.value}
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  }

  renderOptionalFeeItems(items, title = "", direction = "row") {
    console.log("items:", items);
    return (
      <Card>
        <CardHeader title={title}></CardHeader>
        <Divider />
        <Typography>
          <List>
            <Grid container >
              {items?.map((item, index) => {
                return (
                  <Grid item xs={12} md={direction === "row" ? 3 : 12}>
                    {item.optional &&
                      (!item.opted ? this.renderOptionalFeeItem(item, index, "", "add_circle", true) :
                        this.renderOptionalFeeItem(item, index, "bg-green", "cancel_presentation", false)
                      )}
                  </Grid>
                );
              })}
            </Grid>
          </List>
        </Typography>
      </Card>
    );
  }

  renderOptionalFeeItem(item, index, bgColor, icon, opt) {

    return <div class="fee-items-list">
      <ListItem key={item.id} role={undefined} dense button color={"primary"} className={bgColor} onClick={() => this.handleOptInAndOut(item, opt)}>
        <ListItemIcon  >
          <Icon>{icon}</Icon>
        </ListItemIcon>
        <ListItemText id={"labelId-" + item.id} primary={`${index + 1}. ${item.label}`} />
        <ListItemSecondaryAction className={bgColor}>
          <Typography >
            {item.value}
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  }



  async populateForm() {
    const { id, pay } = this.props;
    if (id === "new") return;
    try {
      const { data } = await admissionFeePaymentService.getByStudent(id)
      if (pay === "r") {
        this.mapToViewModel({ ...data, totalFeeAmount: data.remainingAmount, netPayAmount: data.remainingAmount, discountAmount: "", discountApplied: false });
      } else {
        this.mapToViewModel({ ...data, netPayAmount: data.totalFeeAmount, discountAmount: "" });
      }
      this.setState({ optionalFeeItems: data.optionalItems });
      this.setState({ feeItems: data.items });
      this.setState({ fees: [data] });
      await this.populateTransactions(data.id);

    } catch (ex) {
      logger.log(ex);
    }
  }


  async populateTransactions(id) {
    const { data: transactions } = await admissionFeePaymentService.getTransactionsByAdmissionFeePayment(id);
    this.setState({ transactions })
  }

  async componentDidMount() {
    await this.populateForm();
  }

  doSubmit = async (id) => {
    try {
      const { data, source } = this.state;
      await admissionFeePaymentService.offlineAdmissionFeePayment(id, data);
      this.props.doRefresh()
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t, previewData, id } = this.props;
    const { dropDowns, optionalFeeItems, feeItems, infoDialogOpen, transactions, fees } = this.state;

    return (
      <>
        {/* {previewData.admissionFeePaymentStatus !== "PAYMENT_DONE" && <Grid container>
          {this.renderOptionalFeeItems(optionalFeeItems, t("Additional Fees"))}

        </Grid>}
        <br /> */}
        <Grid container spacing={2}>

          {previewData.admissionFeePaymentStatus !== "PAYMENT_DONE" && 
          <Grid item sm={12} md={previewData && previewData.admissionFeePaymentStatus !== "PAYMENT_DONE" ? 5 : 12} >
            <Card>
              <form onSubmit={(event) => this.handleSubmit(event, id)}>
                <CardHeader title={t("Payment Details")} />
                {this.renderForm(this.formJson, dropDowns, false)}
                <CardActions>
                  <Grid container item sm={12} md={12} direction="row-reverse">
                    {this.renderMatButton("Submit")}
                    {this.renderMatButton("Cancel", "reset")}
                  </Grid>
                </CardActions>
              </form>
            </Card>
          </Grid>}
          <Grid item sm={12} md={previewData && previewData.admissionFeePaymentStatus === "PAYMENT_DONE" ? 12 : 7} spacing={3}>
            {fees && <AdmissionFeePaymentTable data={fees} onMoreClick={this.handleMoreClcik} />}
            <br />
            {transactions && <AdmissionFeePaymentTransactionTable onDownload={this.handleDownload} data={transactions} />}
          </Grid>
        </Grid>
        {infoDialogOpen && (
          <InfoDialog
            id="info-dialog"
            keepMounted
            title={t("Fee Items")}
            content={this.renderFeeItems(feeItems, "", "col")}
            open={infoDialogOpen}
            onClose={() => this.setState({ infoDialogOpen: false })}
            onOk={() => this.setState({ infoDialogOpen: false })}
          />
        )}
      </>
    );
  }
}

export default withTranslation(["admissionFeePaymentForm", "common"])(
  AdmissionFeePaymentForm
);
