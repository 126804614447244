import {
  AppBar,
  Badge,
  Button,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import SockJS from "sockjs-client";
import Stomp from 'stompjs';
import AccountCircle from "@mui/icons-material/AccountCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import TranslateIcon from "@mui/icons-material/Translate";
import classNames from "classnames";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import authService from "../../../services/authService";
import { getLanguages } from "../../../services/languageService";
import notificationService from "../../../services/notificationService";
import { getHeaderTitle } from "../../../services/navService";
class Header extends Component {
  state = {
    profile: false,
    i18n: false,
    notification: false,
    institute: ""
  };

  async getNotifications() {
    const { data: notifications } = await notificationService.getAll();
    this.setState({ notifications })

  }

  async componentDidMount() {
   let instituteType= authService.getInstituteType();
    this.handleLanguageChange({ id: "en-"+instituteType, name: "ENGLISH" });

    if (authService.getCurrentUser()) {
      this.subscribeToNotification()
      this.getNotifications();
    }
    const { org } = this.props;
    if (org)
      org.then(orgData => {
        // console.log("org in headr jsx:", orgData);
        this.setState({ org: orgData })
      })

  }

  subscribeToNotification() {
    // Connect to the WebSocket endpoint
    const socketUrl = process.env.REACT_APP_ENV === "dev" ? "http://localhost:8200/api/ws" : "https://services.emagna.in/api/ws"
    const socket = new SockJS(socketUrl);
    const stompClient = Stomp.over(socket);

    // Subscribe to the appropriate topic
    stompClient.connect({}, () => {
      stompClient.subscribe(`/topic/notifications/${authService.getCurrentUser().id}`, message => {
        // console.log("websocket message:",JSON.parse(message.body));
        toast.info(JSON.parse(message.body).message, { position: "bottom-right" })
        // setNotifications([...notifications, JSON.parse(message.body)]);
      });
    });
    return;
  }


  handleLanguageChange = (language) => {
    this.props.i18n.changeLanguage(language.id);

    this.setState({ language });

    this.handleClose({ i18n: false });
  };

  handleClick = (event, ele) => {
    this.setState({ [ele]: !this.state[ele] });
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (item) => {
    this.setState({ anchorEl: null, profile: false, i18n: false, notification: false });
  };

  handleLogout = () => {
    this.handleClose(null);
    authService.logout();
    // window.location = "/";
    window.location.replace("/login");
    // this.props.history.replace("/login");
    // location.replace("/login")
  };

  renderProfileMenu(anchorEl, flag) {
    return (
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(flag)}
        onClose={this.handleClose}
      >
        <MenuItem onClick={this.handleClose}>Profile</MenuItem>
        <MenuItem onClick={this.handleClose}>Settings</MenuItem>
        <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
      </Menu>
    );
  }
  renderLanguageMenu(anchorEl, flag) {
    const languages = getLanguages();
    return (
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(flag)}
        onClose={this.handleClose}
      >
        {languages &&
          languages.map((language) => (
            <MenuItem
              key={language.id}
              onClick={() => this.handleLanguageChange(language)}
            >
              {language.name}
            </MenuItem>
          ))}
      </Menu>
    );
  }

  renderNotifications(anchorEl, flag) {
    const notifications = this.state.notifications;
    return (
      <Menu
        id="notification-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(flag)}
        onClose={this.handleClose}
      >
        {notifications &&
          notifications.map((notification) => (
            <MenuItem
              key={notification.id}
            // onClick={() => this.handlenotificationChange(notification)}
            >
              {notification.message}
            </MenuItem>
          ))}
      </Menu>
    );
  }

  render() {
    const { t, classes, onMobileDrawerClick, onDrawerClick, open, user } = this.props;
    const { org, notifications } = this.state;
    return (
      <React.Fragment>
        <div>
          <AppBar
            position="fixed"
            className={user && open ? classes.appBar : classes.appBarFull}
          // className={classNames(classes.appBar)}
          >
            <Toolbar className={classes.toolbar}>
              {user && <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => onMobileDrawerClick()}
                className={classNames(
                  classes.menuButton
                )}
                size="large">
                <MenuIcon />
              </IconButton>}

              {(user && !open) && <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => onDrawerClick()}
                size="large">
                <MenuIcon />
              </IconButton>}
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                <span id="org-header-name">
                  {org?.name} {user ? "- " + getHeaderTitle(authService.getCurrentRole()) : ""}
                </span>
                {/* {this.state.institute } */}
              </Typography>
              <Hidden only={["xs", "sm"]}>
                <Button
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="language-menu"
                  aria-haspopup="true"
                  startIcon={<TranslateIcon />}
                  endIcon={<ExpandMoreIcon />}
                  onClick={(e) => this.handleClick(e, "i18n")}
                  color="inherit"
                  title="Languages"
                >
                  {this.state.language && this.state.language.name}
                </Button>
              </Hidden>

              {user && <><IconButton
                aria-label="show 4 new mails"
                color="inherit"
                title="Circulars"
                size="large">
                <Badge badgeContent={4} color="secondary">
                  <MailIcon />
                </Badge>
              </IconButton>
                <IconButton
                  aria-label="notifications"
                  color="inherit"
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  onClick={(event) => this.handleClick(event, "notification")}
                  title="Notifications"
                  size="large">
                  <Badge badgeContent={notifications && notifications.length} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="profile-menu"
                  aria-haspopup="true"
                  onClick={(event) => this.handleClick(event, "profile")}
                  color="inherit"
                  title={user.name}
                  size="large">
                  <AccountCircle />
                </IconButton>
              </>}
            </Toolbar>
          </AppBar>
          {this.renderNotifications(this.state.anchorEl, this.state.notification)}
          {this.renderProfileMenu(this.state.anchorEl, this.state.profile)}
          {this.renderLanguageMenu(this.state.anchorEl, this.state.i18n)}
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation(["header"])(Header);
