import { Box, Button, ButtonGroup, Card, CardActionArea, CardActions, CardContent, CardHeader, Divider, Grid, Icon, IconButton, LinearProgress, Paper, Typography } from "@mui/material";
import React from "react";
import enquiryService from "../../../services/enquiryService";
import { Edit, GraphicEq, InsertChart, Monitor, TableBar, TableChart, TableRows, TrendingUp } from "@mui/icons-material";
import { prepareInstitutesFeeChartData, prepareMapDataToChartData, prepareFeeChartData } from "../../common/chartUtil";
import { Bar } from "react-chartjs-2";
import MatTable from "../../common/matTable";
import instituteService from "../../../services/instituteService";
import MatChips from "../../common/matChips";
import { formatInLakhsCrores } from "../../common/util";
import ProgramsFeeTable from "./programsFeeTable";
import InstitutesFeeTable from "./institutesFeeTable";

class MDDashboard extends React.Component {
  state = {
    summary: null,
    chartData: null,
    graph: true,
    institute: 'All',
    enquiryFollowupStats: {},
  };

  constructor() {
    super();
    this.getInstitutes();
    this.getAdmissionSummary();
  }

  async getInstitutes() {
    const { data } = await instituteService.getInstitutes();
    let institutes = data.map(i => { return { label: i.name, value: i.id }; })
    // console.log("instttttt:", institutes);
    this.setState({ institutes: [{ label: 'All', value: 'All' }, ...institutes] })
  }

  getAdmissionSummary = async () => {
    try {
      const { data: summary } = await enquiryService.fetchAdmissionStatistics();
      // sessionStorage.setItem("stats", JSON.stringify(summary))
      this.setState({ summary });
      if (summary) {
        this.setDashboardSummary('All')

      }
    } catch (error) { }
  };

  setDashboardSummary(selected) {
    const { summary } = this.state;
    let dashboardSumary = {}
    if (selected === 'All') {
      dashboardSumary.admissionSummary = summary?.admissionSummary?.overall;
      dashboardSumary.feeSummary = summary?.feeSummary?.fee_overall;
      dashboardSumary.hostelFeeSummary = summary.feeSummary.hostel_fee_overall;
     const output= prepareInstitutesFeeChartData(summary.feeSummary.institute_fee_summary);
     dashboardSumary.instituteFeeSummary=output.instituteFeeSummary     
     this.setState({ chartData:output.chart});

    } else {
      dashboardSumary.admissionSummary = summary?.admissionSummary?.institute_summary[selected];
      dashboardSumary.feeSummary = summary?.feeSummary?.institute_fee_summary[selected];
      dashboardSumary.hostelFeeSummary = summary.feeSummary.institute_hostel_fee_summary[selected]
      dashboardSumary.programFeeSummary = summary.programFeeSummary.program_fee_summary_by_tenant[selected];
     const chartData= prepareFeeChartData(dashboardSumary.programFeeSummary, "code");
      this.setState({ chartData });
    }
    this.setState({ dashboardSumary })
  }

  renderAdmissionSummary(title, obj) {
    return <>
      <Card style={{backgroundColor:"#666ff7",color:"white"}}>
        <CardHeader  title={
          <Typography>
          Total Admissions
          </Typography>
          }/>
        <CardContent>
          <Box  marginTop={"10px"}>
            <Box sx={{ width: '10%', float: "left" }}>
              <Icon>{title}</Icon>
            </Box>
            <Box sx={{ width: '90%', float: "left",fontWeight:"bolder" }}>
              <Typography fontWeight={"bolder"}>
                {(obj && obj.admission_count ? obj.admission_count : 0) + " / " + (obj && obj.total_intake ? obj.total_intake : 0)}
              </Typography>
              <LinearProgress style={{height:"10px",backgroundColor:"#fff"}} color="success" value={obj && obj.admission_count ? obj.admission_count : 0} valueBuffer={obj && obj.total_intake ? obj.total_intake : 0} variant="determinate" />
            </Box>
          </Box>
        </CardContent>
        <CardActionArea>
          <CardActions>
            <Grid container>
              <Grid item sm="6">
                <Typography component={"span"}>
                  Enquiry:{obj?.enquiry_count}
                </Typography>
              </Grid>
              <Grid item sm="6" >
                <Typography component={"p"} textAlign={"right"}>
                  Application:{obj?.application_count}
                </Typography>
              </Grid>
            </Grid>
          </CardActions>
        </CardActionArea>
      </Card>
    </>
  }

  renderFeeSummary(titel, icon,color, obj) {
    return <>

      {<Card style={{backgroundColor:color.bg,color:color.text,fontWeight:"bolder"}} >
        <CardHeader   title={
          <Typography>
         {titel}
          </Typography>
          }
          // subheader={titel} 
          />
        <CardContent>
          <Box marginTop={"10px"}>
            <Box sx={{ width: '10%', float: "left" }}>
              <Icon>{icon}</Icon>
            </Box>
            <Box sx={{ width: '90%', float: "left",fontWeight:"bolder" }}>
              <Typography fontWeight={"bolder"}>
                {(obj ? formatInLakhsCrores(obj?.collection) : 0) + " / " + (obj ? formatInLakhsCrores(obj?.billing) : 0)}
              </Typography>
              <LinearProgress style={{height:"10px",backgroundColor:"#fff"}} color="success" key={titel} value={(obj ? (obj?.collection * 100) / obj?.billing : 0)} variant="determinate" />
            </Box>
          </Box>
        </CardContent>
        <CardActionArea>
          <CardActions>
            <Grid container>
              <Grid item sm="6">
                <Typography component={"span"}>
                  Balance:{formatInLakhsCrores(obj?.collectable)}
                </Typography>
              </Grid>
              <Grid item sm="6" >
                <Typography component={"p"} textAlign={"right"}>

                  Discount:{formatInLakhsCrores(obj?.discount)}
                </Typography>
              </Grid>
            </Grid>
          </CardActions>
        </CardActionArea>
      </Card>}
    </>
  }

  



  handleClick = (graph) => {
    this.setState({ graph })
  }

  handleChipClick = (selected) => {
    this.setState({ institute: selected })
    this.setDashboardSummary(selected)

  }

  render() {
    const { summary, dashboardSumary, chartData, institutes, institute, graph } = this.state;
    return (
      <><Grid container>
        {institutes && <MatChips
        color={"primary"}
          items={institutes}
          onChipClick={this.handleChipClick}
          selected={institute}
        />}
      </Grid>
        <br />
        <Divider />
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3}>
            {dashboardSumary && this.renderAdmissionSummary('group_add', dashboardSumary.admissionSummary)}
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            {dashboardSumary && this.renderFeeSummary("Fee Collection", "currency_rupee",{bg:"rgb(255, 99, 132)",text:"#fff"}, dashboardSumary.feeSummary)}
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            {dashboardSumary && this.renderFeeSummary("Hostel & Mess Fee", "account_balance",{bg:"rgb(251, 166, 19)",text:"#fff"}, dashboardSumary.hostelFeeSummary)}
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            {summary && this.renderFeeSummary("Transportation Fee", "directions_bus",{bg:"purple",text:"#fff"}, summary?.feeSummary?.hostel_fee_overall)}
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <Card>
              <CardHeader title={<ButtonGroup
                disableElevation
                variant="outlined"
                aria-label="Disabled button group"
              >
                <Button variant={graph ? 'contained' : 'outlined'} onClick={() => this.handleClick(true)}><TrendingUp /></Button>
                <Button variant={!graph ? 'contained' : 'outlined'} onClick={() => this.handleClick(false)}><TableChart /></Button>
              </ButtonGroup>} />
              <CardContent>
                {graph 
                  ?(chartData && <Bar options={chartData.options} data={chartData.data} />)
                  : (summary &&  institute==='All'?<InstitutesFeeTable data={dashboardSumary?.instituteFeeSummary} />:  <ProgramsFeeTable data={dashboardSumary.programFeeSummary}/>)}

              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Card>
              <CardContent>
                <img style={{width:"auto",height:"25.67rem"}} src="https://raw.githubusercontent.com/shreekantha/igrs-img/84c46bba2247bcc4dc430da7eaadb4968634be19/ad1.jpeg" alt="Ad1" />
              </CardContent>
            </Card>
          </Grid>
        </Grid></>
    );
  }
}

export default MDDashboard;
