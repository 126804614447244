import React from "react";
import { useTranslation } from "react-i18next";
import MatTable from "../../common/matTable";
import MatChip from "../../common/matChip";

const HostelFeeCollectionTableView = ({ data, sortColumn, onSort, onDelete, pagination }) => {
  const { t } = useTranslation(["hostel", "common"]);
  const columns = [
    { path: "hostelName", label: t("Hostel Name") },
    { path: "sharing", label: t("Sharing"), numeric: true },
    { path: "blockNum", label: t("Block #"), numeric: true },
    { path: "roomNum", label: t("Room #"), numeric: true },
    {
      path: "totalFeeAmount", label: t("Fee Amount"), numeric: true,
      content: (item) => {
        return <MatChip color="info" label={item.totalFeeAmount} variant="outlined" size="small" />
      }
    },
    {
      path: "totalPaidAmount", label: t("Paid Amount"), numeric: true,
      content: (item) => {
        return <MatChip color="success" label={item.totalPaidAmount} variant="outlined" size="small" />
      }
    },
    {
      path: "discountAmount", label: t("Discount"), numeric: true,
      content: (item) => {
        return <MatChip color="default" label={item.discountAmount} variant="outlined" size="small" />
      }
    },
    {
      path: "remainingAmount", label: t("Balance"), numeric: true,
      content: (item) => {
        return <MatChip color="error" label={item.remainingAmount} variant="outlined" size="small" />
      }
    },
  ];

  return (
    <MatTable
      data={data}
      heading={t("Hostel Fee Detail")}
      columns={columns}
      pagination={pagination}
    />
  );
};

export default HostelFeeCollectionTableView;
